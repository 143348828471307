.grouplistitem-grouplistitem {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    padding: var(--dl-space-space-unit);
    position: relative;
    box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.05);
    align-items: center;
    border-color: var(--dl-color-gray-900);
    border-style: solid;
    border-width: 1px;
    border-radius: var(--dl-radius-radius-radius16);
    margin-bottom: var(--dl-space-space-halfunit);
    flex-direction: column;
    justify-content: flex-start;
    background-color: var(--dl-color-gray-white);
}

.grouplistitem-container {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.grouplistitem-text {
    width: 100%;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
}

.grouplistitem-icon {
    fill: var(--dl-color-gray-700);
    width: 24px;
    height: 24px;
    max-width: 24px;
    min-width: 24px;
    max-height: 24px;
    min-height: 24px;
}

.grouplistitem-text1 {
    color: var(--dl-color-gray-700);
    width: 100%;
    margin-top: 2px;
    padding-left: var(--dl-space-space-halfunit);
    padding-right: var(--dl-space-space-halfunit);
}

.grouplistitem-text2 {
    color: var(--dl-color-gray-700);
    width: 100%;
    font-size: 12px;
    margin-top: 2px;
    text-align: right;
    padding-left: var(--dl-space-space-halfunit);
    padding-right: var(--dl-space-space-halfunit);
}