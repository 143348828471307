.cashholderlistitem-entitylistitem {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    position: relative;
    box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.05);
    align-items: center;
    border-color: var(--dl-color-gray-900);
    border-width: 1px;
    padding-left: var(--dl-space-space-unit);
    border-radius: var(--dl-radius-radius-radius8);
    margin-bottom: var(--dl-space-space-halfunit);
    padding-right: var(--dl-space-space-unit);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-unit);
    justify-content: flex-start;
    background-color: var(--dl-color-gray-white);
}

.cashholderlistitem-container {
    flex: 0 0 auto;
    width: 100%;
    max-height: 150px;
    min-height: 150px;
    display: flex;
    align-items: center;
    margin-bottom: var(--dl-space-space-halfunit);
    justify-content: flex-end;
}

.cashholderlistitem-chart {
    width: 100%;
    height: 100px;
    display: flex;
    align-items: flex-start;
}

.cashholderlistitem-icon {
    fill: var(--dl-color-gray-700);
    width: 30px;
    height: 30px;
    max-width: 30px;
    min-width: 30px;
    max-height: 30px;
    min-height: 30px;
    margin-left: var(--dl-space-space-halfunit);
}

.cashholderlistitem-container1 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.cashholderlistitem-image {
    width: 64px;
    height: 64px;
    min-width: 64px;
    min-height: 64px;
    max-width: 64px;
    max-height: 64px;
    object-fit: cover;
    border-radius: var(--dl-radius-radius-round);
}

.cashholderlistitem-container2 {
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
}

.cashholderlistitem-text {
    color: var(--dl-color-gray-500);
    width: 100%;
    font-size: 20px;
    font-style: normal;
    text-align: left;
    font-weight: 700;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: 2px;
}

.cashholderlistitem-text1 {
    color: var(--dl-color-primary-100);
    width: 100%;
    font-size: 20px;
    font-style: normal;
    text-align: right;
    font-weight: 700;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
}

.cashholderlistitem-text2 {
    color: var(--dl-color-cbm-cbm_orange);
    width: 100%;
    font-size: 12px;
    font-style: normal;
    text-align: right;
    font-weight: 700;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
}

.cashholderlistitem-icon2 {
    fill: var(--dl-color-gray-700);
    width: 30px;
    height: 30px;
    max-width: 30px;
    min-width: 30px;
    max-height: 30px;
    min-height: 30px;
}

.cashholderlistitem-container3 {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    padding: var(--dl-space-space-halfunit);
    margin-top: var(--dl-space-space-unit);
    align-items: center;
    border-color: rgba(217, 217, 217, 0.5);
    border-width: 1px;
    border-radius: var(--dl-radius-radius-radius4);
    flex-direction: column;
    justify-content: flex-start;
}

.cashholderlistitem-textinput {
    width: 100%;
    height: 36px;
    border-color: var(--dl-color-gray-900);
    border-radius: var(--dl-radius-radius-radius8);
}

.cashholderlistitem-container4 {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-top: var(--dl-space-space-unit);
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
}

.cashholderlistitem-button {
    color: var(--dl-color-gray-white);
    width: 45%;
    font-style: normal;
    font-weight: 800;
    border-color: var(--dl-color-cbm-cbm_green);
    border-radius: var(--dl-radius-radius-radius8);
    background-color: var(--dl-color-cbm-cbm_green);
}

.cashholderlistitem-button1 {
    color: var(--dl-color-gray-white);
    width: 45%;
    font-style: normal;
    font-weight: 800;
    border-color: var(--dl-color-cbm-cbm_red);
    border-radius: var(--dl-radius-radius-radius8);
    background-color: var(--dl-color-cbm-cbm_red);
}


@media(max-width: 479px) {
    .cashholderlistitem-entitylistitem {
        padding-left: var(--dl-space-space-halfunit);
        padding-right: var(--dl-space-space-halfunit);
    }

    .cashholderlistitem-chart {
        border-color: rgba(217, 217, 217, 0.5);
        border-width: 1px;
        border-radius: var(--dl-radius-radius-radius8);
    }

    .cashholderlistitem-container3 {
        padding: var(--dl-space-space-halfunit);
        border-color: rgba(217, 217, 217, 0.5);
        border-width: 1px;
        border-radius: var(--dl-radius-radius-radius4);
    }
}