.login-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}

.login-bodycontainer {
  flex: 1;
  width: 100%;
  height: auto;
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
}

.login-brandingcontainer {
  flex: 2;
  width: auto;
  height: auto;
  display: flex;
  z-index: 200;
  position: relative;
  box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.15);
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}

.newuserregister-text_pwd_warn {
  color: var(--dl-color-gray-white);
  width: 80%;
  padding-left: var(--dl-space-space-unit);
  margin-bottom: 2px;
}

.login-logotopspacer {
  width: 100%;
  height: var(--dl-size-size-large);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
}

.login-bravhurlogo {
  width: var(--dl-size-size-xxlarge);
  object-fit: cover;
}

.login-bravhurtext {
  color: var(--dl-color-custom-postoffice);
  font-size: 32px;
  font-style: normal;
  margin-top: var(--dl-space-space-twounits);
  text-align: center;
  font-family: Inter;
  font-weight: 800;
}

.login-logincontainer {
  flex: 3;
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-custom-postoffice);
}

.login-logintopspacer {
  width: 100%;
  height: var(--dl-size-size-large);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
}

.login-loginform {
  width: 100%;
  height: 100%;
  display: flex;
  z-index: 100;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}

.login-logintext {
  color: var(--dl-color-gray-white);
  width: 80%;
  font-size: 36px;
  font-style: normal;
  font-family: Inter;
  font-weight: 700;
  padding-left: 0px;
  margin-bottom: var(--dl-space-space-twounits);
}

.login-loginusernanmeinput {
  width: 80%;
  height: var(--dl-size-size-small);
  border-color: var(--dl-color-gray-900);
  border-width: 2px;
  border-radius: var(--dl-radius-radius-radius8);
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}

.login-loginpasswordinput {
  width: 80%;
  height: var(--dl-size-size-small);
  border-color: var(--dl-color-gray-900);
  border-width: 2px;
  border-radius: var(--dl-radius-radius-radius8);
  margin-bottom: var(--dl-space-space-fiveunits);
}

.login-loginlegaltext {
  color: var(--dl-color-gray-white);
  width: 60%;
  text-align: center;
  margin-bottom: var(--dl-space-space-unit);
}

.login-loginbutton {
  color: var(--dl-color-custom-postoffice);
  width: 80%;
  height: var(--dl-size-size-small);
  box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.2);
  font-style: normal;
  text-align: center;
  font-weight: 800;
  border-color: var(--dl-color-gray-white);
  border-width: 2px;
  border-radius: var(--dl-radius-radius-radius16);
}

.login-text4 {
  color: var(--dl-color-gray-white);
  font-style: normal;
  margin-top: var(--dl-space-space-unit);
  font-weight: 700;
  cursor: pointer;
}

.login-registerform {
  width: 100%;
  height: 100%;
  z-index: 100;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}

.login-registertext {
  color: var(--dl-color-gray-white);
  width: 80%;
  font-size: 36px;
  font-style: normal;
  font-family: Inter;
  font-weight: 700;
  padding-left: 0px;
  margin-bottom: var(--dl-space-space-twounits);
}

.login-nameinput {
  width: 80%;
  height: var(--dl-size-size-small);
  border-color: var(--dl-color-gray-900);
  border-width: 2px;
  border-radius: var(--dl-radius-radius-radius8);
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}

.login-regusernameinput {
  width: 80%;
  height: var(--dl-size-size-small);
  border-color: var(--dl-color-gray-900);
  border-width: 2px;
  border-radius: var(--dl-radius-radius-radius8);
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}

.login-regpasswordinput {
  width: 80%;
  height: var(--dl-size-size-small);
  border-color: var(--dl-color-gray-900);
  border-width: 2px;
  border-radius: var(--dl-radius-radius-radius8);
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}

.login-repeatpasswordinput {
  width: 80%;
  height: var(--dl-size-size-small);
  border-color: var(--dl-color-gray-900);
  border-width: 2px;
  border-radius: var(--dl-radius-radius-radius8);
  margin-bottom: var(--dl-space-space-fiveunits);
}

.login-reglegaltext {
  color: var(--dl-color-gray-white);
  width: 60%;
  text-align: center;
  margin-bottom: var(--dl-space-space-unit);
}

.login-registerbutton {
  color: var(--dl-color-custom-postoffice);
  width: 80%;
  height: var(--dl-size-size-small);
  box-shadow: rgba(0, 0, 0, 0.2) 5px 5px 10px 0px;
  font-style: normal;
  text-align: center;
  font-weight: 800;
  border-color: var(--dl-color-gray-white);
  border-width: 2px;
  border-radius: var(--dl-radius-radius-radius16);
}

@media(max-width: 991px) {
  .login-logintext {
    width: 90%;
  }

  .login-loginusernanmeinput {
    width: 90%;
  }

  .login-loginpasswordinput {
    width: 90%;
  }

  .login-loginlegaltext {
    width: 80%;
  }

  .login-loginbutton {
    width: 90%;
  }

  .login-registertext {
    width: 90%;
  }

  .login-nameinput {
    width: 90%;
  }

  .login-regusernameinput {
    width: 90%;
  }

  .login-regpasswordinput {
    width: 90%;
  }

  .login-repeatpasswordinput {
    width: 90%;
  }

  .login-reglegaltext {
    width: 80%;
  }

  .login-registerbutton {
    width: 90%;
  }
}

@media(max-width: 767px) {
  .login-bodycontainer {
    flex-direction: column;
  }

  .login-brandingcontainer {
    flex: 1;
  }

  .login-logotopspacer {
    height: var(--dl-size-size-xsmall);
  }

  .login-bravhurtext {
    margin-bottom: var(--dl-space-space-unit);
  }

  .login-text {
    margin-bottom: var(--dl-space-space-unit);
  }

  .login-logintopspacer {
    display: none;
  }

  .login-logintext {
    width: 90%;
    margin-top: var(--dl-space-space-unit);
  }

  .login-loginusernanmeinput {
    width: 90%;
  }

  .login-loginpasswordinput {
    width: 90%;
  }

  .login-loginlegaltext {
    width: 80%;
  }

  .login-loginbutton {
    width: 90%;
  }

  .login-registertext {
    width: 90%;
    margin-top: var(--dl-space-space-unit);
  }

  .login-nameinput {
    width: 90%;
  }

  .login-regusernameinput {
    width: 90%;
  }

  .login-regpasswordinput {
    width: 90%;
  }

  .login-repeatpasswordinput {
    width: 90%;
  }

  .login-reglegaltext {
    width: 80%;
  }

  .login-registerbutton {
    width: 90%;
    margin-bottom: var(--dl-space-space-threeunits);
  }
}

@media(max-width: 479px) {
  .login-bodycontainer {
    flex-direction: column;
  }

  .login-brandingcontainer {
    flex: 1;
  }

  .login-logotopspacer {
    height: var(--dl-size-size-xsmall);
  }

  .login-bravhurtext {
    margin-bottom: var(--dl-space-space-unit);
  }

  .login-text {
    margin-bottom: var(--dl-space-space-unit);
  }

  .login-logincontainer {
    justify-content: flex-start;
  }

  .login-logintopspacer {
    display: none;
  }

  .login-logintext {
    width: 90%;
    margin-top: var(--dl-space-space-unit);
  }

  .login-loginusernanmeinput {
    width: 90%;
  }

  .login-loginpasswordinput {
    width: 90%;
  }

  .login-loginlegaltext {
    width: 80%;
  }

  .login-loginbutton {
    width: 90%;
  }

  .login-registerform {
    display: flex;
  }

  .login-registertext {
    width: 90%;
    margin-top: var(--dl-space-space-unit);
  }

  .login-nameinput {
    width: 90%;
  }

  .login-regusernameinput {
    width: 90%;
  }

  .login-regpasswordinput {
    width: 90%;
  }

  .login-repeatpasswordinput {
    width: 90%;
  }

  .login-reglegaltext {
    width: 80%;
  }

  .login-registerbutton {
    width: 90%;
    margin-bottom: var(--dl-space-space-threeunits);
  }
}