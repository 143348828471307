.passwordchange-container {
    width: 100%;
    display: flex;
    overflow: auto;
    min-height: 100vh;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
}

.passwordchange-contentframe {
    flex: 1;
    width: 100%;
    height: auto;
    display: flex;
    z-index: 20;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.passwordchange-contentcontainer {
    flex: 1;
    width: 100%;
    height: auto;
    display: flex;
    padding: var(--dl-space-space-halfunit);
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
}

.passwordchange-homepanel {
    flex: 1;
    width: 100%;
    height: auto;
    display: flex;
    max-width: var(--dl-size-size-1200px);
    max-height: auto;
    align-items: stretch;
    flex-direction: row;
    justify-content: flex-start;
}

.passwordchange-leftpanel {
    flex: 1;
    width: 50%;
    height: auto;
    display: flex;
    max-width: 600px;
    align-items: flex-start;
    flex-direction: column;
}

.passwordchange-leftpaneltext {
    color: var(--dl-color-custom-postoffice);
    width: 100%;
    font-size: 20px;
    font-style: normal;
    margin-top: var(--dl-space-space-halfunit);
    font-weight: 500;
    margin-left: 0px;
    padding-left: var(--dl-space-space-unit);
}

@media(max-width: 479px) {
    .passwordchange-leftpanel {
        width: 100%;
    }
}