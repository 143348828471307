.credentialitem-container {
    width: 100%;
    height: auto;
    display: flex;
    position: relative;
    box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.03);
    align-items: center;
    border-color: var(--dl-color-gray-900);
    border-width: 1px;
    border-radius: var(--dl-radius-radius-radius16);
    margin-bottom: var(--dl-space-space-halfunit);
    flex-direction: column;
    justify-content: flex-start;
    background-color: var(--dl-color-gray-white);
}

.credentialitem-main {
    flex: 0 0 auto;
    width: 100%;
    height: var(--dl-size-size-small);
    display: flex;
    align-items: center;
    margin-left: 0px;
    border-radius: var(--dl-radius-radius-radius16);
}

.credentialitem-credential {
    fill: var(--dl-color-custom-krakenlogin);
    width: var(--dl-size-size-midsmall);
    height: var(--dl-size-size-midsmall);
    margin-left: var(--dl-space-space-halfunit);
}

.credentialitem-finger {
    fill: var(--dl-color-custom-helloblue);
    width: var(--dl-size-size-midsmall);
    height: var(--dl-size-size-midsmall);
    margin-left: var(--dl-space-space-halfunit);
}

.credentialitem-icon01 {
    fill: var(--dl-color-custom-hellopink);
    width: var(--dl-size-size-midsmall);
    height: var(--dl-size-size-midsmall);
    margin-left: var(--dl-space-space-halfunit);
}

.credentialitem-icon03 {
    fill: var(--dl-color-custom-newsalmon);
    width: var(--dl-size-size-midsmall);
    height: var(--dl-size-size-midsmall);
    margin-left: var(--dl-space-space-halfunit);
}

.credentialitem-container1 {
    flex: 0 0 auto;
    width: auto;
    height: var(--dl-size-size-midsmall);
    display: flex;
    align-items: flex-start;
    margin-left: var(--dl-space-space-halfunit);
    border-color: var(--dl-color-gray-900);
    border-style: solid;
    border-width: 1px;
}

.credentialitem-icon05 {
    fill: var(--dl-color-custom-redred);
    width: var(--dl-size-size-xsmall);
    height: var(--dl-size-size-xsmall);
    margin-left: var(--dl-space-space-halfunit);
    margin-right: 0px;
}

.credentialitem-icon07 {
    fill: var(--dl-color-custom-krakenlogin);
    width: var(--dl-size-size-xsmall);
    height: var(--dl-size-size-xsmall);
    align-self: center;
    margin-left: var(--dl-space-space-halfunit);
    margin-right: 0px;
}

.credentialitem-container2 {
    flex: 1;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    padding-top: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-halfunit);
    padding-right: var(--dl-space-space-halfunit);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-unit);
    justify-content: center;
}

.credentialitem-text {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
}

.credentialitem-navlink {
    display: contents;
}

.credentialitem-icon09 {
    fill: var(--dl-color-custom-hellopink);
    width: var(--dl-size-size-midsmall);
    height: var(--dl-size-size-midsmall);
    min-width: var(--dl-size-size-midsmall);
    min-height: var(--dl-size-size-midsmall);
    max-width: var(--dl-size-size-midsmall);
    max-height: var(--dl-size-size-midsmall);
    margin-right: var(--dl-space-space-halfunit);
    text-decoration: none;
}

.credentialitem-menu {
    flex: 0 0 auto;
    width: 100%;
    height: var(--dl-size-size-small);
    display: flex;
    align-items: center;
    margin-left: 0px;
    border-color: var(--dl-color-gray-700);
    border-width: 1px;
    margin-right: 0px;
    border-radius: 0px;
    padding-right: var(--dl-space-space-unit);
    justify-content: flex-end;
    background-color: #f1f1f1;
    border-left-width: 0px;
    border-right-width: 0px;
    border-bottom-width: 0px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: var(--dl-radius-radius-radius16);
    border-bottom-right-radius: var(--dl-radius-radius-radius16);
}

.credentialitem-icon11 {
    fill: var(--dl-color-custom-redred);
    width: var(--dl-size-size-midsmall);
    height: var(--dl-size-size-midsmall);
    margin-left: var(--dl-space-space-halfunit);
}

.credentialitem-container3 {
    flex: 1;
    width: auto;
    height: auto;
    display: flex;
    align-items: flex-start;
}

.credentialitem-button {
    color: var(--dl-color-gray-white);
    padding: 0.3rem;
    font-style: normal;
    font-weight: 700;
    margin-left: var(--dl-space-space-halfunit);
    border-color: var(--dl-color-custom-krakenlogin);
    border-width: 3px;
    border-radius: var(--dl-radius-radius-radius16);
    background-color: var(--dl-color-custom-krakenlogin);
}

.credentialitem-button1 {
    color: var(--dl-color-gray-white);
    padding: 0.3rem;
    font-style: normal;
    font-weight: 700;
    margin-left: var(--dl-space-space-halfunit);
    border-color: var(--dl-color-custom-helloblue);
    border-width: 3px;
    border-radius: var(--dl-radius-radius-radius16);
    background-color: var(--dl-color-custom-helloblue);
}

.credentialitem-button2 {
    color: var(--dl-color-gray-white);
    padding: 0.3rem;
    font-style: normal;
    font-weight: 700;
    margin-left: var(--dl-space-space-halfunit);
    border-color: var(--dl-color-custom-redred);
    border-width: 3px;
    border-radius: var(--dl-radius-radius-radius16);
    padding-right: 0.3rem;
    background-color: var(--dl-color-custom-redred);
}

.credentialitem-menu1 {
    flex: 0 0 auto;
    width: 100%;
    height: var(--dl-size-size-small);
    display: flex;
    align-items: center;
    margin-left: 0px;
    border-color: var(--dl-color-gray-700);
    border-width: 1px;
    margin-right: 0px;
    border-radius: var(--dl-radius-radius-radius16);
    padding-right: var(--dl-space-space-unit);
    justify-content: flex-end;
    background-color: #e6e5e5;
    border-left-width: 0px;
    border-right-width: 0px;
    border-bottom-width: 0px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: var(--dl-radius-radius-radius16);
    border-bottom-right-radius: var(--dl-radius-radius-radius16);
}

.credentialitem-textinput {
    border-color: var(--dl-color-gray-700);
    border-radius: var(--dl-radius-radius-radius16);
}

.credentialitem-button3 {
    color: var(--dl-color-gray-white);
    padding: 0.3rem;
    font-style: normal;
    font-weight: 700;
    margin-left: var(--dl-space-space-halfunit);
    border-color: var(--dl-color-custom-krakenlogin);
    border-width: 3px;
    border-radius: var(--dl-radius-radius-radius16);
    padding-bottom: 0.3rem;
    background-color: var(--dl-color-custom-krakenlogin);
}