.searchandadd-searchandadd {
    width: 100%;
    display: flex;
    position: relative;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
    background-color: var(--dl-color-gray-white);
}

.searchandadd-text {
    color: var(--dl-color-cbm-cbm_blue);
    font-style: normal;
    margin-top: var(--dl-space-space-halfunit);
    font-weight: 700;
    margin-left: var(--dl-space-space-halfunit);
    padding-top: 2px;
    border-color: var(--dl-color-cbm-cbm_blue);
    border-width: 1px;
    padding-left: var(--dl-space-space-halfunit);
    border-radius: var(--dl-radius-radius-radius8);
    margin-bottom: var(--dl-space-space-halfunit);
    padding-right: var(--dl-space-space-halfunit);
    padding-bottom: 2px;
    text-transform: uppercase;
}

.searchandadd-textinput {
    width: 100%;
    border-color: var(--dl-color-gray-900);
    border-radius: var(--dl-radius-radius-radius16);
    margin-bottom: var(--dl-space-space-halfunit);
}

.searchandadd-searchadminresult {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    align-items: flex-start;
    margin-bottom: var(--dl-space-space-halfunit);
    flex-direction: column;
}