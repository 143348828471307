.savings-account-item-savings-account-item {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    position: relative;
    align-items: center;
    border-color: var(--dl-color-gray-900);
    border-width: 1px;
    border-radius: var(--dl-radius-radius-radius16);
    margin-bottom: var(--dl-space-space-halfunit);
    flex-direction: column;
    justify-content: flex-start;
    background-color: var(--dl-color-gray-white);
}

.savings-account-item-container {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    padding: var(--dl-space-space-halfunit);
    align-items: center;
    justify-content: space-between;
}

.savings-account-item-text {
    color: var(--dl-color-custom-krakenlogin);
    font-style: normal;
    font-weight: 700;
}

.savings-account-item-text1 {
    color: var(--dl-color-custom-happyblue);
    font-style: normal;
    font-weight: 500;
}

.savings-account-item-container1 {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    padding: var(--dl-space-space-halfunit);
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
}

.savings-account-item-icon {
    fill: rgba(0, 152, 153, 0.25);
    width: 40px;
    height: 40px;
    max-width: 40px;
    min-width: 40px;
    max-height: 40px;
    min-height: 40px;
    cursor: pointer;
}

.savings-account-item-icon2 {
    fill: rgba(240, 95, 110, 0.25);
    width: 40px;
    height: 40px;
    max-width: 40px;
    min-width: 40px;
    max-height: 40px;
    min-height: 40px;
    margin-left: var(--dl-space-space-halfunit);
    cursor: pointer;
}

.savings-account-item-container2 {
    flex: 1;
    width: 100%;
    height: auto;
    display: flex;
    align-items: flex-start;
}

.savings-account-item-icon4 {
    fill: #D9D9D9;
    width: 40px;
    height: 40px;
    max-width: 40px;
    min-width: 40px;
    max-height: 40px;
    min-height: 40px;
    cursor: pointer;
}