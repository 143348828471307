.withdrawmanager-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.withdrawmanager-contentframe {
  flex: 1;
  width: 100%;
  height: auto;
  display: flex;
  z-index: 20;
  align-items: center;
  padding-left: 0px;
  flex-direction: column;
  justify-content: center;
}
.withdrawmanager-contentcontainer {
  flex: 1;
  width: 100%;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.withdrawmanager-homepanel {
  flex: 1;
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  max-width: 600px;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  flex-direction: column;
  justify-content: flex-start;
}
.withdrawmanager-kyccontainer {
  flex: 0 0 auto;
  width: 100%;
  height: 100px;
  display: flex;
  padding: var(--dl-space-space-unit);
  max-width: 600px;
  box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.05);
  align-items: flex-start;
  border-color: rgba(217, 217, 217, 0.5);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius32);
  margin-bottom: var(--dl-space-space-unit);
}
.withdrawmanager-withdraw-form {
  width: 80%;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.05);
  align-items: center;
  border-color: rgba(240, 95, 110, 0.5);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius16);
  margin-bottom: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  justify-content: flex-start;
  background-color: rgba(240, 95, 110, 0.25);
}
.withdrawmanager-text {
  color: var(--dl-color-gray-white);
  width: 100%;
  font-size: 20px;
  font-style: normal;
  text-align: center;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.withdrawmanager-textinput {
  width: 95%;
  border-color: rgba(240, 95, 110, 0.5);
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.withdrawmanager-button {
  color: var(--dl-color-custom-hellopink);
  width: 95%;
  box-shadow: rgba(0, 0, 0, 0.1) 5px 5px 10px 0px;
  font-style: normal;
  font-weight: 700;
  border-color: var(--dl-color-custom-hellopink);
  border-radius: var(--dl-radius-radius-radius16);
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.withdrawmanager-container1 {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  justify-content: flex-start;
}
.withdrawmanager-text01 {
  color: var(--dl-color-custom-postoffice);
  width: 100%;
  font-size: 20px;
  font-style: normal;
  margin-top: var(--dl-space-space-oneandhalfunits);
  text-align: center;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.withdrawmanager-image {
  width: 100%;
  object-fit: cover;
}
.withdrawmanager-confirmation {
  width: 80%;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.05);
  align-items: center;
  border-color: rgba(240, 95, 110, 0.5);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius16);
  margin-bottom: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  justify-content: flex-start;
  background-color: rgba(240, 95, 110, 0.25);
}
.withdrawmanager-text02 {
  color: var(--dl-color-gray-white);
  width: 100%;
  font-size: 20px;
  font-style: normal;
  text-align: center;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.withdrawmanager-text03 {
  color: #f05f6e;
  width: 100%;
  font-size: 24px;
  font-style: normal;
  margin-top: var(--dl-space-space-oneandhalfunits);
  text-align: center;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-threeunits);
}
.withdrawmanager-text04 {
  color: var(--dl-color-gray-white);
  width: 100%;
  font-size: 16px;
  font-style: normal;
  text-align: center;
  font-weight: 500;
  margin-bottom: var(--dl-space-space-halfunit);
}
.withdrawmanager-container2 {
  flex: 0 0 auto;
  width: 100%;
  height: var(--dl-size-size-large);
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-gray-700);
  border-style: solid;
  border-width: 2px;
  border-radius: var(--dl-radius-radius-radius8);
  margin-bottom: var(--dl-space-space-threeunits);
  background-color: var(--dl-color-gray-900);
}
.withdrawmanager-button1 {
  color: rgb(240, 95, 110);
  width: 95%;
  box-shadow: rgba(0, 0, 0, 0.1) 5px 5px 10px 0px;
  font-style: normal;
  font-weight: 700;
  border-color: #f05f6e;
  border-radius: var(--dl-radius-radius-radius16);
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.withdrawmanager-success {
  width: 80%;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.05);
  align-items: center;
  border-color: var(--dl-color-custom-krakenlogin);
  border-width: 2px;
  border-radius: var(--dl-radius-radius-radius16);
  margin-bottom: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  justify-content: flex-start;
  background-color: var(--dl-color-gray-white);
}
.withdrawmanager-text05 {
  color: var(--dl-color-custom-krakenlogin);
  width: 100%;
  font-size: 24px;
  font-style: normal;
  text-align: center;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.withdrawmanager-icon {
  fill: var(--dl-color-custom-krakenlogin);
  width: 150px;
  height: 150px;
  max-width: 150px;
  min-width: 150px;
  max-height: 150px;
  min-height: 150px;
  margin-bottom: var(--dl-space-space-threeunits);
}
.withdrawmanager-button2 {
  color: var(--dl-color-custom-krakenlogin);
  width: 95%;
  box-shadow: rgba(0, 0, 0, 0.1) 5px 5px 10px 0px;
  font-style: normal;
  font-weight: 700;
  border-color: var(--dl-color-custom-krakenlogin);
  border-radius: var(--dl-radius-radius-radius16);
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.withdrawmanager-error {
  width: 80%;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.05);
  align-items: center;
  border-color: var(--dl-color-custom-redred);
  border-width: 2px;
  border-radius: var(--dl-radius-radius-radius16);
  margin-bottom: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  justify-content: flex-start;
  background-color: var(--dl-color-gray-white);
}
.withdrawmanager-text06 {
  color: var(--dl-color-custom-redred);
  width: 100%;
  font-size: 24px;
  font-style: normal;
  text-align: center;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.withdrawmanager-icon2 {
  fill: var(--dl-color-custom-redred);
  width: 150px;
  height: 150px;
  max-width: 150px;
  min-width: 150px;
  max-height: 150px;
  min-height: 150px;
  margin-bottom: var(--dl-space-space-threeunits);
}
.withdrawmanager-button3 {
  color: var(--dl-color-custom-redred);
  width: 95%;
  box-shadow: rgba(0, 0, 0, 0.1) 5px 5px 10px 0px;
  font-style: normal;
  font-weight: 700;
  border-color: var(--dl-color-custom-redred);
  border-radius: var(--dl-radius-radius-radius16);
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
@media(max-width: 479px) {
  .withdrawmanager-withdraw-form {
    width: 100%;
  }
  .withdrawmanager-confirmation {
    width: 100%;
  }
  .withdrawmanager-success {
    width: 100%;
  }
  .withdrawmanager-error {
    width: 100%;
  }
}
