.investmentcontainer-investmentcontainer {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    padding-top: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-unit);
    position: relative;
    max-width: 600px;
    box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.02);
    align-items: flex-start;
    border-color: rgba(217, 217, 217, 1);
    border-width: 1px;
    border-radius: var(--dl-radius-radius-radius16);
    margin-bottom: var(--dl-space-space-unit);
    flex-direction: column;
    justify-content: flex-start;
    background-color: var(--dl-color-gray-white);
}

.investmentcontainer-container {
    flex: auto;
    width: 100%;
    height: 200px;
    display: flex;
    align-items: flex-start;
}

.investmentcontainer-container1 {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    margin-top: var(--dl-space-space-halfunit);
    padding: var(--dl-space-space-unit);
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
}

.investmentcontainer-container2 {
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
}

.investmentcontainer-text {
    width: 100%;
}

.investmentcontainer-text1 {
    color: var(--dl-color-cbm-cbm_green);
    width: 100%;
    font-style: normal;
    text-align: right;
    font-weight: 700;
}

.investmentcontainer-container3 {
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
}

.investmentcontainer-text2 {
    width: 100%;
}

.investmentcontainer-text3 {
    color: var(--dl-color-cbm-cbm_orange);
    width: 100%;
    font-style: normal;
    text-align: right;
    font-weight: 700;
}

.investmentcontainer-container4 {
    width: 100%;
    display: flex;
    margin-top: var(--dl-space-space-halfunit);
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
}

.investmentcontainer-text4 {
    width: 100%;
    text-align: right;
    text-decoration: underline;
}

.investmentcontainer-text5 {
    color: var(--dl-color-cbm-cbm_red);
    width: 100%;
    font-style: normal;
    margin-top: 2px;
    text-align: right;
    font-weight: 700;
}

.investmentcontainer-text6 {
    color: var(--dl-color-cbm-cbm_green);
    width: 100%;
    margin-top: var(--dl-space-space-unit);
        padding: var(--dl-space-space-unit);
    text-align: left;
}

.investmentcontainer-text7 {
    color: var(--dl-color-cbm-cbm_red);
    width: 100%;
    margin-top: var(--dl-space-space-unit);
        padding: var(--dl-space-space-unit);
    text-align: left;
}

.investmentcontainer-container5 {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    padding: var(--dl-space-space-unit);
    margin-top: var(--dl-space-space-halfunit);
    align-items: center;
    justify-content: flex-start;
}

.investmentcontainer-icon {
    fill: rgba(145, 182, 115, 0.5);
    width: 30px;
    height: 30px;
    max-width: 30px;
    min-width: 30px;
    max-height: 30px;
    min-height: 30px;
}

.investmentcontainer-icon2 {
    fill: rgba(232, 92, 59, 0.25);
    width: 30px;
    height: 30px;
    max-width: 30px;
    min-width: 30px;
    max-height: 30px;
    min-height: 30px;
    margin-left: var(--dl-space-space-halfunit);
}

.investmentcontainer-container6 {
    flex: 1;
    width: 100%;
    height: auto;
    display: flex;
    align-items: flex-start;
}

.investmentcontainer-icon4 {
    fill: var(--dl-color-gray-700);
    width: 30px;
    height: 30px;
    max-width: 30px;
    min-width: 30px;
    max-height: 30px;
    min-height: 30px;
}