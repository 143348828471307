.kycinfo-container {
    width: 100%;
    display: flex;
    overflow: auto;
    min-height: 100vh;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
}

.kycinfo-body {
    flex: 1;
    width: 100%;
    height: 100px;
    display: flex;
    max-width: var(--dl-size-size-1200);
    align-self: flex-start;
    align-items: flex-start;
    padding-top: var(--dl-space-space-halfunit);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-halfunit);
    justify-content: flex-start;
}

.kycinfo-maincontainer {
    flex: 1;
    width: 100%;
    height: 100%;
    display: flex;
    max-width: 600px;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
}

.kycinfo-screencontent {
    width: 100%;
    height: 100%;
    display: flex;
    max-width: 600px;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
}

@media(max-width: 479px) {
    .kycinfo-body {
        padding: var(--dl-space-space-halfunit);
    }
}