.demographics-container {
    width: 100%;
    display: flex;
    overflow: auto;
    min-height: 100vh;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
}

.demographics-contentframe {
    flex: 1;
    width: 100%;
    height: auto;
    display: flex;
    z-index: 20;
    align-items: center;
    padding-left: 0px;
    flex-direction: column;
    justify-content: center;
}

.demographics-islandselector {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
}

.demographics-island-select {
    width: 100%;
    height: 30px;
    margin-top: var(--dl-space-space-halfunit);
    max-height: 30px;
    min-height: 30px;
    padding-left: var(--dl-space-space-unit);
    border-radius: var(--dl-radius-radius-radius16);
    margin-bottom: var(--dl-space-space-halfunit);
    padding-right: var(--dl-space-space-unit);
}

.demographics-searchicon {
    fill: #D9D9D9;
    width: 24px;
    height: 24px;
    max-width: 24px;
    min-width: 24px;
    align-self: center;
    max-height: 24px;
    min-height: 24px;
    margin-left: var(--dl-space-space-halfunit);
}

.demographics-contentcontainer {
    flex: 1;
    width: 100%;
    height: auto;
    display: flex;
    padding: var(--dl-space-space-halfunit);
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
}

.demographics-homepanel {
    flex: 1;
    width: 100%;
    height: auto;
    display: flex;
    position: relative;
    max-width: 600px;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
}

@media(max-width: 991px) {
    .demographics-homepanel {
        max-width: 100%;
    }
}

@media(max-width: 767px) {
    .demographics-homepanel {
        max-width: 100%;
    }
}