.imageeditor-imageeditor {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    padding: var(--dl-space-space-unit);
    position: relative;
    align-items: center;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
    justify-content: center;
    background-color: var(--dl-color-gray-white);
}

.imageeditor-imageeditorcropper {
    width: 100%;
    height: var(--dl-size-size-xxlarge);
    object-fit: cover;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
    border-width: 1px;
    position: relative;
}

.imageeditor-imageeditorcontainers {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    padding-left: 0px;
    padding-right: 0px;
    flex-direction: row;
    justify-content: space-between;
}

.imageeditor-imageeditorcancel {
    color: var(--dl-color-gray-900);
    border-color: var(--dl-color-gray-900);
    border-radius: var(--dl-radius-radius-radius8);
}

.imageeditor-imageeditorfileselector {
    color: var(--dl-color-gray-700);
    width: 100%;
    margin-left: var(--dl-space-space-unit);
    border-color: var(--dl-color-gray-900);
    margin-right: var(--dl-space-space-unit);
    border-radius: var(--dl-radius-radius-radius8);
    margin-bottom: 0px;
}

.imageeditor-imageeditorsubmit {
    color: var(--dl-color-success-300);
    font-style: normal;
    font-weight: 700;
    border-color: var(--dl-color-success-300);
    border-radius: var(--dl-radius-radius-radius8);
}

.imageeditor-root-class-name {
    margin-bottom: var(--dl-space-space-unit);
}

.imageeditor-root-class-name1 {
    margin-bottom: var(--dl-space-space-unit);
}

@media(max-width: 991px) {
    .imageeditor-imageeditorcontainers {
        padding-left: 0px;
        padding-right: 0px;
    }
}

@media(max-width: 767px) {
    .imageeditor-imageeditorcontainers {
        padding-left: 0px;
        padding-right: 0px;
    }
}

@media(max-width: 479px) {
    .imageeditor-imageeditor {
        align-items: center;
        padding-left: 0px;
        padding-right: 0px;
        justify-content: center;
    }


    .imageeditor-imageeditorcontainers {
        padding-left: 0px;
        padding-right: 0px;
    }

}