.cbmaccounting-container {
    width: 100%;
    display: flex;
    overflow: auto;
    min-height: 100vh;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
}

.cbmaccounting-body {
    flex: 1;
    width: 100%;
    height: 100px;
    display: flex;
    max-width: var(--dl-size-size-1200);
    align-self: flex-start;
    align-items: flex-start;
    padding-top: var(--dl-space-space-halfunit);
    padding-left: var(--dl-space-space-halfunit);
    padding-right: var(--dl-space-space-halfunit);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-halfunit);
    justify-content: flex-start;
}

.cbmaccounting-maincontainer {
    flex: 1;
    width: 100%;
    height: 100%;
    display: flex;
    max-width: 600px;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
}

.cbmaccounting-systemwarning {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    padding: var(--dl-space-space-unit);
    max-width: 600px;
    align-items: center;
    border-color: var(--dl-color-cbm-cbm_red);
    border-style: solid;
    border-width: 2px;
    border-radius: var(--dl-radius-radius-radius16);
    margin-bottom: var(--dl-space-space-halfunit);
    justify-content: center;
    background-color: var(--dl-color-cbm-cbm_red);
}

.cbmaccounting-text {
    color: var(--dl-color-gray-white);
    font-size: 20px;
    font-style: normal;
    text-align: center;
    font-weight: 700;
}

.cbmaccounting-screencontent {
    width: 100%;
    height: 100%;
    display: flex;
    max-width: 600px;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
}

.cbmaccounting-container1 {
    flex: 0 0 auto;
    width: 100%;
    height: var(--dl-size-size-xxlarge);
    display: flex;
    box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.05);
    align-items: flex-start;
    margin-bottom: var(--dl-space-space-halfunit);
    margin-top: var(--dl-space-space-halfunit);
}

.cbmaccounting-text1 {
    color: var(--dl-color-cbm-cbm_blue);
    font-style: normal;
    margin-top: var(--dl-space-space-halfunit);
    font-weight: 700;
    margin-left: var(--dl-space-space-halfunit);
    padding-top: 2px;
    border-color: var(--dl-color-cbm-cbm_blue);
    border-width: 1px;
    padding-left: var(--dl-space-space-halfunit);
    border-radius: var(--dl-radius-radius-radius8);
    margin-bottom: var(--dl-space-space-halfunit);
    padding-right: var(--dl-space-space-halfunit);
    padding-bottom: 2px;
    text-transform: uppercase;
}

.cbmaccounting-container2 {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    padding: var(--dl-space-space-halfunit);
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.cbmaccounting-text2 {
    color: var(--dl-color-cbm-cbm_blue);
    font-style: normal;
    margin-top: var(--dl-space-space-halfunit);
    font-weight: 700;
    margin-left: var(--dl-space-space-halfunit);
    padding-top: 2px;
    border-color: var(--dl-color-cbm-cbm_blue);
    border-width: 1px;
    padding-left: var(--dl-space-space-halfunit);
    border-radius: var(--dl-radius-radius-radius8);
    margin-bottom: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-halfunit);
    padding-bottom: 2px;
    text-transform: uppercase;
}

@media(max-width: 479px) {
    .cbmaccounting-body {
        padding: var(--dl-space-space-halfunit);
    }
}