.user-loanitem-userlistitem {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    position: relative;
    align-items: center;
    margin-bottom: var(--dl-space-space-halfunit);
    justify-content: flex-start;
    background-color: var(--dl-color-gray-white);
}

.user-loanitem-greyl {
    flex: 0 0 auto;
    width: 8px;
    height: 116px;
    display: flex;
    align-items: flex-start;
    border-color: var(--dl-color-gray-900);
    border-width: 1px;
    background-color: var(--dl-color-gray-900);
}

.user-loanitem-orangel {
    flex: 0 0 auto;
    width: 8px;
    height: 116px;
    display: flex;
    align-items: flex-start;
    border-color: var(--dl-color-cbm-cbm_orange);
    border-width: 1px;
    background-color: var(--dl-color-cbm-cbm_orange);
}

.user-loanitem-greenl {
    flex: 0 0 auto;
    width: 8px;
    height: 116px;
    display: flex;
    align-items: flex-start;
    border-color: var(--dl-color-cbm-cbm_green);
    border-width: 1px;
    background-color: var(--dl-color-cbm-cbm_green);
}

.user-loanitem-gredl {
    flex: 0 0 auto;
    width: 8px;
    height: 116px;
    display: flex;
    align-items: flex-start;
    border-color: var(--dl-color-cbm-cbm_red);
    border-width: 1px;
    background-color: var(--dl-color-cbm-cbm_red);
}

.user-loanitem-blackl {
    flex: 0 0 auto;
    width: 8px;
    height: 116px;
    display: flex;
    align-items: flex-start;
    border-color: var(--dl-color-cbm-cbm_brown);
    border-width: 1px;
    background-color: var(--dl-color-cbm-cbm_brown);
}

.user-loanitem-container {
    flex: 1;
    width: 100px;
    height: 116px;
    display: flex;
    align-items: flex-start;
    border-color: rgba(120, 120, 120, 0.4);
    border-style: solid;
    border-width: 0px;
    flex-direction: row;
    justify-content: flex-start;
    border-top-width: 1px;
    border-bottom-width: 1px;
}

.user-loanitem-image {
    width: 114px;
    object-fit: cover;
}

.user-loanitem-container1 {
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
}

.user-loanitem-container2 {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    align-items: flex-start;
    padding-top: 2px;
    padding-left: var(--dl-space-space-halfunit);
    padding-right: 4px;
    padding-bottom: 2px;
}

.user-loanitem-icon {
    fill: var(--dl-color-cbm-cbm_green);
    width: 14px;
    height: 14px;
    margin-left: var(--dl-space-space-halfunit);
}

.user-loanitem-text {
    color: var(--dl-color-gray-700);
    width: 100%;
    font-size: 12px;
    text-align: right;
    padding-top: 0px;
    padding-right: 0px;
    text-transform: uppercase;
}

.user-loanitem-text1 {
    color: var(--dl-color-gray-500);
    width: 100%;
    font-size: 18px;
    font-style: normal;
    text-align: left;
    font-weight: 700;
    padding-top: 2px;
    padding-left: var(--dl-space-space-halfunit);
    padding-right: var(--dl-space-space-halfunit);
}

.user-loanitem-text2 {
    color: var(--dl-color-cbm-cbm_red);
    width: 100%;
    font-size: 14px;
    font-style: normal;
    text-align: left;
    font-weight: 500;
    padding-top: 2px;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
}

.user-loanitem-text3 {
    color: var(--dl-color-gray-700);
    width: 100%;
    font-size: 14px;
    font-style: normal;
    text-align: left;
    font-weight: 500;
    padding-top: 2px;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
}

.user-loanitem-text4 {
    color: var(--dl-color-gray-700);
    width: 100%;
    font-size: 14px;
    font-style: normal;
    text-align: left;
    font-weight: 500;
    padding-top: 2px;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
}

.user-loanitem-text5 {
    color: var(--dl-color-primary-100);
    width: 100%;
    font-size: 14px;
    font-style: normal;
    text-align: right;
    font-weight: 700;
    padding-top: 0px;
    padding-right: 4px;
    text-transform: uppercase;
}

.user-loanitem-blackr {
    flex: 0 0 auto;
    width: 8px;
    height: 116px;
    display: flex;
    align-items: flex-start;
    border-color: var(--dl-color-cbm-cbm_brown);
    border-width: 1px;
    background-color: var(--dl-color-cbm-cbm_brown);
}

.user-loanitem-redr {
    flex: 0 0 auto;
    width: 8px;
    height: 116px;
    display: flex;
    align-items: flex-start;
    border-color: var(--dl-color-cbm-cbm_red);
    border-width: 1px;
    background-color: var(--dl-color-cbm-cbm_red);
}

.user-loanitem-greenr {
    flex: 0 0 auto;
    width: 8px;
    height: 116px;
    display: flex;
    align-items: flex-start;
    border-color: var(--dl-color-cbm-cbm_green);
    border-width: 1px;
    background-color: var(--dl-color-cbm-cbm_green);
}

.user-loanitem-oranger {
    flex: 0 0 auto;
    width: 8px;
    height: 116px;
    display: flex;
    align-items: flex-start;
    border-color: var(--dl-color-cbm-cbm_orange);
    border-width: 1px;
    background-color: var(--dl-color-cbm-cbm_orange);
}

.user-loanitem-greyr {
    flex: 0 0 auto;
    width: 8px;
    height: 116px;
    display: flex;
    align-items: flex-start;
    border-color: var(--dl-color-gray-900);
    border-width: 1px;
    background-color: var(--dl-color-gray-900);
}