.transfermanager-container {
    width: 100%;
    display: flex;
    overflow: auto;
    min-height: 100vh;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
}

.transfermanager-contentframe {
    flex: 1;
    width: 100%;
    height: auto;
    display: flex;
    z-index: 20;
    align-items: center;
    padding-left: 0px;
    flex-direction: column;
    justify-content: center;
}

.transfermanager-contentcontainer {
    flex: 1;
    width: 100%;
    height: auto;
    display: flex;
    padding: var(--dl-space-space-halfunit);
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
}

.transfermanager-homepanel {
    flex: 1;
    width: 100%;
    height: auto;
    display: flex;
    position: relative;
    max-width: 600px;
    align-items: center;
    padding-top: var(--dl-space-space-halfunit);
    flex-direction: column;
    justify-content: flex-start;
}

.transfermanager-kyccontainer {
    flex: 0 0 auto;
    width: 100%;
    height: 100px;
    display: flex;
    padding: var(--dl-space-space-unit);
    max-width: 600px;
    box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.05);
    align-items: flex-start;
    border-color: rgba(217, 217, 217, 0.5);
    border-width: 1px;
    border-radius: var(--dl-radius-radius-radius32);
    margin-bottom: var(--dl-space-space-unit);
}

.transfermanager-container1 {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
    justify-content: flex-start;
}

.transfermanager-text {
    color: var(--dl-color-custom-postoffice);
    width: 100%;
    font-size: 20px;
    font-style: normal;
    margin-top: var(--dl-space-space-oneandhalfunits);
    text-align: center;
    font-weight: 700;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
}

.transfermanager-image {
    width: 100%;
    object-fit: cover;
}

.transfermanager-transfer-form {
    width: 80%;
    height: auto;
    display: flex;
    padding: var(--dl-space-space-oneandhalfunits);
    box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.05);
    align-items: center;
    border-color: rgba(23, 86, 140, 0.5);
    border-width: 1px;
    border-radius: var(--dl-radius-radius-radius16);
    margin-bottom: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
    justify-content: flex-start;
    background-color: rgba(23, 86, 140, 0.25);
}

.transfermanager-text01 {
    color: var(--dl-color-gray-white);
    width: 100%;
    font-size: 20px;
    font-style: normal;
    text-align: center;
    font-weight: 700;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
}

.transfermanager-selecteduser {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
}

.transfermanager-useritem-selected {
    flex: 0 0 auto;
    width: 95%;
    height: auto;
    display: flex;
    position: relative;
    box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.15);
    align-items: center;
    padding-top: var(--dl-space-space-halfunit);
    border-color: rgba(217, 217, 217, 0.5);
    border-style: solid;
    border-width: 1px;
    padding-left: var(--dl-space-space-halfunit);
    border-radius: var(--dl-radius-radius-radius16);
    margin-bottom: var(--dl-space-space-halfunit);
    padding-right: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-halfunit);
    justify-content: flex-start;
    background-color: var(--dl-color-gray-white);
}

.transfermanager-image1 {
    width: auto;
    height: 60px;
    max-height: 60px;
    min-height: 60px;
    object-fit: cover;
    border-radius: var(--dl-radius-radius-round);
}

.transfermanager-container2 {
    flex: 1;
    width: 100%;
    height: auto;
    display: flex;
    padding: var(--dl-space-space-halfunit);
    align-items: flex-start;
    flex-direction: column;
}

.transfermanager-text02 {
    width: 100%;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    padding-left: 8px;
}

.transfermanager-text03 {
    color: #f05f6e;
    width: 100%;
    font-size: 24px;
    font-style: normal;
    margin-top: var(--dl-space-space-fourunits);
    text-align: center;
    font-weight: 700;
    margin-bottom: var(--dl-space-space-threeunits);
}

.transfermanager-container3 {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
}

.transfermanager-text04 {
    margin-left: var(--dl-space-space-halfunit);
}

.transfermanager-button {
    color: var(--dl-color-custom-helloblue);
    width: 95%;
    box-shadow: rgba(0, 0, 0, 0.1) 5px 5px 10px 0px;
    font-style: normal;
    margin-top: var(--dl-space-space-oneandhalfunits);
    font-weight: 700;
    border-color: var(--dl-color-custom-helloblue);
    border-radius: var(--dl-radius-radius-radius16);
    margin-bottom: var(--dl-space-space-oneandhalfunits);
}

.transfermanager-success {
    width: 80%;
    height: auto;
    display: flex;
    padding: var(--dl-space-space-oneandhalfunits);
    box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.05);
    align-items: center;
    border-color: var(--dl-color-custom-krakenlogin);
    border-width: 2px;
    border-radius: var(--dl-radius-radius-radius16);
    margin-bottom: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
    justify-content: flex-start;
    background-color: var(--dl-color-gray-white);
}

.transfermanager-text05 {
    color: var(--dl-color-custom-krakenlogin);
    width: 100%;
    font-size: 24px;
    font-style: normal;
    text-align: center;
    font-weight: 700;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
}

.transfermanager-icon {
    fill: var(--dl-color-custom-krakenlogin);
    width: 150px;
    height: 150px;
    max-width: 150px;
    min-width: 150px;
    max-height: 150px;
    min-height: 150px;
    margin-bottom: var(--dl-space-space-threeunits);
}

.transfermanager-button1 {
    color: var(--dl-color-custom-krakenlogin);
    width: 95%;
    box-shadow: rgba(0, 0, 0, 0.1) 5px 5px 10px 0px;
    font-style: normal;
    font-weight: 700;
    border-color: var(--dl-color-custom-krakenlogin);
    border-radius: var(--dl-radius-radius-radius16);
    margin-bottom: var(--dl-space-space-oneandhalfunits);
}

.transfermanager-error {
    width: 80%;
    height: auto;
    display: flex;
    padding: var(--dl-space-space-oneandhalfunits);
    box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.05);
    align-items: center;
    border-color: var(--dl-color-custom-redred);
    border-width: 2px;
    border-radius: var(--dl-radius-radius-radius16);
    margin-bottom: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
    justify-content: flex-start;
    background-color: var(--dl-color-gray-white);
}

.transfermanager-text06 {
    color: var(--dl-color-custom-redred);
    width: 100%;
    font-size: 24px;
    font-style: normal;
    text-align: center;
    font-weight: 700;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
}

.transfermanager-icon2 {
    fill: var(--dl-color-custom-redred);
    width: 150px;
    height: 150px;
    max-width: 150px;
    min-width: 150px;
    max-height: 150px;
    min-height: 150px;
    margin-bottom: var(--dl-space-space-threeunits);
}

.transfermanager-button2 {
    color: var(--dl-color-custom-redred);
    width: 95%;
    box-shadow: rgba(0, 0, 0, 0.1) 5px 5px 10px 0px;
    font-style: normal;
    font-weight: 700;
    border-color: var(--dl-color-custom-redred);
    border-radius: var(--dl-radius-radius-radius16);
    margin-bottom: var(--dl-space-space-oneandhalfunits);
}

@media(max-width: 479px) {
    .transfermanager-transfer-form {
        width: 100%;
    }

    .transfermanager-success {
        width: 100%;
    }

    .transfermanager-error {
        width: 100%;
    }
}