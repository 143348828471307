.manager-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.manager-contentframe {
  flex: 1;
  width: 100%;
  height: auto;
  display: flex;
  z-index: 20;
  align-items: center;
  padding-left: 0px;
  flex-direction: column;
  justify-content: center;
}
.manager-contentcontainer {
  flex: 1;
  width: 100%;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.manager-homepanel {
  flex: 1;
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  max-width: 600px;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}

.pushscreen-systemwarning {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-unit);
  max-width: 600px;
  align-items: center;
  border-color: var(--dl-color-cbm-cbm_red);
  border-style: solid;
  border-width: 2px;
  border-radius: var(--dl-radius-radius-radius16);
  margin-bottom: var(--dl-space-space-halfunit);
  margin-top: var(--dl-space-space-halfunit);
  justify-content: center;
  background-color: var(--dl-color-cbm-cbm_red);
}

.pushscreen-text {
  color: var(--dl-color-gray-white);
  font-size: 20px;
  font-style: normal;
  text-align: center;
  font-weight: 700;
}


.pushscreen-searchbar {
  flex: 0 0 auto;
  width: 100%;
  height: 50px;
  display: flex;
  position: relative;
  max-width: 600px;
  box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.05);
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius8);
  margin-bottom: var(--dl-space-space-halfunit);
  margin-top: var(--dl-space-space-halfunit);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
  justify-content: space-between;
  background-color: var(--dl-color-gray-white);
}

.pushscreen-icon {
  fill: var(--dl-color-gray-700);
  width: 30px;
  height: 30px;
  max-width: 30px;
  min-width: 30px;
  max-height: 30px;
  min-height: 30px;
  margin-right: var(--dl-space-space-halfunit);
}

.pushscreen-icon2 {
  fill: var(--dl-color-gray-700);
  width: 30px;
  height: 30px;
  max-width: 30px;
  min-width: 30px;
  max-height: 30px;
  min-height: 30px;
  margin-left: var(--dl-space-space-halfunit);
}

.pushscreen-activetext {
  color: var(--dl-color-cbm-cbm_blue);
  height:25px;
  font-style: normal;
  font-weight: 700;
  margin-left: var(--dl-space-space-halfunit);
  padding-top: 2px;
  border-color: var(--dl-color-cbm-cbm_blue);
  border-width: 1px;
  padding-left: var(--dl-space-space-halfunit);
  border-radius: var(--dl-radius-radius-radius8);
  margin-bottom: var(--dl-space-space-halfunit);
  padding-right: var(--dl-space-space-halfunit);
  padding-bottom: 2px;
  text-transform: uppercase;
}

.pushscreen-activecontainer {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}


@media(max-width: 991px) {
  .manager-homepanel {
    max-width: 100%;
  }
}
@media(max-width: 767px) {
  .manager-homepanel {
    max-width: 100%;
  }
}
