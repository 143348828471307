.profileparametereditor-appparametereditor {
    flex: 0 0 auto;
    width: 100%;
    height: 100%;
    display: flex;
    padding: 15px;
    position: relative;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
    background-color: var(--dl-color-gray-white);
}

.profileparametereditor-profcont {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
}

.profileparametereditor-image {
    width: var(--dl-size-size-xxlarge);
    height: var(--dl-size-size-xxlarge);
    object-fit: cover;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
}

.profileparametereditor-form {
    width: 100%;
    height: auto;
    display: flex;
    margin-top: 0px;
    padding-top: 0px;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    flex-direction: column;
    padding-bottom: 0px;
}

.profileparametereditor-textinput {
    width: 100%;
    border-color: var(--dl-color-gray-700);
    margin-right: 0px;
    border-radius: var(--dl-radius-radius-radius8);
    margin-bottom: var(--dl-space-space-oneandhalfunits);
}

.profileparametereditor-container {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    margin-top: 0px;
    align-items: center;
    padding-left: 0px;
    padding-right: 0px;
    justify-content: flex-end;
}

.profileparametereditor-button {
    color: var(--dl-color-gray-white);
    box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.15);
    font-style: normal;
    font-weight: 700;
    border-color: var(--dl-color-success-300);
    border-radius: var(--dl-radius-radius-radius8);
    background-color: var(--dl-color-success-300);
}

@media(max-width: 479px) {
    .profileparametereditor-appparametereditor {
        padding: var(--dl-space-space-halfunit);
    }

    .profileparametereditor-image {
        width: 100%;
        height: 100%;
    }

    .profileparametereditor-form {
        padding-left: 0px;
        padding-right: 0px;
    }
}