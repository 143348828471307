.useritem-add-useritem-add {
    flex: 0 0 auto;
    width: 95%;
    height: auto;
    display: flex;
    position: relative;
    box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.15);
    align-items: center;
    padding-top: var(--dl-space-space-halfunit);
    border-color: rgba(217, 217, 217, 0.5);
    border-style: solid;
    border-width: 1px;
    padding-left: var(--dl-space-space-halfunit);
    border-radius: var(--dl-radius-radius-radius16);
    margin-bottom: var(--dl-space-space-halfunit);
    padding-right: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-halfunit);
    justify-content: flex-start;
    background-color: var(--dl-color-gray-white);
}

.useritem-add-image {
    width: 60px;
    height: 60px;
    max-height: 60px;
    min-height: 60px;
    max-width: 60px;
    min-width: 60px;
    object-fit: cover;
    border-radius: var(--dl-radius-radius-round);
}

.useritem-add-text {
    width: 100%;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    margin-left: var(--dl-space-space-halfunit);
    padding-left: var(--dl-space-space-halfunit);
}

.useritem-add-icon {
    fill: #D9D9D9;
    width: var(--dl-size-size-small);
    height: var(--dl-size-size-small);
    max-width: var(--dl-size-size-small);
    min-width: var(--dl-size-size-small);
    max-height: var(--dl-size-size-small);
    min-height: var(--dl-size-size-small);
}