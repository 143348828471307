.cbmeditgroup-container {
    width: 100%;
    display: flex;
    overflow: auto;
    min-height: 100vh;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
}

.cbmeditgroup-body {
    flex: 1;
    width: 100%;
    height: 100px;
    display: flex;
    max-width: var(--dl-size-size-1200);
    align-self: flex-start;
    align-items: flex-start;
    padding-top: var(--dl-space-space-halfunit);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-halfunit);
    justify-content: flex-start;
}

.cbmeditgroup-text {
    width: 100%;
    font-size: 20px;
    font-style: normal;
    margin-top: var(--dl-space-space-halfunit);
    font-weight: 700;
}

.cbmeditgroup-text1 {
    color: var(--dl-color-gray-700);
    width: 100%;
    font-size: 14px;
    font-style: normal;
    margin-top: 2px;
    font-weight: 500;
    padding-left: var(--dl-space-space-halfunit);
    margin-bottom: var(--dl-space-space-halfunit);
    padding-right: var(--dl-space-space-halfunit);
}

.cbmeditgroup-maincontainer {
    flex: 1;
    width: 100%;
    height: 100%;
    display: flex;
    max-width: 600px;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
}

.cbmeditgroup-text2 {
    color: var(--dl-color-cbm-cbm_blue);
    font-style: normal;
    margin-top: var(--dl-space-space-halfunit);
    font-weight: 700;
    margin-left: var(--dl-space-space-halfunit);
    padding-top: 2px;
    border-color: var(--dl-color-cbm-cbm_blue);
    border-width: 1px;
    padding-left: var(--dl-space-space-halfunit);
    border-radius: var(--dl-radius-radius-radius8);
    margin-bottom: var(--dl-space-space-halfunit);
    padding-right: var(--dl-space-space-halfunit);
    padding-bottom: 2px;
    text-transform: uppercase;
}

.cbmeditgroup-textinput {
    width: 100%;
    border-color: var(--dl-color-gray-900);
    border-radius: var(--dl-radius-radius-radius16);
    margin-bottom: var(--dl-space-space-halfunit);
}

.cbmeditgroup-searchadminresult {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    align-items: flex-start;
    margin-bottom: var(--dl-space-space-halfunit);
    flex-direction: column;
}

.cbmeditgroup-searchadmincontent {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    align-items: flex-start;
    margin-bottom: var(--dl-space-space-halfunit);
    flex-direction: column;
}

.cbmeditgroup-text3 {
    color: var(--dl-color-cbm-cbm_blue);
    font-style: normal;
    margin-top: var(--dl-space-space-halfunit);
    font-weight: 700;
    margin-left: var(--dl-space-space-halfunit);
    padding-top: 2px;
    border-color: var(--dl-color-cbm-cbm_blue);
    border-width: 1px;
    padding-left: var(--dl-space-space-halfunit);
    border-radius: var(--dl-radius-radius-radius8);
    margin-bottom: var(--dl-space-space-halfunit);
    padding-right: var(--dl-space-space-halfunit);
    padding-bottom: 2px;
    text-transform: uppercase;
}

.cbmeditgroup-textinput1 {
    width: 100%;
    border-color: var(--dl-color-gray-900);
    border-radius: var(--dl-radius-radius-radius16);
    margin-bottom: var(--dl-space-space-halfunit);
}

.cbmeditgroup-searchmemberresult {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    align-items: flex-start;
    margin-bottom: var(--dl-space-space-halfunit);
    flex-direction: column;
}

.cbmeditgroup-searchmembercontent {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    align-items: flex-start;
    margin-bottom: var(--dl-space-space-halfunit);
    flex-direction: column;
}

@media(max-width: 479px) {
    .cbmeditgroup-body {
        padding: var(--dl-space-space-halfunit);
    }
}