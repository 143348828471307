.transfer-form-container {
    display: flex;
    position: relative;
    justify-content: center;
}

.transfer-form-transfer-form {
    width: 80%;
    height: auto;
    display: flex;
    padding: var(--dl-space-space-oneandhalfunits);
    box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.05);
    align-items: center;
    border-color: rgba(23, 86, 140, 0.5);
    border-width: 1px;
    border-radius: var(--dl-radius-radius-radius16);
    margin-bottom: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
    justify-content: flex-start;
    background-color: rgba(23, 86, 140, 0.25);
}

.transfer-form-text {
    color: var(--dl-color-gray-white);
    width: 100%;
    font-size: 20px;
    font-style: normal;
    text-align: center;
    font-weight: 700;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
}

.transfer-form-container1 {
    width: 95%;
    display: flex;
    align-items: flex-start;
    margin-bottom: var(--dl-space-space-unit);
    flex-direction: row;
    justify-content: center;
}

.transfer-form-textinput {
    width: 100%;
    align-self: center;
    border-color: rgba(23, 86, 140, 0.5);
    border-radius: var(--dl-radius-radius-radius16);
}

.transfer-form-icon {
    fill: var(--dl-color-gray-white);
    width: 32px;
    height: 32px;
    max-width: 32px;
    min-width: 32px;
    align-self: center;
    max-height: 32px;
    min-height: 32px;
    margin-left: var(--dl-space-space-unit);
}

.transfer-form-usercontainer {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
}

.transfer-form-selecteduser {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
}

.transfer-form-textinput1 {
    width: 95%;
    margin-top: var(--dl-space-space-halfunit);
    border-color: rgba(23, 86, 140, 0.5);
    margin-bottom: var(--dl-space-space-oneandhalfunits);
}

.transfer-form-button {
    color: var(--dl-color-custom-helloblue);
    width: 95%;
    box-shadow: rgba(0, 0, 0, 0.1) 5px 5px 10px 0px;
    font-style: normal;
    margin-top: var(--dl-space-space-oneandhalfunits);
    font-weight: 700;
    border-color: var(--dl-color-custom-helloblue);
    border-radius: var(--dl-radius-radius-radius16);
    margin-bottom: var(--dl-space-space-oneandhalfunits);
}

@media(max-width: 479px) {
    .transfer-form-transfer-form {
        width: 100%;
    }
}