.userlistitemwitharrow-applicationitem {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    position: relative;
    box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.1);
    align-items: center;
    padding-top: var(--dl-space-space-halfunit);
    border-color: var(--dl-color-gray-900);
    border-style: solid;
    border-width: 1px;
    padding-left: var(--dl-space-space-unit);
    border-radius: var(--dl-radius-radius-radius8);
    margin-bottom: var(--dl-space-space-halfunit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-halfunit);
    justify-content: flex-start;
    background-color: var(--dl-color-gray-white);
}

.userlistitemwitharrow-image {
    width: var(--dl-size-size-small);
    height: var(--dl-size-size-small);
    min-width: var(--dl-size-size-small);
    min-height: var(--dl-size-size-small);
    object-fit: cover;
    border-radius: var(--dl-radius-radius-round);
}

.userlistitemwitharrow-container {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
}

.userlistitemwitharrow-text {
    width: 100%;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    padding-left: var(--dl-space-space-unit);
}

.userlistitemwitharrow-text1 {
    color: var(--dl-color-gray-500);
    width: 100%;
    font-size: 13px;
    font-style: normal;
    margin-top: 2px;
    font-weight: 400;
    padding-left: var(--dl-space-space-unit);
}

.userlistitemwitharrow-icon {
    fill: var(--dl-color-gray-700);
    width: 30px;
    height: 30px;
}