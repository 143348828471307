.profilelistitem-container {
    width: 100%;
    height: var(--dl-size-size-small);
    display: flex;
    position: relative;
    box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.03);
    align-items: center;
    border-color: var(--dl-color-gray-900);
    border-width: 1px;
    border-radius: var(--dl-radius-radius-radius16);
    margin-bottom: var(--dl-space-space-halfunit);
    flex-direction: row;
    justify-content: flex-start;
    background-color: var(--dl-color-gray-white);
}

.profilelistitem-image {
    width: var(--dl-size-size-midsmall);
    height: var(--dl-size-size-midsmall);
    object-fit: cover;
    margin-left: var(--dl-space-space-halfunit);
    border-radius: var(--dl-radius-radius-round);
}

.profilelistitem-container1 {
    flex: 1;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    padding-top: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-unit);
    justify-content: center;
}

.profilelistitem-text {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
}

.profilelistitem-navlink {
    display: contents;
}

.profilelistitem-icon {
    fill: var(--dl-color-custom-hellopink);
    width: var(--dl-size-size-midsmall);
    height: var(--dl-size-size-midsmall);
    margin-right: var(--dl-space-space-halfunit);
    text-decoration: none;
}