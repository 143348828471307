.newpush-container {
    width: 100%;
    display: flex;
    position: relative;
    background-color: var(--dl-color-gray-white);
}

.newpush-newpush {
    width: 100%;
    height: auto;
    display: flex;
    margin-top: var(--dl-space-space-unit);
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
}

.newpush-textinput {
    width: 100%;
    border-color: var(--dl-color-gray-900);
}

.newpush-container1 {
    width: 100%;
    display: flex;
    margin-top: var(--dl-space-space-halfunit);
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
}

.newpush-text {
    width: 100%;
    padding-left: var(--dl-space-space-unit);
}

.newpush-textinput1 {
    border-color: var(--dl-color-gray-900);
    width: auto;
    max-width: 150px;
    display: flex;
}

.newpush-container2 {
    width: 100%;
    display: flex;
    margin-top: var(--dl-space-space-halfunit);
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
}

.newpush-text1 {
    width: 100%;
    padding-left: var(--dl-space-space-unit);
}

.newpush-textinput2 {
    border-color: var(--dl-color-gray-900);
    width: 100%;
}

.newpush-text2 {
    color: #ff1562;
    width: 100%;
    font-size: 14px;
    font-style: normal;
    margin-top: var(--dl-space-space-unit);
    font-weight: 700;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
}

.newpush-select {
    width: 100%;
    height: auto;
    margin-top: var(--dl-space-space-halfunit);
    border-color: var(--dl-color-gray-900);
    border-width: 1px;
}

.newpush-container3 {
    width: 100%;
    display: flex;
    margin-top: var(--dl-space-space-oneandhalfunits);
    align-items: center;
    flex-direction: row;
    justify-content: flex-end;
}

.newpush-button {
    color: var(--dl-color-custom-krakenlogin);
    box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.15);
    font-style: normal;
    font-weight: 700;
    border-color: var(--dl-color-custom-krakenlogin);
}

.newpush-root-class-name {
    width: 100%;
}