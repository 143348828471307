.historicpushitem-historicpushitem {
    flex: 0 0 auto;
    width: 100%;
    height: 100px;
    display: flex;
    position: relative;
    align-items: flex-start;
    margin-bottom: var(--dl-space-space-halfunit);
    justify-content: flex-start;
    text-decoration: none;
    background-color: var(--dl-color-gray-white);
}

.historicpushitem-container {
    flex: 0 0 auto;
    width: 8px;
    height: var(--dl-size-size-medium);
    display: flex;
    align-items: flex-start;
    border-color: var(--dl-color-gray-900);
    border-width: 1px;
    background-color: var(--dl-color-gray-900);
}

.historicpushitem-container1 {
    flex: 1;
    width: 100px;
    height: var(--dl-size-size-medium);
    display: flex;
    align-items: flex-start;
    border-color: rgba(120, 120, 120, 0.4);
    border-style: solid;
    border-width: 0px;
    flex-direction: column;
    justify-content: flex-start;
    border-top-width: 1px;
    border-bottom-width: 1px;
}

.historicpushitem-container2 {
    flex: 1;
    width: 100%;
    height: var(--dl-size-size-medium);
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
}

.historicpushitem-container3 {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    align-items: flex-start;
    padding-top: 2px;
    padding-left: var(--dl-space-space-halfunit);
    padding-right: 4px;
    padding-bottom: 2px;
}

.historicpushitem-text {
    color: var(--dl-color-gray-700);
    width: 100%;
    font-size: 12px;
    text-align: right;
    padding-top: 0px;
    padding-right: 0px;
    text-transform: uppercase;
}

.historicpushitem-text1 {
    color: var(--dl-color-gray-500);
    width: 100%;
    font-size: 18px;
    font-style: normal;
    text-align: left;
    font-weight: 700;
    padding-top: 2px;
    padding-left: var(--dl-space-space-halfunit);
    padding-right: var(--dl-space-space-halfunit);
}

.historicpushitem-text2 {
    color: var(--dl-color-cbm-cbm_red);
    width: 100%;
    font-size: 14px;
    font-style: normal;
    text-align: left;
    font-weight: 500;
    padding-top: 2px;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
}

.historicpushitem-text3 {
    color: var(--dl-color-cbm-cbm_red);
    width: 100%;
    font-size: 10px;
    font-style: normal;
    text-align: left;
    font-weight: 500;
    padding-top: 2px;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
}

.historicpushitem-text4 {
    color: var(--dl-color-cbm-cbm_green);
    width: 100%;
    font-size: 14px;
    font-style: normal;
    text-align: right;
    font-weight: 700;
    padding-top: 0px;
    padding-right: 4px;
    text-transform: uppercase;
}

.historicpushitem-container4 {
    flex: 0 0 auto;
    width: 8px;
    height: var(--dl-size-size-medium);
    display: flex;
    align-items: flex-start;
    border-color: var(--dl-color-gray-900);
    border-width: 1px;
    background-color: var(--dl-color-gray-900);
}