.cbmsettings-container {
    width: 100%;
    display: flex;
    overflow: auto;
    min-height: 100vh;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
}

.cbmsettings-body {
    flex: 1;
    width: 100%;
    height: 100px;
    display: flex;
    max-width: var(--dl-size-size-1200);
    align-self: flex-start;
    align-items: flex-start;
    padding-top: var(--dl-space-space-halfunit);
    padding-left: var(--dl-space-space-halfunit);
    padding-right: var(--dl-space-space-halfunit);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-halfunit);
    justify-content: flex-start;
}

.cbmsettings-maincontainer {
    flex: 1;
    width: 100%;
    height: 100%;
    display: flex;
    max-width: 600px;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
}

.cbmsettings-systemwarning {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    padding: var(--dl-space-space-unit);
    max-width: 600px;
    align-items: center;
    border-color: var(--dl-color-cbm-cbm_red);
    border-style: solid;
    border-width: 2px;
    border-radius: var(--dl-radius-radius-radius16);
    margin-bottom: var(--dl-space-space-halfunit);
    justify-content: center;
    background-color: var(--dl-color-cbm-cbm_red);
}

.cbmsettings-text {
    color: var(--dl-color-gray-white);
    font-size: 20px;
    font-style: normal;
    text-align: center;
    font-weight: 700;
}

.cbmsettings-screencontent {
    width: 100%;
    height: 100%;
    display: flex;
    max-width: 600px;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
}

.cbmsettings-form {
    width: 100%;
    height: auto;
    display: flex;
    padding: var(--dl-space-space-halfunit);
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
}

.cbmsettings-text01 {
    color: var(--dl-color-gray-500);
    width: 100%;
    font-size: 22px;
    font-style: normal;
    margin-top: var(--dl-space-space-unit);
    font-weight: 700;
    padding-left: var(--dl-space-space-halfunit);
    margin-bottom: var(--dl-space-space-unit);
}

.cbmsettings-container01 {
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
}

.cbmsettings-text02 {
    color: var(--dl-color-gray-500);
    width: 100%;
    font-size: 12px;
    padding-left: var(--dl-space-space-unit);
    margin-bottom: 2px;
    padding-right: var(--dl-space-space-unit);
}

.cbmsettings-textinput {
    width: 100%;
    border-color: var(--dl-color-gray-900);
    border-radius: var(--dl-radius-radius-radius8);
    margin-bottom: var(--dl-space-space-halfunit);
}

.cbmsettings-container02 {
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
}

.cbmsettings-text03 {
    color: var(--dl-color-gray-500);
    width: 100%;
    font-size: 12px;
    padding-left: var(--dl-space-space-unit);
    margin-bottom: 2px;
    padding-right: var(--dl-space-space-unit);
}

.cbmsettings-textinput01 {
    width: 100%;
    border-color: var(--dl-color-gray-900);
    border-radius: var(--dl-radius-radius-radius8);
    margin-bottom: var(--dl-space-space-halfunit);
}

.cbmsettings-container03 {
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
}

.cbmsettings-text04 {
    color: var(--dl-color-gray-500);
    width: 100%;
    font-size: 12px;
    padding-left: var(--dl-space-space-unit);
    margin-bottom: 2px;
    padding-right: var(--dl-space-space-unit);
}

.cbmsettings-textinput02 {
    width: 100%;
    border-color: var(--dl-color-gray-900);
    border-radius: var(--dl-radius-radius-radius8);
    margin-bottom: var(--dl-space-space-halfunit);
}

.cbmsettings-container04 {
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
}

.cbmsettings-text05 {
    color: var(--dl-color-gray-500);
    width: 100%;
    font-size: 12px;
    padding-left: var(--dl-space-space-unit);
    margin-bottom: 2px;
    padding-right: var(--dl-space-space-unit);
}

.cbmsettings-textinput03 {
    width: 100%;
    border-color: var(--dl-color-gray-900);
    border-radius: var(--dl-radius-radius-radius8);
    margin-bottom: var(--dl-space-space-halfunit);
}

.cbmsettings-container05 {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    padding-top: var(--dl-space-space-unit);
    margin-bottom: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-unit);
    justify-content: flex-start;
}

.cbmsettings-text06 {
    color: var(--dl-color-gray-500);
    width: 50%;
    font-size: 12px;
    padding-left: var(--dl-space-space-unit);
}

.cbmsettings-checkbox {
    width: auto;
}

.cbmsettings-container06 {
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
}

.cbmsettings-text07 {
    color: var(--dl-color-gray-500);
    width: 100%;
    font-size: 12px;
    padding-left: var(--dl-space-space-unit);
    margin-bottom: 2px;
    padding-right: var(--dl-space-space-unit);
}

.cbmsettings-textinput04 {
    width: 100%;
    border-color: var(--dl-color-gray-900);
    border-radius: var(--dl-radius-radius-radius8);
    margin-bottom: var(--dl-space-space-halfunit);
}

.cbmsettings-container07 {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    padding-top: var(--dl-space-space-unit);
    margin-bottom: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-unit);
    justify-content: flex-start;
}

.cbmsettings-text08 {
    color: var(--dl-color-gray-500);
    width: 50%;
    font-size: 12px;
    padding-left: var(--dl-space-space-unit);
}

.cbmsettings-checkbox1 {
    width: auto;
}

.cbmsettings-container08 {
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
}

.cbmsettings-text09 {
    color: var(--dl-color-gray-500);
    width: 100%;
    font-size: 12px;
    padding-left: var(--dl-space-space-unit);
    margin-bottom: 2px;
    padding-right: var(--dl-space-space-unit);
}

.cbmsettings-textinput05 {
    width: 100%;
    border-color: var(--dl-color-gray-900);
    border-radius: var(--dl-radius-radius-radius8);
    margin-bottom: var(--dl-space-space-halfunit);
}

.cbmsettings-container09 {
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
}

.cbmsettings-text10 {
    color: var(--dl-color-gray-500);
    width: 100%;
    font-size: 12px;
    padding-left: var(--dl-space-space-unit);
    margin-bottom: 2px;
    padding-right: var(--dl-space-space-unit);
}

.cbmsettings-textinput06 {
    width: 100%;
    border-color: var(--dl-color-gray-900);
    border-radius: var(--dl-radius-radius-radius8);
    margin-bottom: var(--dl-space-space-halfunit);
}

.cbmsettings-container10 {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    padding-top: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-unit);
    justify-content: flex-end;
}

.cbmsettings-button {
    color: var(--dl-color-gray-white);
    font-style: normal;
    font-weight: 800;
    border-color: var(--dl-color-cbm-cbm_green);
    background-color: var(--dl-color-cbm-cbm_green);
}

.cbmsettings-form1 {
    width: 100%;
    height: auto;
    display: flex;
    padding: var(--dl-space-space-halfunit);
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
}

.cbmsettings-text11 {
    color: var(--dl-color-gray-500);
    width: 100%;
    font-size: 22px;
    font-style: normal;
    margin-top: var(--dl-space-space-unit);
    font-weight: 700;
    padding-left: var(--dl-space-space-halfunit);
    margin-bottom: var(--dl-space-space-unit);
}

.cbmsettings-text12 {
    color: var(--dl-color-gray-500);
    width: 100%;
    font-size: 12px;
    padding-left: var(--dl-space-space-unit);
    margin-bottom: 2px;
    padding-right: var(--dl-space-space-unit);
}

.cbmsettings-textinput07 {
    width: 100%;
    border-color: var(--dl-color-gray-900);
    border-radius: var(--dl-radius-radius-radius8);
    margin-bottom: var(--dl-space-space-halfunit);
}

.cbmsettings-text13 {
    color: var(--dl-color-gray-500);
    width: 100%;
    font-size: 12px;
    padding-left: var(--dl-space-space-unit);
    margin-bottom: 2px;
    padding-right: var(--dl-space-space-unit);
}

.cbmsettings-textinput08 {
    width: 100%;
    border-color: var(--dl-color-gray-900);
    border-radius: var(--dl-radius-radius-radius8);
    margin-bottom: var(--dl-space-space-halfunit);
}

.cbmsettings-text14 {
    color: var(--dl-color-gray-500);
    width: 100%;
    font-size: 12px;
    padding-left: var(--dl-space-space-unit);
    margin-bottom: 2px;
    padding-right: var(--dl-space-space-unit);
}

.cbmsettings-textinput09 {
    width: 100%;
    border-color: var(--dl-color-gray-900);
    border-radius: var(--dl-radius-radius-radius8);
    margin-bottom: var(--dl-space-space-halfunit);
}

.cbmsettings-text15 {
    color: var(--dl-color-gray-500);
    width: 100%;
    font-size: 12px;
    padding-left: var(--dl-space-space-unit);
    margin-bottom: 2px;
    padding-right: var(--dl-space-space-unit);
}

.cbmsettings-textinput10 {
    width: 100%;
    border-color: var(--dl-color-gray-900);
    border-radius: var(--dl-radius-radius-radius8);
    margin-bottom: var(--dl-space-space-halfunit);
}

.cbmsettings-container11 {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    padding-top: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-unit);
    justify-content: flex-end;
}

.cbmsettings-button1 {
    color: var(--dl-color-gray-white);
    font-style: normal;
    font-weight: 800;
    border-color: var(--dl-color-cbm-cbm_green);
    background-color: var(--dl-color-cbm-cbm_green);
}

@media(max-width: 479px) {
    .cbmsettings-body {
        padding: var(--dl-space-space-halfunit);
    }
}