@import "image-gallery_custom.css";


.kyc-content-kyc-content {
    flex: 1;
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;
    max-width: 800px;
    align-items: flex-start;
    flex-direction: column;
    background-color: var(--dl-color-gray-white);
}

.kyc-content-activecontainer {
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}

.kyc-content-navlink {
    display: contents;
}

.kyc-content-activepushitem {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    position: relative;
    align-items: flex-start;
    margin-bottom: var(--dl-space-space-halfunit);
    justify-content: flex-start;
    text-decoration: none;
    background-color: var(--dl-color-gray-white);
}

.kyc-content-container {
    flex: 1;
    width: 100px;
    height: auto;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
}

.kyc-content-graphcomeshere {
    flex: 1;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.kyc-content-image {
    width: 100%;
    max-width: 800px;
    max-height: auto;
    object-fit: cover;
}

.kyc-content-container1 {
    flex: 1;
    width: 100%;
    height: var(--dl-size-size-large);
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
}

.kyc-content-container2 {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    align-items: flex-start;
    padding-top: 2px;
    padding-left: var(--dl-space-space-halfunit);
    margin-bottom: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-halfunit);
    padding-bottom: 2px;
}

.kyc-content-text {
    color: var(--dl-color-gray-700);
    width: 100%;
    font-size: 12px;
    text-align: right;
    padding-top: 0px;
    padding-right: 0px;
    text-transform: uppercase;
}

.kyc-content-container3 {
    width: 100%;
    display: flex;
    position: relative;
    align-items: flex-start;
    flex-direction: row;
    justify-content: flex-start;
}

.kyc-content-icon {
    top: 70px;
    fill: var(--dl-color-primary-500);
    left: 5px;
    width: 30px;
    height: 30px;
    position: absolute;
    max-width: 30px;
    min-width: 30px;
    max-height: 30px;
    min-height: 30px;
}

.kyc-content-image1 {
    width: auto;
    height: var(--dl-size-size-medium);
    object-fit: cover;
    border-radius: var(--dl-radius-radius-round);
}

.kyc-content-container4 {
    width: 100%;
    height: auto;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
}

.kyc-content-text1 {
    color: var(--dl-color-gray-500);
    width: 100%;
    font-size: 22px;
    font-style: normal;
    text-align: left;
    font-weight: 700;
    padding-top: 2px;
    padding-left: var(--dl-space-space-halfunit);
    padding-right: var(--dl-space-space-halfunit);
}

.kyc-content-text2 {
    color: var(--dl-color-cbm-cbm_red);
    width: 100%;
    font-size: 16px;
    font-style: normal;
    text-align: left;
    font-weight: 500;
    padding-top: 2px;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
}

.kyc-content-text3 {
    color: var(--dl-color-gray-700);
    width: 100%;
    font-size: 16px;
    font-style: normal;
    text-align: left;
    font-weight: 500;
    padding-top: 2px;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
}

.kyc-content-text4 {
    color: var(--dl-color-gray-700);
    width: 100%;
    font-size: 16px;
    font-style: normal;
    text-align: left;
    font-weight: 500;
    padding-top: 2px;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
}

.kyc-content-text5 {
    color: var(--dl-color-gray-700);
    width: 100%;
    font-size: 16px;
    font-style: normal;
    text-align: left;
    font-weight: 500;
    padding-top: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
}

.kyc-content-text6 {
    color: var(--dl-color-gray-700);
    width: 100%;
    font-size: 16px;
    font-style: normal;
    text-align: left;
    font-weight: 500;
    padding-top: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
}

.kyc-content-text7 {
    color: var(--dl-color-gray-700);
    width: 100%;
    font-size: 16px;
    font-style: normal;
    text-align: left;
    font-weight: 500;
    padding-top: 2px;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: 2px;
}

.kyc-content-historiccontainer {
    width: 100%;
    display: flex;
    max-width: 800px;
    align-items: flex-start;
    flex-direction: column;
}

.kyc-content-container5 {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    padding: var(--dl-space-space-unit);
    align-items: center;
    margin-bottom: var(--dl-space-space-halfunit);
    justify-content: flex-end;
}

.kyc-content-button {
    color: var(--dl-color-gray-white);
    font-style: normal;
    font-weight: 700;
    border-color: var(--dl-color-cbm-cbm_red);
    border-radius: var(--dl-radius-radius-radius8);
    background-color: var(--dl-color-cbm-cbm_red);
}

.kyc-content-imagelibrary {
    flex: 1;
    width: 100%;
    max-width: 800px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.kyc-content-image2 {
    width: var(--dl-size-size-large);
    object-fit: cover;
    margin-right: var(--dl-space-space-halfunit);
    margin-bottom: var(--dl-space-space-halfunit);
}

.kyc-content-image3 {
    width: var(--dl-size-size-large);
    object-fit: cover;
    margin-right: var(--dl-space-space-halfunit);
    margin-bottom: var(--dl-space-space-halfunit);
}

.kyc-content-image4 {
    width: var(--dl-size-size-large);
    object-fit: cover;
    margin-right: var(--dl-space-space-halfunit);
    margin-bottom: var(--dl-space-space-halfunit);
}