.pushpage-container {
    width: 100%;
    display: flex;
    overflow: auto;
    min-height: 100vh;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
}

.pushpage-body {
    flex: 1;
    width: 100%;
    height: 100px;
    display: flex;
    max-width: var(--dl-size-size-1200);
    align-self: flex-start;
    align-items: flex-start;
    padding-top: var(--dl-space-space-halfunit);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-halfunit);
    justify-content: flex-start;
}

.pushpage-maincontainer {
    flex: 1;
    width: 100%;
    height: 100%;
    display: flex;
    max-width: 600px;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
}

.pushpage-screencontent {
    width: 100%;
    height: 100%;
    display: flex;
    max-width: 600px;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
}

.pushpage-searchcontent {
    flex: 1;
    width: 100%;
    height: 100%;
    display: flex;
    max-width: 600px;
    align-items: flex-start;
    flex-direction: column;
}

.pushpage-activecontainer {
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}

.pushpage-historiccontainer {
    width: 100%;
    display: flex;
    max-width: 600px;
    align-items: flex-start;
    flex-direction: column;
}

.pushpage-container1 {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    padding: var(--dl-space-space-unit);
    align-items: center;
    margin-bottom: var(--dl-space-space-halfunit);
    justify-content: flex-end;
}

.pushpage-button {
    color: var(--dl-color-gray-white);
    font-style: normal;
    font-weight: 700;
    border-color: var(--dl-color-cbm-cbm_red);
    border-radius: var(--dl-radius-radius-radius8);
    background-color: var(--dl-color-cbm-cbm_red);
}

@media(max-width: 479px) {
    .pushpage-body {
        padding: var(--dl-space-space-halfunit);
    }
}

.pushitem-a-container1 {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    margin-top: var(--dl-space-space-unit);
    align-items: center;
    justify-content: flex-end;
}

.pushitem-a-button {
    color: var(--dl-color-gray-white);
    font-style: normal;
    font-weight: 700;
    border-color: var(--dl-color-custom-krakenlogin);
    background-color: var(--dl-color-custom-krakenlogin);
}

.pushitem-a-text3 {
    width: 100%;
}

.pushitem-a-button1 {
    color: var(--dl-color-gray-white);
    font-style: normal;
    font-weight: 700;
    margin-left: var(--dl-space-space-halfunit);
    border-color: var(--dl-color-custom-redred);
    background-color: var(--dl-color-custom-redred);
}

.pushitem-a-button2 {
    color: #ff1562;
    font-style: normal;
    font-weight: 700;
    margin-left: var(--dl-space-space-halfunit);
    border-color: #ff1562;
}