.accountingsettings-container {
    width: 100%;
    display: flex;
    position: relative;
    background-color: var(--dl-color-gray-white);
}

.accountingsettings-accountingsettings {
    width: 100%;
    height: auto;
    display: flex;
    padding: var(--dl-space-space-halfunit);
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
}

.accountingsettings-text {
    color: var(--dl-color-gray-500);
    width: 100%;
    font-size: 22px;
    font-style: normal;
    margin-top: var(--dl-space-space-unit);
    font-weight: 700;
    padding-left: var(--dl-space-space-halfunit);
    margin-bottom: var(--dl-space-space-unit);
}

.accountingsettings-text1 {
    color: var(--dl-color-gray-500);
    width: 100%;
    font-size: 12px;
    padding-left: var(--dl-space-space-unit);
    margin-bottom: 2px;
    padding-right: var(--dl-space-space-unit);
}

.accountingsettings-textinput {
    width: 100%;
    border-color: var(--dl-color-gray-900);
    border-radius: var(--dl-radius-radius-radius8);
    margin-bottom: var(--dl-space-space-halfunit);
}

.accountingsettings-text2 {
    color: var(--dl-color-gray-500);
    width: 100%;
    font-size: 12px;
    padding-left: var(--dl-space-space-unit);
    margin-bottom: 2px;
    padding-right: var(--dl-space-space-unit);
}

.accountingsettings-textinput1 {
    width: 100%;
    border-color: var(--dl-color-gray-900);
    border-radius: var(--dl-radius-radius-radius8);
    margin-bottom: var(--dl-space-space-halfunit);
}

.accountingsettings-text3 {
    color: var(--dl-color-gray-500);
    width: 100%;
    font-size: 12px;
    padding-left: var(--dl-space-space-unit);
    margin-bottom: 2px;
    padding-right: var(--dl-space-space-unit);
}

.accountingsettings-textinput2 {
    width: 100%;
    border-color: var(--dl-color-gray-900);
    border-radius: var(--dl-radius-radius-radius8);
    margin-bottom: var(--dl-space-space-halfunit);
}

.accountingsettings-text4 {
    color: var(--dl-color-gray-500);
    width: 100%;
    font-size: 12px;
    padding-left: var(--dl-space-space-unit);
    margin-bottom: 2px;
    padding-right: var(--dl-space-space-unit);
}

.accountingsettings-textinput3 {
    width: 100%;
    border-color: var(--dl-color-gray-900);
    border-radius: var(--dl-radius-radius-radius8);
    margin-bottom: var(--dl-space-space-halfunit);
}

.accountingsettings-container1 {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    padding-top: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-unit);
    justify-content: flex-end;
}

.accountingsettings-button {
    color: var(--dl-color-gray-white);
    font-style: normal;
    font-weight: 800;
    border-color: var(--dl-color-cbm-cbm_green);
    background-color: var(--dl-color-cbm-cbm_green);
}