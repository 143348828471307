.cbmuserloan-container {
    width: 100%;
    display: flex;
    overflow: auto;
    min-height: 100vh;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
}

.cbmuserloan-body {
    flex: 1;
    width: 100%;
    height: 100px;
    display: flex;
    max-width: var(--dl-size-size-1200);
    align-self: flex-start;
    align-items: flex-start;
    padding-top: var(--dl-space-space-halfunit);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-halfunit);
    justify-content: flex-start;
}

.cbmuserloan-maincontainer {
    flex: 1;
    width: 100%;
    height: 100%;
    display: flex;
    max-width: 600px;
    align-self: flex-start;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
}

.cbmuserloan-kyccontainer {
    flex: 0 0 auto;
    width: 100%;
    height: 100px;
    display: flex;
    padding: var(--dl-space-space-unit);
    max-width: 600px;
    box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.05);
    align-items: flex-start;
    border-color: rgba(217, 217, 217, 0.5);
    border-width: 1px;
    border-radius: var(--dl-radius-radius-radius8);
    margin-bottom: var(--dl-space-space-unit);
}

.cbmuserloan-paybackloan {
    width: 80%;
    height: auto;
    display: flex;
    padding: var(--dl-space-space-oneandhalfunits);
    align-self: center;
    box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.05);
    align-items: center;
    border-color: rgba(0, 152, 153, 0.5);
    border-width: 1px;
    border-radius: var(--dl-radius-radius-radius16);
    margin-bottom: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
    justify-content: flex-start;
    background-color: rgba(0, 152, 153, 0.25);
}

.cbmuserloan-text {
    color: var(--dl-color-gray-white);
    width: 100%;
    font-size: 20px;
    font-style: normal;
    text-align: center;
    font-weight: 700;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
}

.cbmuserloan-text01 {
    color: var(--dl-color-gray-white);
    width: 100%;
    font-size: 16px;
    font-style: normal;
    text-align: center;
    font-weight: 700;
    margin-bottom: var(--dl-space-space-halfunit);
}

.cbmuserloan-text02 {
    color: var(--dl-color-custom-helloblue);
    width: 100%;
    font-size: 24px;
    font-style: normal;
    text-align: center;
    font-weight: 700;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
}

.cbmuserloan-textinput {
    width: 95%;
    border-color: rgba(0, 152, 153, 0.5);
    margin-bottom: var(--dl-space-space-oneandhalfunits);
}

.cbmuserloan-button {
    color: var(--dl-color-custom-postoffice);
    width: 95%;
    box-shadow: rgba(0, 0, 0, 0.1) 5px 5px 10px 0px;
    font-style: normal;
    font-weight: 700;
    border-color: var(--dl-color-custom-postoffice);
    border-radius: var(--dl-radius-radius-radius16);
    margin-bottom: var(--dl-space-space-oneandhalfunits);
}

.cbmuserloan-confirmloan {
    width: 80%;
    height: auto;
    display: flex;
    padding: var(--dl-space-space-oneandhalfunits);
    align-self: center;
    box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.05);
    align-items: center;
    border-color: rgba(0, 152, 153, 0.5);
    border-width: 1px;
    border-radius: var(--dl-radius-radius-radius16);
    margin-bottom: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
    justify-content: flex-start;
    background-color: rgba(0, 152, 153, 0.25);
}

.cbmuserloan-text03 {
    color: var(--dl-color-gray-white);
    width: 100%;
    font-size: 20px;
    font-style: normal;
    text-align: center;
    font-weight: 700;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
}

.cbmuserloan-text04 {
    color: var(--dl-color-custom-helloblue);
    width: 100%;
    font-size: 24px;
    font-style: normal;
    text-align: center;
    font-weight: 700;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
}

.cbmuserloan-button1 {
    color: var(--dl-color-custom-postoffice);
    width: 95%;
    box-shadow: rgba(0, 0, 0, 0.1) 5px 5px 10px 0px;
    font-style: normal;
    font-weight: 700;
    border-color: var(--dl-color-custom-postoffice);
    border-radius: var(--dl-radius-radius-radius16);
    margin-bottom: var(--dl-space-space-oneandhalfunits);
}

.cbmuserloan-successloan {
    width: 80%;
    height: auto;
    display: flex;
    padding: var(--dl-space-space-oneandhalfunits);
    align-self: flex-start;
    box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.05);
    align-items: center;
    border-color: var(--dl-color-custom-krakenlogin);
    border-width: 2px;
    border-radius: var(--dl-radius-radius-radius16);
    margin-bottom: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
    justify-content: flex-start;
    background-color: var(--dl-color-gray-white);
}

.cbmuserloan-text05 {
    color: var(--dl-color-custom-krakenlogin);
    width: 100%;
    font-size: 24px;
    font-style: normal;
    text-align: center;
    font-weight: 700;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
}

.cbmuserloan-icon {
    fill: var(--dl-color-custom-krakenlogin);
    width: 150px;
    height: 150px;
    max-width: 150px;
    min-width: 150px;
    max-height: 150px;
    min-height: 150px;
    margin-bottom: var(--dl-space-space-threeunits);
}

.cbmuserloan-button2 {
    color: var(--dl-color-custom-krakenlogin);
    width: 95%;
    box-shadow: rgba(0, 0, 0, 0.1) 5px 5px 10px 0px;
    font-style: normal;
    font-weight: 700;
    border-color: var(--dl-color-custom-krakenlogin);
    border-radius: var(--dl-radius-radius-radius16);
    margin-bottom: var(--dl-space-space-oneandhalfunits);
}

.cbmuserloan-errorloan {
    width: 80%;
    height: auto;
    display: flex;
    padding: var(--dl-space-space-oneandhalfunits);
    align-self: flex-start;
    box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.05);
    align-items: center;
    border-color: var(--dl-color-custom-redred);
    border-width: 2px;
    border-radius: var(--dl-radius-radius-radius16);
    margin-bottom: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
    justify-content: flex-start;
    background-color: var(--dl-color-gray-white);
}

.cbmuserloan-text06 {
    color: var(--dl-color-custom-redred);
    width: 100%;
    font-size: 24px;
    font-style: normal;
    text-align: center;
    font-weight: 700;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
}

.cbmuserloan-icon2 {
    fill: var(--dl-color-custom-redred);
    width: 150px;
    height: 150px;
    max-width: 150px;
    min-width: 150px;
    max-height: 150px;
    min-height: 150px;
    margin-bottom: var(--dl-space-space-threeunits);
}

.cbmuserloan-button3 {
    color: var(--dl-color-custom-redred);
    width: 95%;
    box-shadow: rgba(0, 0, 0, 0.1) 5px 5px 10px 0px;
    font-style: normal;
    font-weight: 700;
    border-color: var(--dl-color-custom-redred);
    border-radius: var(--dl-radius-radius-radius16);
    margin-bottom: var(--dl-space-space-oneandhalfunits);
}

.cbmuserloan-all-loans {
    width: 100%;
    display: flex;
    align-items: flex-start;
    margin-bottom: var(--dl-space-space-unit);
    flex-direction: column;
    justify-content: flex-start;
}

.cbmuserloan-balancestrip {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    align-self: center;
    margin-top: var(--dl-space-space-unit);
    align-items: center;
    justify-content: space-between;
}

.cbmuserloan-ledgerbalanetext {
    color: var(--dl-color-custom-postoffice);
    font-size: 22px;
    font-style: normal;
    text-align: left;
    font-weight: 700;
}

.cbmuserloan-balanceamounttext {
    color: var(--dl-color-new_category-blueish);
    font-size: 22px;
    font-style: normal;
    text-align: right;
    font-weight: 700;
}

.cbmuserloan-ledgerhistorytopstrip {
    flex: 0 0 auto;
    width: 95%;
    height: auto;
    display: flex;
    align-self: center;
    margin-top: var(--dl-space-space-unit);
    align-items: center;
    justify-content: flex-end;
}

.cbmuserloan-ledgerpagingtopbit {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.cbmuserloan-topleftarrow {
    fill: #D9D9D9;
    width: 30px;
    height: 30px;
    max-width: 30px;
    min-width: 30px;
    max-height: 30px;
    min-height: 30px;
}

.cbmuserloan-toppageno {
    color: var(--dl-color-gray-900);
    width: 30px;
    height: auto;
    font-size: 20px;
    text-align: center;
}

.cbmuserloan-toprightarrow {
    fill: #D9D9D9;
    width: 30px;
    height: 30px;
    max-width: 30px;
    min-width: 30px;
    max-height: 30px;
    min-height: 30px;
}

.cbmuserloan-loanitems {
    width: 100%;
    display: flex;
    align-items: flex-start;
    padding-top: var(--dl-space-space-halfunit);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-halfunit);
    justify-content: flex-start;
}

.cbmuserloan-ledgeritempositive {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    padding: var(--dl-space-space-halfunit);
    position: relative;
    align-items: flex-start;
    border-color: rgba(217, 217, 217, 0.5);
    border-width: 1px;
    margin-bottom: var(--dl-space-space-halfunit);
    flex-direction: column;
    justify-content: flex-start;
    background-color: var(--dl-color-gray-white);
}

.cbmuserloan-typetext {
    color: var(--dl-color-custom-krakenlogin);
    width: 100%;
    font-size: 16px;
    font-style: normal;
    text-align: left;
    font-weight: 500;
    text-transform: uppercase;
}

.cbmuserloan-nametext {
    color: var(--dl-color-gray-700);
    width: 100%;
    font-size: 20px;
    font-style: normal;
    margin-top: 4px;
    text-align: left;
    font-weight: 500;
}

.cbmuserloan-amounttext {
    color: var(--dl-color-custom-krakenlogin);
    width: 100%;
    font-size: 24px;
    font-style: normal;
    margin-top: 0px;
    text-align: right;
    font-weight: 700;
    text-transform: uppercase;
}

.cbmuserloan-bottomcont {
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    justify-content: space-between;
}

.cbmuserloan-bytext {
    color: var(--dl-color-gray-700);
    width: 100%;
    font-size: 12px;
    font-style: normal;
    margin-top: 0px;
    text-align: left;
    font-weight: 400;
}

.cbmuserloan-datetext {
    color: var(--dl-color-gray-700);
    width: 100%;
    font-size: 12px;
    font-style: normal;
    margin-top: 0px;
    text-align: right;
    font-weight: 400;
}

.cbmuserloan-ledgerhistorybottomstrip {
    flex: 0 0 auto;
    width: 95%;
    height: auto;
    display: flex;
    align-self: center;
    margin-top: 0px;
    align-items: center;
    margin-bottom: var(--dl-space-space-unit);
    justify-content: flex-end;
}

.cbmuserloan-ledgerpagerbottombit {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.cbmuserloan-bottomleftarrow {
    fill: #D9D9D9;
    width: 30px;
    height: 30px;
    max-width: 30px;
    min-width: 30px;
    max-height: 30px;
    min-height: 30px;
}

.cbmuserloan-bottompageno {
    color: var(--dl-color-gray-900);
    width: 30px;
    height: auto;
    font-size: 20px;
    text-align: center;
}

.cbmuserloan-bottomrightarrow {
    fill: #D9D9D9;
    width: 30px;
    height: 30px;
    max-width: 30px;
    min-width: 30px;
    max-height: 30px;
    min-height: 30px;
}

@media(max-width: 479px) {
    .cbmuserloan-body {
        padding: var(--dl-space-space-halfunit);
    }

    .cbmuserloan-paybackloan {
        width: 100%;
    }

    .cbmuserloan-confirmloan {
        width: 100%;
    }

    .cbmuserloan-successloan {
        width: 100%;
    }

    .cbmuserloan-errorloan {
        width: 100%;
    }
}