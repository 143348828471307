.banktransferlist-banktransferlist {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    padding: var(--dl-space-space-unit);
    position: relative;
    box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.05);
    align-items: center;
    border-color: var(--dl-color-gray-900);
    border-width: 1px;
    border-radius: var(--dl-radius-radius-radius16);
    margin-bottom: var(--dl-space-space-halfunit);
    flex-direction: column;
    justify-content: flex-start;
    background-color: var(--dl-color-gray-white);
}

.banktransferlist-container {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding-top: 3px;
    padding-left: var(--dl-space-space-halfunit);
    margin-bottom: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-halfunit);
    padding-bottom: 3px;
    justify-content: space-between;
}

.banktransferlist-text {
    color: var(--dl-color-custom-newsalmon);
}

.banktransferlist-button {
    color: var(--dl-color-gray-900);
    display: flex;
    padding: var(--dl-space-space-halfunit);
    box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.15);
    border-color: rgba(217, 217, 217, 0.48);
    flex-direction: row;
}

.banktransferlist-text1 {
    align-self: center;
}

.banktransferlist-icon {
    fill: var(--dl-color-gray-900);
    width: 18px;
    height: 18px;
    align-self: center;
    margin-left: var(--dl-space-space-halfunit);
}

.banktransferlist-text2 {
    color: var(--dl-color-custom-redred);
    width: 100%;
    padding-top: var(--dl-space-space-halfunit);
    padding-left: var(--dl-space-space-halfunit);
    padding-right: var(--dl-space-space-halfunit);
}

.banktransferlist-form {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    padding-top: var(--dl-space-space-halfunit);
    padding-left: var(--dl-space-space-halfunit);
    padding-right: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-unit);
    justify-content: flex-start;
}

.banktransferlist-textinput {
    color: var(--dl-color-gray-500);
    width: 100%;
    border-color: var(--dl-color-gray-900);
    border-radius: var(--dl-radius-radius-radius16);
}

.banktransferlist-button1 {
    color: var(--dl-color-custom-redred);
    padding: var(--dl-space-space-halfunit);
    margin-left: var(--dl-space-space-unit);
    border-color: var(--dl-color-custom-redred);
    border-radius: var(--dl-radius-radius-radius16);
    padding-bottom: 8px;
}

.banktransferlist-select {
    width: 100%;
    height: var(--dl-size-size-large);
    padding: var(--dl-space-space-halfunit);
    margin-top: var(--dl-space-space-halfunit);
    border-color: var(--dl-color-gray-900);
    border-width: 1px;
}