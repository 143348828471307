:root {
  --dl-color-gray-500: #595959;
  --dl-color-gray-700: #999999;
  --dl-color-gray-900: #D9D9D9;
  --dl-size-size-large: 144px;
  --dl-size-size-small: 48px;
  --dl-color-danger-300: #A22020;
  --dl-color-danger-500: #BF2626;
  --dl-color-danger-700: #E14747;
  --dl-color-gray-black: #000000;
  --dl-color-gray-white: #FFFFFF;
  --dl-size-size-1200px: 1200px;
  --dl-size-size-medium: 96px;
  --dl-size-size-xlarge: 192px;
  --dl-size-size-xsmall: 16px;
  --dl-space-space-unit: 16px;
  --dl-color-primary-100: #003EB3;
  --dl-color-primary-300: #0074F0;
  --dl-color-primary-500: #14A9FF;
  --dl-color-primary-700: #85DCFF;
  --dl-color-success-300: #199033;
  --dl-color-success-500: #32A94C;
  --dl-color-success-700: #4CC366;
  --dl-size-size-xxlarge: 288px;
  --dl-size-size-maxwidth: 1400px;
  --dl-size-size-midsmall: 32px;
  --dl-color-bravhur-olive: #a3c20aff;
  --dl-color-custom-redred: #ce4032ff;
  --dl-radius-radius-round: 50%;
  --dl-color-bravhur-purple: #a41ec5ff;
  --dl-space-space-halfunit: 8px;
  --dl-space-space-sixunits: 96px;
  --dl-space-space-twounits: 32px;
  --dl-color-bravhur-sayblue: #330af5ff;
  --dl-color-bravhur-tritary: #0df2a2ff;
  --dl-radius-radius-radius2: 2px;
  --dl-radius-radius-radius4: 4px;
  --dl-radius-radius-radius8: 8px;
  --dl-space-space-fiveunits: 80px;
  --dl-space-space-fourunits: 64px;
  --dl-color-bravhur-lighcyan: #0099ccff;
  --dl-color-custom-happyblue: #02a3d9ff;
  --dl-color-custom-helloblue: #17568cff;
  --dl-color-custom-hellopink: #f05f6eff;
  --dl-color-custom-newsalmon: #ff9827ff;
  --dl-radius-radius-radius16: 16px;
  --dl-radius-radius-radius24: 24px;
  --dl-space-space-threeunits: 48px;
  --dl-color-custom-postoffice: #009899ff;
  --dl-color-new_category-vine: #dc21aaff;
  --dl-color-custom-krakenlogin: #008c72ff;
  --dl-color-new_category-cloud: #91d7e5ff;
  --dl-color-bravhur-splitorange: #f5d60aff;
  --dl-color-bravhur-stronggreen: #338f0aff;
  --dl-color-new_category-redish: #fd024cff;
  --dl-color-new_category-blueish: #0293cdff;
  --dl-color-bravhur-bravhur_color: #ff1562ff;
  --dl-color-bravhur-complementary: #00cc89ff;
  --dl-color-new_category-greenish: #099e25ff;
  --dl-color-new_category-orangish: #eee147ff;
  --dl-space-space-oneandhalfunits: 24px;
  --dl-color-new_category-purpleish: #860ae8ff;
  --dl-color-new_category-deeporange: #dc7223ff;


  --dl-color-cbm-cbm_red: #e85c3bff;
    --dl-color-cbm-cbm_blue: #54bad3ff;
    --dl-color-cbm-cbm_brown: #806e6aff;
    --dl-color-cbm-cbm_green: #91b673ff;
    --dl-color-cbm-cbm_orange: #c2a764ff;





}
.button {
  color: var(--dl-color-gray-black);
  display: inline-block;
  padding: 0.5rem 1rem;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  border-radius: 4px;
  background-color: var(--dl-color-gray-white);
}
.input {
  color: var(--dl-color-gray-black);
  cursor: auto;
  padding: 0.5rem 1rem;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  border-radius: 4px;
  background-color: var(--dl-color-gray-white);
}
.textarea {
  color: var(--dl-color-gray-500);
  cursor: auto;
  border-width: 1px;
  border-radius: 4px;
  background-color: var(--dl-color-gray-white);
}
.list {
  width: 100%;
  margin: 1em 0px 1em 0px;
  display: block;
  padding: 0px 0px 0px 1.5rem;
  list-style-type: none;
  list-style-position: outside;
}
.list-item {
  display: list-item;
}
.teleport-show {
  display: flex !important;
  transform: none !important;
}
.Content {
  font-size: 16px;
  font-family: Inter;
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.Heading {
  font-size: 32px;
  font-family: Inter;
  font-weight: 700;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
