.businesssettings-businesssettings {
    width: 100%;
    max-width: 600px;
    display: flex;
    margin-top: 8px;
    position: relative;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    background-color: var(--dl-color-gray-white);
}

.businesssettings-form {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
}

.businesssettings-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.businesssettings-icon {
    fill: var(--dl-color-custom-postoffice);
    width: 30px;
    height: 30px;
    max-width: 30px;
    min-width: 30px;
    align-self: center;
    max-height: 30px;
    min-height: 30px;
    margin-left: var(--dl-space-space-halfunit);
}

.businesssettings-text {
    color: var(--dl-color-custom-postoffice);
    width: 100%;
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    padding-left: var(--dl-space-space-halfunit);
    padding-right: var(--dl-space-space-halfunit);
}

.businesssettings-textinput {
    color: var(--dl-color-gray-500);
    width: 95%;
    outline: none;
    align-self: center;
    margin-top: var(--dl-space-space-oneandhalfunits);
    border-color: rgba(217, 217, 217, 0.5);
}

.businesssettings-textinput1 {
    color: var(--dl-color-gray-500);
    width: 95%;
    outline: none;
    align-self: center;
    margin-top: var(--dl-space-space-unit);
    border-color: rgba(217, 217, 217, 0.5);
}

.businesssettings-select {
    width: 95%;
    height: 40px;
    background: #ffffff;
    margin-top: var(--dl-space-space-unit);
    border-color: rgba(217, 217, 217, 0.5);
    border-width: 1px;
    padding-left: var(--dl-space-space-unit);
    border-radius: var(--dl-radius-radius-radius16);
    padding-right: var(--dl-space-space-unit);
}

.businesssettings-select1 {
    width: 95%;
    height: 40px;
    background: #ffffff;
    margin-top: var(--dl-space-space-unit);
    border-color: rgba(217, 217, 217, 0.5);
    border-width: 1px;
    padding-left: var(--dl-space-space-unit);
    border-radius: var(--dl-radius-radius-radius16);
    padding-right: var(--dl-space-space-unit);
}

.businesssettings-textarea {
    width: 95%;
    height: var(--dl-size-size-medium);
    resize: none;
    outline: none;
    padding: var(--dl-space-space-halfunit);
    margin-top: var(--dl-space-space-unit);
    border-color: rgba(217, 217, 217, 0.5);
    border-width: 1px;
}

.businesssettings-container1 {
    width: 100%;
    display: flex;
    margin-top: var(--dl-space-space-unit);
    align-items: center;
    justify-content: flex-start;
}

.businesssettings-icon2 {
    fill: var(--dl-color-custom-postoffice);
    width: 30px;
    height: 30px;
    max-width: 30px;
    min-width: 30px;
    max-height: 30px;
    min-height: 30px;
    margin-left: var(--dl-space-space-halfunit);
}

.businesssettings-text1 {
    color: var(--dl-color-custom-postoffice);
    width: 100%;
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    padding-left: var(--dl-space-space-halfunit);
    padding-right: var(--dl-space-space-halfunit);
}

.businesssettings-select2 {
    width: 95%;
    height: 40px;
    background: #ffffff;
    margin-top: var(--dl-space-space-oneandhalfunits);
    border-color: rgba(217, 217, 217, 0.5);
    border-width: 1px;
    padding-left: var(--dl-space-space-unit);
    border-radius: var(--dl-radius-radius-radius16);
    padding-right: var(--dl-space-space-unit);
}

.businesssettings-textinput2 {
    color: var(--dl-color-gray-500);
    width: 95%;
    outline: none;
    align-self: center;
    margin-top: var(--dl-space-space-unit);
    border-color: rgba(217, 217, 217, 0.5);
}

.businesssettings-textinput3 {
    color: var(--dl-color-gray-500);
    width: 95%;
    outline: none;
    align-self: center;
    margin-top: var(--dl-space-space-unit);
    border-color: rgba(217, 217, 217, 0.5);
}

.businesssettings-textinput4 {
    color: var(--dl-color-gray-500);
    width: 95%;
    outline: none;
    align-self: center;
    margin-top: var(--dl-space-space-unit);
    border-color: rgba(217, 217, 217, 0.5);
}

.businesssettings-textinput5 {
    color: var(--dl-color-gray-500);
    width: 95%;
    outline: none;
    align-self: center;
    margin-top: var(--dl-space-space-unit);
    border-color: rgba(217, 217, 217, 0.5);
}

.businesssettings-container2 {
    flex: 0 0 auto;
    width: 95%;
    height: auto;
    display: flex;
    padding: var(--dl-space-space-halfunit);
    margin-top: var(--dl-space-space-oneandhalfunits);
    align-items: center;
    justify-content: flex-end;
}

.businesssettings-button {
    color: var(--dl-color-gray-white);
    box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.15);
    font-style: normal;
    font-weight: 800;
    border-color: var(--dl-color-custom-postoffice);
    border-radius: var(--dl-radius-radius-radius8);
    background-color: var(--dl-color-custom-postoffice);
}

@media(max-width: 479px) {
    .businesssettings-form {
        width: 100%;
    }
}