.bankstrip-bankstrip {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    padding: var(--dl-space-space-halfunit);
    position: relative;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: var(--dl-space-space-halfunit);
    justify-content: space-between;
    background-color: var(--dl-color-gray-white);
}

.bankstrip-text {
    color: var(--dl-color-custom-postoffice);
    font-style: normal;
    font-weight: 700;
}

.bankstrip-text1 {
    color: var(--dl-color-custom-helloblue);
    font-style: normal;
    font-weight: 700;
}