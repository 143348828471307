.profile-container {
    top: 0;
    right: 0;
    width: 30%;
    height: 100%;
    display: flex;
    padding: var(--dl-space-space-oneandhalfunits);
    z-index: 1000;
    position: fixed;
    max-width: 500px;
    min-width: 300px;
    box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.13);
    max-height: 100%;
    min-height: 100%;
    align-items: flex-end;
    align-content: 0;
    flex-direction: column;
    justify-content: space-between;
    background-color: var(--dl-color-gray-white);
}

.profile-nav {
    flex: 1;
    width: 100%;
    height: 100%;
    display: flex;
    max-height: 100%;
    min-height: 100%;
    align-items: flex-end;
    flex-direction: column;
    justify-content: space-between;
}

.profile-closeicon {
    width: 24px;
    height: 24px;
    margin-bottom: var(--dl-space-space-unit);
}

.profile-avatar {
    width: 100px;
    align-self: center;
    object-fit: cover;
    border-radius: var(--dl-radius-radius-round);
}

.profile-username {
    color: var(--dl-color-gray-500);
    width: 100%;
    font-size: 18px;
    font-style: normal;
    margin-top: var(--dl-space-space-unit);
    text-align: center;
    font-weight: 500;
}

.profile-divider {
    flex: 0 0 auto;
    width: 80%;
    height: auto;
    display: flex;
    align-self: center;
    margin-top: var(--dl-space-space-unit);
    align-items: flex-start;
    border-color: var(--dl-color-gray-900);
    border-style: solid;
    border-width: 1px;
    margin-bottom: var(--dl-space-space-unit);
}

.profile-navlink {
    display: contents;
}

.profile-logoutcontainer {
    flex: 0 0 auto;
    width: 80%;
    height: auto;
    display: flex;
    align-self: center;
    margin-top: var(--dl-space-space-unit);
    align-items: center;
    justify-content: flex-start;
    text-decoration: none;
}

.profile-icon1 {
    fill: var(--dl-color-gray-500);
    width: 24px;
    height: 24px;
    margin-right: var(--dl-space-space-halfunit);
}

.profile-logouttext {
    color: var(--dl-color-gray-500);
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    margin-bottom: 0px;
}

.profile-extendercontainer {
    flex: 1;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}

.profile-divider02 {
    flex: 0 0 auto;
    width: 80%;
    height: auto;
    display: flex;
    align-self: center;
    margin-top: var(--dl-space-space-unit);
    align-items: flex-start;
    border-color: var(--dl-color-gray-900);
    border-style: solid;
    border-width: 1px;
    margin-bottom: var(--dl-space-space-unit);
}

.profile-navlink1 {
    display: contents;
}

.profile-logoutcontainer1 {
    flex: 0 0 auto;
    width: 80%;
    height: auto;
    display: flex;
    align-self: center;
    align-items: center;
    justify-content: flex-end;
    text-decoration: none;
}

.profile-logouttext1 {
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    margin-bottom: 0px;
}

.profile-logouticon {
    width: 24px;
    height: 24px;
    margin-left: var(--dl-space-space-halfunit);
}

.profile-root-class-name1 {
    top: 0px;
    right: 0px;
    bottom: 0px;
    height: 100%;
    display: none;
    position: absolute;
}

@media(max-width: 479px) {
    .profile-container {
        width: 100%;
        height: 100%;
        max-height: 100%;
        min-height: 100%;
    }

    .profile-nav {
        height: auto;
    }

    .profile-extendercontainer {
        flex: 1;
        height: 100%;
    }
}