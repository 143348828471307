.cashholders-container {
    width: 100%;
    display: flex;
    overflow: auto;
    min-height: 100vh;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
}

.cashholders-body {
    flex: 1;
    width: 100%;
    height: 100px;
    display: flex;
    max-width: var(--dl-size-size-1200);
    align-self: flex-start;
    margin-top: 52px;
    align-items: flex-start;
    padding-top: var(--dl-space-space-halfunit);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-halfunit);
    justify-content: flex-start;
}

.cashholders-maincontainer {
    flex: 1;
    width: 100%;
    height: 100%;
    display: flex;
    max-width: 600px;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
}

.cashholders-systemwarning {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    padding: var(--dl-space-space-unit);
    max-width: 600px;
    align-items: center;
    border-color: var(--dl-color-cbm-cbm_red);
    border-style: solid;
    border-width: 2px;
    border-radius: var(--dl-radius-radius-radius16);
    margin-bottom: var(--dl-space-space-halfunit);
    justify-content: center;
    background-color: var(--dl-color-cbm-cbm_red);
}

.cashholders-text {
    color: var(--dl-color-gray-white);
    font-size: 20px;
    font-style: normal;
    text-align: center;
    font-weight: 700;
}

.cashholders-screencontent {
    width: 100%;
    height: 100%;
    display: flex;
    max-width: 600px;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
}

.cashholders-container1 {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.05);
    align-items: center;
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-gray-900);
    border-width: 1px;
    padding-left: var(--dl-space-space-unit);
    border-radius: var(--dl-radius-radius-radius8);
    margin-bottom: var(--dl-space-space-halfunit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-unit);
    justify-content: flex-start;
}

.cashholders-text1 {
    width: 100%;
    font-size: 20px;
}

.cashholders-text2 {
    width: var(--dl-size-size-small);
    font-size: 20px;
    text-align: right;
}

.cashholders-searchcontent {
    flex: 1;
    width: 100%;
    height: 100%;
    display: flex;
    max-width: 600px;
    align-items: flex-start;
    flex-direction: column;
}

.cashholders-activecontainer {
    width: 100%;
    padding-top: var(--dl-space-space-unit);
    display: flex;
    max-width: 600px;
    align-items: flex-start;
    flex-direction: column;
}

@media(max-width: 479px) {
    .cashholders-body {
        padding: var(--dl-space-space-halfunit);
    }
}