.users-container {
    width: 100%;
    display: flex;
    overflow: auto;
    min-height: 100vh;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
}

.users-contentframe {
    flex: 1;
    width: 100%;
    height: 100%;
    display: flex;
    z-index: 20;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.users-gqlframe {
    flex: auto;
    width: 100%;
    height: 100%;
    min-height: 100%;
    display: flex;
    z-index: 20;
    align-items: flex-start;
    padding-top: 56px;
    padding-left: 56px;
    flex-direction: column;
    justify-content: top;
    position: absolute;
}

.users-contentcontainer {
    flex: 1;
    width: 100%;
    height: auto;
    display: flex;
    padding: var(--dl-space-space-halfunit);
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
}

.users-homepanel {
    flex: 1;
    width: 100%;
    height: auto;
    display: flex;
    max-width: var(--dl-size-size-1200px);
    max-height: auto;
    align-items: stretch;
    flex-direction: row;
    justify-content: flex-start;
}

.users-leftpanel {
    flex: 1;
    width: 50%;
    height: auto;
    display: flex;
    max-width: 600px;
    align-items: flex-start;
    flex-direction: column;
}

.users-leftpaneltext {
    color: var(--dl-color-custom-postoffice);
    width: 100%;
    font-size: 20px;
    font-style: normal;
    margin-top: var(--dl-space-space-halfunit);
    font-weight: 500;
    margin-left: 0px;
    padding-left: var(--dl-space-space-unit);
}

.users-orgaddercontainer {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    margin-top: var(--dl-space-space-halfunit);
    align-items: center;

    justify-content: flex-end;
}

.users-textinput {
    color: var(--dl-color-gray-500);
    width: 100%;
    border-color: var(--dl-color-gray-900);
    border-radius: var(--dl-radius-radius-radius16);
}

.users-bizadder {
    color: var(--dl-color-custom-postoffice);
    font-size: 14px;
    margin-left: var(--dl-space-space-halfunit);
    margin-right: 4px;
    text-transform: uppercase;
}

.users-userlistcontainer {
    flex: 1;
    width: 100%;
    height: auto;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
}

.users-employeelistinner {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    padding: 15px;
    align-items: flex-start;

    flex-direction: column;
    justify-content: flex-start;

}


.storescreen-container1 {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    max-width: 600px;
    align-items: center;

    margin-bottom: var(--dl-space-space-halfunit);

    justify-content: flex-start;
}

.storescreen-text1 {
    width: 100%;
    font-size: 20px;
}

.storescreen-text2 {
    width: var(--dl-size-size-small);
    font-size: 20px;
    text-align: right;
}

.storescreen-searchbar {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    position: relative;
    max-width: 600px;
    box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.05);
    align-items: center;
    padding-top: var(--dl-space-space-halfunit);
    border-color: var(--dl-color-gray-900);
    border-width: 1px;
    padding-left: var(--dl-space-space-unit);
    border-radius: var(--dl-radius-radius-radius8);
    margin-bottom: var(--dl-space-space-halfunit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-halfunit);
    justify-content: space-between;
    background-color: var(--dl-color-gray-white);
}

.storescreen-textinput {
    color: var(--dl-color-gray-black);
    width: 100%;
    height: 34px;
    border-color: var(--dl-color-gray-700);
    border-radius: var(--dl-radius-radius-radius16);
}

.storescreen-searchcontent {
    flex: 1;
    width: 100%;
    height: 100%;
    display: flex;
    max-width: 600px;
    align-items: flex-start;
    flex-direction: column;
}

.storescreen-activecontainer {
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}

.userscreen-searchcontent {
    flex: 1;
    width: 100%;
    height: 100%;
    display: flex;
    max-width: 600px;
    align-items: flex-start;
    flex-direction: column;
}

.userscreen-activecontainer {
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}

.userscreen-container1 {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    max-width: 600px;
    box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.05);
    align-items: center;
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-gray-900);
    border-width: 1px;
    padding-left: var(--dl-space-space-unit);
    border-radius: var(--dl-radius-radius-radius8);
    margin-bottom: var(--dl-space-space-halfunit);
    margin-top: var(--dl-space-space-halfunit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-unit);
    justify-content: flex-start;
}

.userscreen-text1 {
    width: 100%;
    font-size: 20px;
}

.userscreen-text2 {
    width: var(--dl-size-size-small);
    font-size: 20px;
    text-align: right;
}


.userscreen-searchbar {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    position: relative;
    max-width: 600px;
    box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.05);
    align-items: center;
    padding-top: var(--dl-space-space-halfunit);
    border-color: var(--dl-color-gray-900);
    border-width: 1px;
    padding-left: var(--dl-space-space-unit);
    border-radius: var(--dl-radius-radius-radius8);
    margin-bottom: var(--dl-space-space-halfunit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-halfunit);
    justify-content: space-between;
    background-color: var(--dl-color-gray-white);
}

.userscreen-icon {
    fill: var(--dl-color-gray-700);
    width: 30px;
    height: 30px;
    max-width: 30px;
    min-width: 30px;
    max-height: 30px;
    min-height: 30px;
    margin-right: var(--dl-space-space-halfunit);
}

.userscreen-textinput {
    color: var(--dl-color-gray-black);
    width: 100%;
    height: 34px;
    border-color: var(--dl-color-gray-700);
    border-radius: var(--dl-radius-radius-radius16);
}

.userscreen-icon2 {
    fill: var(--dl-color-gray-700);
    width: 30px;
    height: 30px;
    max-width: 30px;
    min-width: 30px;
    max-height: 30px;
    min-height: 30px;
    margin-left: var(--dl-space-space-halfunit);
}

@media(max-width: 479px) {
    .users-leftpanel {
        width: 100%;
    }

    .users-orgaddercontainer {
        padding-left: 0px;
        padding-right: 0px;
    }

    .users-bizadder {
        margin-left: var(--dl-space-space-halfunit);
    }

    .users-userlistcontainer {
        padding-left: 0px;
        padding-right: 0px;
    }

    .users-employeelistinner {
        padding-left: 0px;
        padding-right: 0px;
    }
}