.kycedititem-kycedititem {
    width: 100%;
    height: auto;
    display: flex;
    position: relative;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    background-color: var(--dl-color-gray-white);
}

.kycedititem-multibut {
    width: 100%;
    flex-direction: row;
    display: flex;
    flex-wrap: wrap;
    justify-content:space-between;
}

.kycedititem-k-cheader {
    width: 100%;
    height: auto;
    display: flex;
    padding: var(--dl-space-space-unit);
    position: relative;
    box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.05);
    align-items: stretch;
    border-color: var(--dl-color-gray-900);
    border-width: 1px;
    border-radius: 0px;
    justify-content: space-between;
}

.kycedititem-image {
    width: 140px;
    height: 140px;
    object-fit: contain;
    border-radius: 0px;
}

.kycedititem-container {
    width: 100%;
    height: auto;
    display: flex;
    padding: var(--dl-space-space-halfunit);
    align-self: flex-start;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
}

.kycedititem-container01 {
    flex: 1;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
}

.kycedititem-container02 {
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-end;
}

.kycedititem-waiting {
    color: #330af5;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
}

.kycedititem-processing {
    color: var(--dl-color-custom-newsalmon);
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
}

.kycedititem-registered {
    color: var(--dl-color-custom-krakenlogin);
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
}

.kycedititem-invalid {
    color: var(--dl-color-custom-redred);
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
}

.kycedititem-text {
    color: var(--dl-color-custom-redred);
    width: 100%;
    font-size: 20px;
    margin-top: 2px;
    text-align: right;
}

.kycedititem-container03 {
    flex: 1;
    width: 100%;
    height: auto;
    display: flex;
    align-self: flex-end;
    align-items: center;
    flex-direction: row;
    justify-content: flex-end;
    margin-bottom: 8px;
    margin-top: 4px;

}

.kycedititem-imagegrey {
    fill: #D9D9D9;
    width: 30px;
    height: 30px;
}

.kycedititem-imagegreen {
    fill: var(--dl-color-custom-krakenlogin);
    width: 30px;
        height: 30px;
}

.kycedititem-imageorange {
    fill: var(--dl-color-custom-newsalmon);
    width: 30px;
        height: 30px;
}

.kycedititem-imagered {
    fill: var(--dl-color-custom-redred);
    width: 30px;
        height: 30px;
}

.kycedititem-detgrey {
    fill: #D9D9D9;
    width: 30px;
        height: 30px;
}

.kycedititem-detgreen {
    fill: var(--dl-color-custom-krakenlogin);
    width: 30px;
        height: 30px;
}

.kycedititem-detorange {
    fill: var(--dl-color-custom-newsalmon);
    width: 30px;
        height: 30px;
}

.kycedititem-detred {
    fill: var(--dl-color-custom-redred);
    width: 30px;
        height: 30px;
}

.kycedititem-contgrey {
    fill: #D9D9D9;
    width: 30px;
        height: 30px;
    margin-left: 3px;
}

.kycedititem-contgreen {
    fill: var(--dl-color-custom-krakenlogin);
    width: 30px;
        height: 30px;
    margin-left: 3px;
}

.kycedititem-contorange {
    fill: var(--dl-color-custom-newsalmon);
    width: 30px;
        height: 30px;
    margin-left: 3px;
}

.kycedititem-contred {
    fill: var(--dl-color-custom-redred);
    width: 30px;
        height: 30px;
    margin-left: 3px;
}

.kycedititem-enhgrey {
    fill: #D9D9D9;
    width: 30px;
        height: 30px;
            margin-left: 3px;
}

.kycedititem-enhgreen {
    fill: var(--dl-color-custom-krakenlogin);
    width: 30px;
        height: 30px;
            margin-left: 3px;
}

.kycedititem-enhorange {
    fill: var(--dl-color-custom-newsalmon);
    width: 30px;
        height: 30px;
            margin-left: 3px;
}

.kycedititem-enhred {
    fill: var(--dl-color-custom-redred);
    width: 30px;
        height: 30px;
            margin-left: 3px;
}

.kycedititem-button {
    color: var(--dl-color-gray-white);
    width: var(--dl-size-size-medium);
    display: flex;
    padding: var(--dl-space-space-halfunit);
    align-self: flex-end;
    border-color: var(--dl-color-custom-newsalmon);
    padding-right: 8px;
    flex-direction: row;
    justify-content: space-between;
    background-color: var(--dl-color-custom-newsalmon);
}

.kycedititem-buttonpwd {
    color: var(--dl-color-gray-white);
    width: var(--dl-size-size-medium);
    display: flex;
    padding: var(--dl-space-space-halfunit);
    align-self: flex-end;
    border-color: var(--dl-color-custom-postoffice);
    padding-right: 8px;
    flex-direction: row;
    justify-content: space-between;
    background-color: var(--dl-color-custom-postoffice);
}

.kycedititem-text01 {
    align-self: center;
    font-style: normal;
    font-weight: 700;
}

.kycedititem-icon {
    fill: var(--dl-color-gray-white);
    width: 24px;
    height: 24px;
    align-self: center;
    padding-left: var(--dl-space-space-halfunit);
}

.kycedititem-button01 {
    color: var(--dl-color-gray-white);
    width: var(--dl-size-size-medium);
    display: flex;
    padding: var(--dl-space-space-halfunit);
    align-self: flex-end;
    align-items: center;
    border-color: var(--dl-color-custom-krakenlogin);
    flex-direction: row;
    justify-content: space-between;
    background-color: var(--dl-color-custom-krakenlogin);
}

.kycedititem-text02 {
    align-self: center;
    font-style: normal;
    font-weight: 700;
}

.kycedititem-icon02 {
    fill: var(--dl-color-gray-white);
    width: 24px;
    height: 24px;
}
.kycedititem-passwordchange {
    flex: 0 0 auto;
    width: 100%;
    height: 250px;
    display: flex;
    box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.05);
    margin-top: var(--dl-space-space-unit);
    align-items: center;
    border-color: var(--dl-color-gray-900);
    border-width: 1px;
    flex-direction: column;
    justify-content: space-between;
}

.kycedititem-text03 {
    color: var(--dl-color-custom-postoffice);
    width: 100%;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    padding-top: var(--dl-space-space-halfunit);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
}

.kycedititem-container04 {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    padding-top: var(--dl-space-space-halfunit);
    padding-left: var(--dl-space-space-halfunit);
    margin-bottom: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-halfunit);
    justify-content: flex-end;
}

.kycedititem-text04 {
    width: 100%;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
}

.kycedititem-button02 {
    color: var(--dl-color-custom-krakenlogin);
    width: var(--dl-size-size-medium);
    max-width: var(--dl-size-size-medium);
    min-width: var(--dl-size-size-medium);
    border-color: var(--dl-color-custom-krakenlogin);
}

.kycedititem-container05 {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    padding-top: var(--dl-space-space-halfunit);
    padding-left: var(--dl-space-space-halfunit);
    margin-bottom: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-halfunit);
    justify-content: flex-end;
}

.kycedititem-text05 {
    width: 100%;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
}

.kycedititem-button03 {
    color: var(--dl-color-custom-redred);
    width: var(--dl-size-size-medium);
    min-width: var(--dl-size-size-medium);
    border-color: var(--dl-color-custom-redred);
}

.kycedititem-container06 {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    padding-top: var(--dl-space-space-halfunit);
    padding-left: var(--dl-space-space-halfunit);
    margin-bottom: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-halfunit);
    justify-content: flex-end;
}

.kycedititem-text06 {
    width: 100%;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
}

.kycedititem-button04 {
    color: var(--dl-color-custom-happyblue);
    border-color: var(--dl-color-custom-happyblue);
}

.kycedititem-showpassword {
    flex: 0 0 auto;
    width: 100%;
    height: 250px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
}

.kycedititem-text07 {
    color: var(--dl-color-custom-postoffice);
    width: 100%;
    font-size: 20px;
    font-style: normal;
    text-align: center;
    font-weight: 500;
    padding-top: var(--dl-space-space-halfunit);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
}

.kycedititem-text08 {
    color: var(--dl-color-custom-redred);
    width: 100%;
    font-size: 32px;
    font-style: normal;
    text-align: center;
    font-weight: 700;
    padding-top: 0px;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
}

.kycedititem-button05 {
    margin-bottom: var(--dl-space-space-unit);
}

.kycedititem-allgood {
    flex: 0 0 auto;
    width: 100%;
    height: 250px;
    display: flex;
    box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.05);
    margin-top: var(--dl-space-space-unit);
    align-items: center;
    border-color: var(--dl-color-gray-900);
    border-width: 1px;
    flex-direction: column;
    justify-content: space-between;
}

.kycedititem-text09 {
    color: var(--dl-color-custom-postoffice);
    width: 100%;
    font-size: 20px;
    font-style: normal;
    text-align: center;
    font-weight: 500;
    padding-top: var(--dl-space-space-halfunit);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
}

.kycedititem-text10 {
    color: var(--dl-color-custom-krakenlogin);
    width: 100%;
    font-size: 32px;
    font-style: normal;
    text-align: center;
    font-weight: 700;
    padding-top: 0px;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
}

.kycedititem-button06 {
    margin-bottom: var(--dl-space-space-unit);
}

.kycedititem-detailedkycform {
    width: 100%;
    height: auto;
    display: flex;
    padding: var(--dl-space-space-unit);
    margin-top: var(--dl-space-space-unit);
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.kycedititem-page01 {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.kycedititem-textinput {
    width: 100%;
    border-radius: var(--dl-radius-radius-radius8);
    margin-bottom: var(--dl-space-space-unit);
}

.kycedititem-container07 {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: var(--dl-space-space-unit);
    flex-direction: row;
    justify-content: center;
}

.kycedititem-text11 {
    width: 50%;
    padding-top: var(--dl-space-space-halfunit);
    padding-left: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-halfunit);
}

.kycedititem-textinput1 {
    width: 50%;
    border-radius: var(--dl-radius-radius-radius8);
}

.kycedititem-select {
    width: 100%;
        height: 36px;
        border-radius: var(--dl-radius-radius-radius8);
        margin-bottom: var(--dl-space-space-unit);
        padding-left: var(--dl-space-space-unit);
        padding-right: var(--dl-space-space-unit);
}

.kycedititem-container08 {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    padding: 0px;
    margin-top: var(--dl-space-space-twounits);
    align-items: center;
    margin-bottom: var(--dl-space-space-unit);
    justify-content: space-between;
}

.kycedititem-button07 {
    color: var(--dl-color-gray-900);
    border-color: var(--dl-color-gray-900);
}

.kycedititem-container09 {
    flex: 0 0 auto;
    width: auto;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.kycedititem-icon04 {
    fill: var(--dl-color-custom-postoffice);
    width: var(--dl-size-size-xsmall);
    height: var(--dl-size-size-xsmall);
}

.kycedititem-icon06 {
    fill: var(--dl-color-gray-900);
    width: var(--dl-size-size-xsmall);
    height: var(--dl-size-size-xsmall);
    margin-left: var(--dl-space-space-halfunit);
}

.kycedititem-icon08 {
    fill: var(--dl-color-gray-900);
    width: var(--dl-size-size-xsmall);
    height: var(--dl-size-size-xsmall);
    margin-left: var(--dl-space-space-halfunit);
}

.kycedititem-icon10 {
    fill: var(--dl-color-gray-900);
    width: var(--dl-size-size-xsmall);
    height: var(--dl-size-size-xsmall);
    margin-left: var(--dl-space-space-halfunit);
}

.kycedititem-icon12 {
    fill: var(--dl-color-gray-900);
    width: var(--dl-size-size-xsmall);
    height: var(--dl-size-size-xsmall);
    margin-left: var(--dl-space-space-halfunit);
}

.kycedititem-button08 {
    color: var(--dl-color-custom-postoffice);
    border-color: var(--dl-color-custom-postoffice);
}

.kycedititem-page02 {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.kycedititem-textinput2 {
    width: 100%;
    border-radius: var(--dl-radius-radius-radius8);
    margin-bottom: var(--dl-space-space-unit);
}

.kycedititem-textinput3 {
    width: 100%;
    border-radius: var(--dl-radius-radius-radius8);
    margin-bottom: var(--dl-space-space-unit);
}

.kycedititem-textinput4 {
    width: 100%;
    margin-top: var(--dl-space-space-unit);
    border-radius: var(--dl-radius-radius-radius8);
    margin-bottom: var(--dl-space-space-unit);
}

.kycedititem-select1 {
    width: 100%;
        height: 36px;
        border-radius: var(--dl-radius-radius-radius8);
        margin-bottom: var(--dl-space-space-unit);
        padding-left: var(--dl-space-space-unit);
        padding-right: var(--dl-space-space-unit);
}

.kycedititem-textarea {
    width: 100%;
    margin-bottom: var(--dl-space-space-unit);
}

.kycedititem-container10 {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    padding: 0px;
    margin-top: var(--dl-space-space-twounits);
    align-items: center;
    margin-bottom: var(--dl-space-space-unit);
    justify-content: space-between;
}

.kycedititem-button09 {
    color: var(--dl-color-gray-900);
    border-color: var(--dl-color-gray-900);
}

.kycedititem-container11 {
    flex: 0 0 auto;
    width: auto;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.kycedititem-icon14 {
    fill: var(--dl-color-gray-900);
    width: var(--dl-size-size-xsmall);
    height: var(--dl-size-size-xsmall);
}

.kycedititem-icon16 {
    fill: var(--dl-color-custom-postoffice);
    width: var(--dl-size-size-xsmall);
    height: var(--dl-size-size-xsmall);
    margin-left: var(--dl-space-space-halfunit);
}

.kycedititem-icon18 {
    fill: var(--dl-color-gray-900);
    width: var(--dl-size-size-xsmall);
    height: var(--dl-size-size-xsmall);
    margin-left: var(--dl-space-space-halfunit);
}

.kycedititem-icon20 {
    fill: var(--dl-color-gray-900);
    width: var(--dl-size-size-xsmall);
    height: var(--dl-size-size-xsmall);
    margin-left: var(--dl-space-space-halfunit);
}

.kycedititem-icon22 {
    fill: var(--dl-color-gray-900);
    width: var(--dl-size-size-xsmall);
    height: var(--dl-size-size-xsmall);
    margin-left: var(--dl-space-space-halfunit);
}

.kycedititem-button10 {
    color: var(--dl-color-custom-postoffice);
    border-color: var(--dl-color-custom-postoffice);
}

.kycedititem-page03 {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.kycedititem-select2 {
    width: 100%;
        height: 36px;
        border-radius: var(--dl-radius-radius-radius8);
        margin-bottom: var(--dl-space-space-unit);
        padding-left: var(--dl-space-space-unit);
        padding-right: var(--dl-space-space-unit);
}

.kycedititem-container12 {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: var(--dl-space-space-unit);
    flex-direction: row;
    justify-content: space-between;
}

.kycedititem-text12 {
    width: 50%;
    padding-top: var(--dl-space-space-halfunit);
    padding-left: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-halfunit);
}

.kycedititem-container13 {
    width: 50%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
}

.kycedititem-container14 {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: var(--dl-space-space-unit);
    flex-direction: row;
    justify-content: space-between;
}

.kycedititem-text13 {
    width: 50%;
    padding-top: var(--dl-space-space-halfunit);
    padding-left: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-halfunit);
}

.kycedititem-container15 {
    width: 50%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
}

.kycedititem-container16 {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: var(--dl-space-space-unit);
    flex-direction: row;
    justify-content: space-between;
}

.kycedititem-text14 {
    width: 50%;
    padding-top: var(--dl-space-space-halfunit);
    padding-left: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-halfunit);
}

.kycedititem-textinput5 {
    width: 50%;
}

.kycedititem-select3 {
    width: 100%;
        height: 36px;
        border-radius: var(--dl-radius-radius-radius8);
        margin-bottom: var(--dl-space-space-unit);
        padding-left: var(--dl-space-space-unit);
        padding-right: var(--dl-space-space-unit);
}

.kycedititem-textinput6 {
    width: 100%;
    margin-top: 0px;
    border-radius: var(--dl-radius-radius-radius8);
    margin-bottom: var(--dl-space-space-unit);
}

.kycedititem-container17 {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: var(--dl-space-space-unit);
    flex-direction: row;
    justify-content: space-between;
}

.kycedititem-text15 {
    width: 50%;
    padding-top: var(--dl-space-space-halfunit);
    padding-left: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-halfunit);
}

.kycedititem-container18 {
    width: 50%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
}

.kycedititem-container19 {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: var(--dl-space-space-unit);
    flex-direction: row;
    justify-content: space-between;
}

.kycedititem-text16 {
    width: 50%;
    padding-top: var(--dl-space-space-halfunit);
    padding-left: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-halfunit);
}

.kycedititem-container20 {
    width: 50%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
}

.kycedititem-container21 {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    padding: 0px;
    margin-top: var(--dl-space-space-twounits);
    align-items: center;
    margin-bottom: var(--dl-space-space-unit);
    justify-content: space-between;
}

.kycedititem-button11 {
    color: var(--dl-color-gray-900);
    border-color: var(--dl-color-gray-900);
}

.kycedititem-container22 {
    flex: 0 0 auto;
    width: auto;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.kycedititem-icon24 {
    fill: var(--dl-color-gray-900);
    width: var(--dl-size-size-xsmall);
    height: var(--dl-size-size-xsmall);
}

.kycedititem-icon26 {
    fill: var(--dl-color-gray-900);
    width: var(--dl-size-size-xsmall);
    height: var(--dl-size-size-xsmall);
    margin-left: var(--dl-space-space-halfunit);
}

.kycedititem-icon28 {
    fill: var(--dl-color-custom-postoffice);
    width: var(--dl-size-size-xsmall);
    height: var(--dl-size-size-xsmall);
    margin-left: var(--dl-space-space-halfunit);
}

.kycedititem-icon30 {
    fill: var(--dl-color-gray-900);
    width: var(--dl-size-size-xsmall);
    height: var(--dl-size-size-xsmall);
    margin-left: var(--dl-space-space-halfunit);
}

.kycedititem-icon32 {
    fill: var(--dl-color-gray-900);
    width: var(--dl-size-size-xsmall);
    height: var(--dl-size-size-xsmall);
    margin-left: var(--dl-space-space-halfunit);
}

.kycedititem-button12 {
    color: var(--dl-color-custom-postoffice);
    border-color: var(--dl-color-custom-postoffice);
}

.kycedititem-page04 {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.kycedititem-text17 {
    color: var(--dl-color-custom-postoffice);
    width: 100%;
    font-style: normal;
    font-weight: 700;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-unit);
}

.kycedititem-select4 {
    width: 100%;
        height: 36px;
        border-radius: var(--dl-radius-radius-radius8);
        margin-bottom: var(--dl-space-space-unit);
        padding-left: var(--dl-space-space-unit);
        padding-right: var(--dl-space-space-unit);
}

.kycedititem-container23 {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    padding: 0px;
    margin-top: var(--dl-space-space-twounits);
    align-items: center;
    margin-bottom: var(--dl-space-space-unit);
    padding-bottom: 0px;
    justify-content: space-between;
}

.kycedititem-button13 {
    color: var(--dl-color-gray-900);
    border-color: var(--dl-color-gray-900);
}

.kycedititem-container24 {
    flex: 0 0 auto;
    width: auto;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.kycedititem-icon34 {
    fill: var(--dl-color-gray-900);
    width: var(--dl-size-size-xsmall);
    height: var(--dl-size-size-xsmall);
}

.kycedititem-icon36 {
    fill: var(--dl-color-gray-900);
    width: var(--dl-size-size-xsmall);
    height: var(--dl-size-size-xsmall);
    margin-left: var(--dl-space-space-halfunit);
}

.kycedititem-icon38 {
    fill: var(--dl-color-gray-900);
    width: var(--dl-size-size-xsmall);
    height: var(--dl-size-size-xsmall);
    margin-left: var(--dl-space-space-halfunit);
}

.kycedititem-icon40 {
    fill: var(--dl-color-custom-postoffice);
    width: var(--dl-size-size-xsmall);
    height: var(--dl-size-size-xsmall);
    margin-left: var(--dl-space-space-halfunit);
}

.kycedititem-icon42 {
    fill: var(--dl-color-gray-900);
    width: var(--dl-size-size-xsmall);
    height: var(--dl-size-size-xsmall);
    margin-left: var(--dl-space-space-halfunit);
}

.kycedititem-button14 {
    color: var(--dl-color-custom-postoffice);
    border-color: var(--dl-color-custom-postoffice);
}

.kycedititem-page05 {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.kycedititem-text18 {
    color: var(--dl-color-custom-postoffice);
    width: 100%;
    font-style: normal;
    text-align: center;
    font-weight: 700;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
}

.kycedititem-container25 {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    padding: 0px;
    margin-top: var(--dl-space-space-twounits);
    align-items: center;
    margin-bottom: var(--dl-space-space-unit);
    padding-bottom: 0px;
    justify-content: space-between;
}

.kycedititem-button15 {
    color: var(--dl-color-custom-redred);
    border-color: var(--dl-color-custom-redred);
}

.kycedititem-container26 {
    flex: 0 0 auto;
    width: auto;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.kycedititem-icon44 {
    fill: var(--dl-color-gray-900);
    width: var(--dl-size-size-xsmall);
    height: var(--dl-size-size-xsmall);
}

.kycedititem-icon46 {
    fill: var(--dl-color-gray-900);
    width: var(--dl-size-size-xsmall);
    height: var(--dl-size-size-xsmall);
    margin-left: var(--dl-space-space-halfunit);
}

.kycedititem-icon48 {
    fill: var(--dl-color-gray-900);
    width: var(--dl-size-size-xsmall);
    height: var(--dl-size-size-xsmall);
    margin-left: var(--dl-space-space-halfunit);
}

.kycedititem-icon50 {
    fill: var(--dl-color-gray-900);
    width: var(--dl-size-size-xsmall);
    height: var(--dl-size-size-xsmall);
    margin-left: var(--dl-space-space-halfunit);
}

.kycedititem-icon52 {
    fill: var(--dl-color-custom-postoffice);
    width: var(--dl-size-size-xsmall);
    height: var(--dl-size-size-xsmall);
    margin-left: var(--dl-space-space-halfunit);
}

.kycedititem-button16 {
    color: var(--dl-color-custom-postoffice);
    border-color: var(--dl-color-custom-postoffice);
}

.floatinccameraicon {
    fill: var(--dl-color-gray-900);
    width: 30px;
    height: 30px;
    align-self: center;
}



@media(max-width: 479px) {

.kycedititem-k-cheader {

        padding: var(--dl-space-space-halfunit);

    }

    .kycedititem-detailedkycform {
        padding-left: var(--dl-space-space-halfunit);
        padding-right: var(--dl-space-space-halfunit);
    }
}