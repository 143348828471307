.entitymemeberremove-entitylistitem {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    position: relative;
    box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.05);
    align-items: center;
    padding-top: var(--dl-space-space-halfunit);
    border-color: var(--dl-color-gray-900);
    border-width: 1px;
    padding-left: var(--dl-space-space-unit);
    border-radius: var(--dl-radius-radius-radius8);
    margin-bottom: var(--dl-space-space-halfunit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-halfunit);
    justify-content: flex-start;
    background-color: var(--dl-color-gray-white);
}

.entitymemeberremove-image {
    width: 64px;
    object-fit: cover;
    border-radius: var(--dl-radius-radius-round);
}

.entitymemeberremove-container {
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
}

.entitymemeberremove-text {
    color: var(--dl-color-gray-500);
    width: 100%;
    font-size: 18px;
    font-style: normal;
    text-align: left;
    font-weight: 700;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
}

.entitymemeberremove-button {
    color: var(--dl-color-gray-white);
    padding: 3px;
    font-style: normal;
    font-weight: 700;
    padding-top: var(--dl-space-space-halfunit);
    border-color: var(--dl-color-cbm-cbm_red);
    padding-left: var(--dl-space-space-halfunit);
    border-radius: var(--dl-radius-radius-radius16);
    padding-right: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-halfunit);
    background-color: var(--dl-color-cbm-cbm_red);
}

.entitymemeberremove-icon {
    fill: var(--dl-color-cbm-cbm_red);
    width: 30px;
    height: 30px;
    max-width: 30px;
    min-width: 30px;
    max-height: 30px;
    min-height: 30px;
}