.ledgerview-container {
  width: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
  background-color: var(--dl-color-gray-white);
}

.ledgerview-ledgernamestrip {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.ledgerview-ledgericon {
  fill: var(--dl-color-custom-postoffice);
  width: 30px;
  height: 30px;
  max-width: 30px;
  min-width: 30px;
  max-height: 30px;
  min-height: 30px;
  margin-left: var(--dl-space-space-halfunit);
}

.ledgerview-ledgername {
  color: var(--dl-color-custom-postoffice);
  width: 100%;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  padding-left: var(--dl-space-space-halfunit);
  padding-right: var(--dl-space-space-halfunit);
}

.ledgerview-ledgerchart {
  flex: 0 0 auto;
  width: 95%;
  max-width: 600px;
  height: 175px;
  display: flex;
  align-self: center;
  box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.05);
  margin-top: var(--dl-space-space-unit);
  align-items: flex-start;
  border-color: rgba(217, 217, 217, 0.5);
  border-style: dashed;
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius24);
}

.ledgerview-balancestrip {
  flex: 0 0 auto;
  width: 95%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  align-self: center;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  justify-content: space-between;
}

.ledgerview-ledgerbalanetext {
  color: var(--dl-color-custom-postoffice);
  font-size: 22px;
  font-style: normal;
  text-align: left;
  font-weight: 700;
}

.ledgerview-ledgerbalanetext-extra {
  color: var(--dl-color-gray-700);
  font-size: 18;
  font-style: normal;
  text-align: left;
  font-weight: 700;
}

.ledgerview-balanceamounttext {
  color: var(--dl-color-new_category-blueish);
  font-size: 22px;
  font-style: normal;
  text-align: right;
  font-weight: 700;
}

.ledgerview-ledgersmallprint {
  color: var(--dl-color-gray-700);
  width: 95%;
  align-self: center;
  margin-top: var(--dl-space-space-halfunit);
  text-align: center;
}

.ledgerview-ledgerhistorytopstrip {
  flex: 0 0 auto;
  width: 90%;
  height: auto;
  display: flex;
  align-self: center;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  justify-content: space-between;
}

.ledgerview-transactioncategoryselector {
  background: white;
  border-color: rgba(217, 217, 217, 0.5);
  border-width: 1px;
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius16);
  padding-right: var(--dl-space-space-unit);
}

.ledgerview-ledgerpagingtopbit {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.ledgerview-topleftarrow {
  fill: #D9D9D9;
  width: 30px;
  height: 30px;
  max-width: 30px;
  min-width: 30px;
  max-height: 30px;
  min-height: 30px;
}

.ledgerview-toppageno {
  color: var(--dl-color-gray-900);
  width: 30px;
  height: auto;
  font-size: 20px;
  text-align: center;
}

.ledgerview-toprightarrow {
  fill: #D9D9D9;
  width: 30px;
  height: 30px;
  max-width: 30px;
  min-width: 30px;
  max-height: 30px;
  min-height: 30px;
}

.ledgerview-transactionlist {
  flex: 0 0 auto;
  width: 95%;
  height: auto;
  display: flex;
  align-self: center;
  margin-top: var(--dl-space-space-halfunit);
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.ledgerview-ledgerhistorybottomstrip {
  flex: 0 0 auto;
  width: 90%;
  height: auto;
  display: flex;
  align-self: center;
  margin-top: 0px;
  align-items: center;
  margin-bottom: var(--dl-space-space-unit);
  justify-content: flex-end;
}

.ledgerview-ledgerpagerbottombit {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.ledgerview-bottomleftarrow {
  fill: #D9D9D9;
  width: 30px;
  height: 30px;
  max-width: 30px;
  min-width: 30px;
  max-height: 30px;
  min-height: 30px;
}

.ledgerview-bottompageno {
  color: var(--dl-color-gray-900);
  width: 30px;
  height: auto;
  font-size: 20px;
  text-align: center;
}

.ledgerview-bottomrightarrow {
  fill: #D9D9D9;
  width: 30px;
  height: 30px;
  max-width: 30px;
  min-width: 30px;
  max-height: 30px;
  min-height: 30px;
}

.ledgerview-root-class-name {
  width: 100%;
}