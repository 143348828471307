.banking-container {
    width: 100%;
    display: flex;
    overflow: auto;
    min-height: 100vh;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
}

.banking-contentframe {
    flex: 1;
    width: 100%;
    height: auto;
    display: flex;
    z-index: 20;
    align-items: center;
    padding-left: 0px;
    flex-direction: column;
    justify-content: center;
}

.banking-contentcontainer {
    flex: 1;
    width: 100%;
    height: auto;
    display: flex;
    padding: var(--dl-space-space-halfunit);
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
}

.banking-homepanel {
    flex: 1;
    width: 100%;
    height: auto;
    display: flex;
    position: relative;
    max-width: 600px;
    align-items: flex-start;
    padding-left: 8px;
    padding-right: 8px;
    flex-direction: column;
    justify-content: flex-start;
}

.banking-text {
    color: var(--dl-color-custom-postoffice);
    width: 100%;
    font-size: 1.5rem;
}

.banking-container1 {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.banking-button {
    color: var(--dl-color-gray-white);
    display: flex;
    align-self: center;
    padding-top: var(--dl-space-space-halfunit);
    border-color: var(--dl-color-custom-postoffice);
    padding-left: var(--dl-space-space-halfunit);
    padding-right: var(--dl-space-space-halfunit);
    flex-direction: row;
    padding-bottom: var(--dl-space-space-halfunit);
    background-color: var(--dl-color-custom-postoffice);
}

.banking-text1 {
    align-self: center;
    font-style: normal;
    font-weight: 700;
}

.banking-icon {
    fill: var(--dl-color-gray-white);
    width: 24px;
    height: 24px;
    align-self: center;
    margin-left: var(--dl-space-space-halfunit);
}

.banking-container2 {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    align-self: center;
    margin-top: var(--dl-space-space-unit);
    align-items: flex-start;
    border-color: var(--dl-color-gray-900);
    border-style: solid;
    border-width: 2px;
    margin-bottom: var(--dl-space-space-unit);
}

.bankingscreen-container1 {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    max-width: 600px;
    box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.05);
    align-items: center;
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-gray-900);
    border-width: 1px;
    padding-left: var(--dl-space-space-unit);
    border-radius: var(--dl-radius-radius-radius8);
    margin-bottom: var(--dl-space-space-halfunit);
    margin-top: var(--dl-space-space-halfunit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-unit);
    justify-content: flex-start;
}

.bankingscreen-text1 {
    width: 100%;
    font-size: 20px;
}

.bankingscreen-button {
    color: var(--dl-color-gray-white);
    box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.05);
    font-style: normal;
    font-weight: 700;
    border-color: var(--dl-color-cbm-cbm_green);
    border-radius: var(--dl-radius-radius-radius8);
    background-color: var(--dl-color-cbm-cbm_green);
}

.bankingscreen-searchcontent {
    flex: 1;
    width: 100%;
    height: 100%;
    display: flex;
    max-width: 600px;
    align-items: flex-start;
    flex-direction: column;
}

.bankingscreen-activecontainer {
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}


@media(max-width: 991px) {
    .banking-homepanel {
        max-width: 100%;
    }
}

@media(max-width: 767px) {
    .banking-homepanel {
        max-width: 100%;
    }
}