.entityeditor-entityeditor {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.02);
    align-items: center;
    border-color: var(--dl-color-gray-900);
    border-style: solid;
    border-width: 1px;
    border-radius: var(--dl-radius-radius-radius16);
    flex-direction: column;
    justify-content: flex-start;
    background-color: var(--dl-color-gray-white);
}

.entityeditor-container {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    align-items: flex-start;
    padding-top: var(--dl-space-space-halfunit);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-halfunit);
    justify-content: space-between;
}

.entityeditor-text {
    color: var(--dl-color-gray-700);
    font-size: 12px;
}

.entityeditor-text1 {
    color: var(--dl-color-gray-700);
    font-size: 12px;
}

.entityeditor-text2 {
    color: var(--dl-color-custom-krakenlogin);
    align-self: flex-start;
    font-style: normal;
    margin-top: var(--dl-space-space-unit);
    font-weight: 500;
    margin-left: var(--dl-space-space-unit);
    margin-bottom: var(--dl-space-space-unit);
}

.entityeditor-container1 {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    align-items: flex-start;
    margin-left: 0px;
    padding-left: var(--dl-space-space-halfunit);
    padding-right: var(--dl-space-space-halfunit);
    flex-direction: column;
    justify-content: flex-start;
}

.entityeditor-container2 {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    padding-top: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-halfunit);
    justify-content: space-between;
}

.entityeditor-text3 {
    color: var(--dl-color-custom-krakenlogin);
    align-self: flex-start;
    font-style: normal;
    margin-top: var(--dl-space-space-halfunit);
    font-weight: 500;
    margin-left: var(--dl-space-space-unit);
    margin-bottom: var(--dl-space-space-halfunit);
}

.entityeditor-icon {
    fill: var(--dl-color-custom-krakenlogin);
    width: var(--dl-size-size-midsmall);
    height: var(--dl-size-size-midsmall);
}

.entityeditor-credaddmain {
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;
    padding-top: 0px;
    padding-left: var(--dl-space-space-halfunit);
    margin-bottom: var(--dl-space-space-halfunit);
    padding-right: var(--dl-space-space-halfunit);
    flex-direction: column;
    padding-bottom: 0px;
    justify-content: flex-start;
}

.entityeditor-emailaddmain {
    flex: 1;
    width: 100%;
    height: auto;
    display: flex;
    padding: var(--dl-space-space-halfunit);
    box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.025);
    align-items: center;
    border-color: var(--dl-color-gray-900);
    border-width: 1px;
    border-radius: var(--dl-radius-radius-radius16);
    justify-content: flex-end;
}

.entityeditor-emailaddinput {
    border-color: var(--dl-color-gray-700);
    border-radius: var(--dl-radius-radius-radius16);
    width: 100%;
}

.entityeditor-emailaddbutton {
    color: var(--dl-color-custom-krakenlogin);
    margin-left: var(--dl-space-space-halfunit);
    border-color: var(--dl-color-custom-krakenlogin);
}

.entityeditor-phoneaddmain {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    padding: var(--dl-space-space-halfunit);
    box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.025);
    margin-top: var(--dl-space-space-halfunit);
    align-items: center;
    border-color: var(--dl-color-gray-900);
    border-width: 1px;
    border-radius: var(--dl-radius-radius-radius16);
    justify-content: flex-end;
}

.entityeditor-phoneaddinput {
    border-color: var(--dl-color-gray-700);
    border-radius: var(--dl-radius-radius-radius16);
    width: 100%;
}

.entityeditor-phoneaddbutton {
    color: var(--dl-color-custom-krakenlogin);
    margin-left: var(--dl-space-space-halfunit);
    border-color: var(--dl-color-custom-krakenlogin);
}

.entityeditor-container3 {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    align-items: flex-start;
    margin-left: 0px;
    padding-left: var(--dl-space-space-halfunit);
    padding-right: var(--dl-space-space-halfunit);
    flex-direction: column;
    justify-content: flex-start;
}

.entityeditor-text4 {
    color: var(--dl-color-custom-krakenlogin);
    align-self: flex-start;
    font-style: normal;
    margin-top: var(--dl-space-space-unit);
    font-weight: 500;
    margin-left: var(--dl-space-space-unit);
    margin-bottom: var(--dl-space-space-halfunit);
}

.entityeditor-container4 {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    align-self: stretch;
    margin-top: var(--dl-space-space-halfunit);
    align-items: center;
    margin-left: 0px;
    margin-right: 0px;
    padding-left: var(--dl-space-space-halfunit);
    margin-bottom: 0px;
    padding-right: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-halfunit);
    justify-content: space-between;
}

.entityeditor-icon2 {
    fill: var(--dl-color-custom-redred);
    width: var(--dl-size-size-midsmall);
    height: var(--dl-size-size-midsmall);
    margin-left: var(--dl-space-space-halfunit);
}

.entityeditor-text5 {
    color: var(--dl-color-custom-redred);
    margin-left: var(--dl-space-space-halfunit);
    margin-right: var(--dl-space-space-halfunit);
}

.entityeditor-button {
    color: var(--dl-color-custom-krakenlogin);
    width: var(--dl-size-size-large);
    text-align: center;
    border-color: var(--dl-color-custom-krakenlogin);
}

.entityeditor-container5 {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    margin-top: var(--dl-space-space-halfunit);
    align-items: center;
    padding-left: var(--dl-space-space-halfunit);
    padding-right: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-halfunit);
    justify-content: space-between;
}

.entityeditor-icon4 {
    fill: var(--dl-color-custom-krakenlogin);
    width: var(--dl-size-size-midsmall);
    height: var(--dl-size-size-midsmall);
    margin-left: var(--dl-space-space-halfunit);
}

.entityeditor-text6 {
    color: var(--dl-color-custom-krakenlogin);
    margin-left: var(--dl-space-space-halfunit);
    margin-right: var(--dl-space-space-halfunit);
}

.entityeditor-button1 {
    color: var(--dl-color-custom-redred);
    width: var(--dl-size-size-large);
    text-align: center;
    border-color: var(--dl-color-custom-redred);
}

.entityeditor-text7 {
    color: var(--dl-color-custom-krakenlogin);
    align-self: flex-start;
    font-style: normal;
    margin-top: var(--dl-space-space-unit);
    font-weight: 500;
    margin-left: var(--dl-space-space-unit);
    margin-bottom: var(--dl-space-space-unit);
}

.entityeditor-container6 {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    align-items: flex-start;
    margin-left: 0px;
    padding-left: var(--dl-space-space-halfunit);
    padding-right: var(--dl-space-space-halfunit);
    flex-direction: column;
    justify-content: flex-start;
}

.entityeditor-container7 {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    padding-top: var(--dl-space-space-halfunit);
    padding-right: var(--dl-space-space-unit);
    justify-content: space-between;
}

.entityeditor-text8 {
    color: var(--dl-color-custom-krakenlogin);
    align-self: flex-start;
    font-style: normal;
    margin-top: var(--dl-space-space-halfunit);
    font-weight: 500;
    margin-left: var(--dl-space-space-unit);
}

.entityeditor-container8 {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    align-items: flex-start;
    margin-left: 0px;
    padding-left: var(--dl-space-space-halfunit);
    padding-right: var(--dl-space-space-halfunit);
    flex-direction: column;
    justify-content: flex-start;
}

.entityeditor-admincontainer {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
}

.entityeditor-adminheadline {
    color: var(--dl-color-custom-krakenlogin);
    align-self: flex-start;
    font-style: normal;
    margin-top: var(--dl-space-space-unit);
    font-weight: 500;
    margin-left: var(--dl-space-space-unit);
    margin-bottom: var(--dl-space-space-halfunit);
}

.entityeditor-notadmincontainer {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    align-self: stretch;
    margin-top: var(--dl-space-space-halfunit);
    align-items: center;
    margin-left: 0px;
    margin-right: 0px;
    padding-left: var(--dl-space-space-halfunit);
    margin-bottom: 0px;
    padding-right: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-halfunit);
    justify-content: space-between;
}

.entityeditor-notadminicon {
    fill: var(--dl-color-custom-redred);
    width: var(--dl-size-size-midsmall);
    height: var(--dl-size-size-midsmall);
    min-width: var(--dl-size-size-midsmall);
    align-self: center;
    min-height: var(--dl-size-size-midsmall);
    margin-left: var(--dl-space-space-halfunit);
}

.entityeditor-notadmintext {
    color: var(--dl-color-custom-redred);
    margin-left: var(--dl-space-space-halfunit);
    margin-right: var(--dl-space-space-halfunit);
}

.entityeditor-notadminbutton {
    color: var(--dl-color-custom-krakenlogin);
    width: var(--dl-size-size-large);
    text-align: center;
    border-color: var(--dl-color-custom-krakenlogin);
}

.entityeditor-isadmincontainer {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    margin-top: var(--dl-space-space-halfunit);
    align-items: center;
    padding-left: var(--dl-space-space-halfunit);
    padding-right: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-halfunit);
    justify-content: space-between;
}

.entityeditor-isadminicon {
    fill: var(--dl-color-custom-krakenlogin);
    width: var(--dl-size-size-midsmall);
    height: var(--dl-size-size-midsmall);
    margin-left: var(--dl-space-space-halfunit);
}

.entityeditor-isadmintext {
    color: var(--dl-color-custom-krakenlogin);
    margin-left: var(--dl-space-space-halfunit);
    margin-right: var(--dl-space-space-halfunit);
}

.entityeditor-isadminbutton {
    color: var(--dl-color-custom-redred);
    width: var(--dl-size-size-large);
    text-align: center;
    border-color: var(--dl-color-custom-redred);
}