.cbmcolorfulbar-cbmcolorfulbar {
    flex: 0 0 auto;
    width: 100%;
    height: 150px;
    display: flex;
    position: relative;
    max-width: 600px;
    box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.05);
    align-items: center;
    border-radius: var(--dl-radius-radius-radius8);
    flex-direction: column;
    justify-content: space-between;
    background-color: var(--dl-color-gray-white);
}

.cbmcolorfulbar-topbar {
    flex: 0 0 auto;
    width: 100%;
    height: var(--dl-size-size-small);
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.cbmcolorfulbar-link {
    display: contents;
}

.cbmcolorfulbar-container {
    flex: 0 0 auto;
    width: 20%;
    height: var(--dl-size-size-small);
    display: flex;
    position: relative;
    align-items: center;
    border-radius: 0px;
    justify-content: center;
    text-decoration: none;
    background-color: var(--dl-color-cbm-cbm_blue);
    border-top-left-radius: var(--dl-radius-radius-radius8);
}

.cbmcolorfulbar-text {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
}

.cbmcolorfulbar-icon {
    right: 4px;
    width: 16px;
    bottom: 2px;
    height: 16px;
    position: absolute;
    max-width: 16px;
    min-width: 16px;
    max-height: 16px;
    min-height: 16px;
}

.cbmcolorfulbar-link1 {
    display: contents;
}

.cbmcolorfulbar-container1 {
    flex: 0 0 auto;
    width: 20%;
    height: var(--dl-size-size-small);
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    background-color: var(--dl-color-cbm-cbm_green);
}

.cbmcolorfulbar-text1 {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
}

.cbmcolorfulbar-icon2 {
    right: 4px;
    width: 16px;
    bottom: 4px;
    height: 16px;
    position: absolute;
    max-width: 16px;
    min-width: 16px;
    max-height: 16px;
    min-height: 16px;
}

.cbmcolorfulbar-link2 {
    display: contents;
}

.cbmcolorfulbar-container2 {
    flex: 0 0 auto;
    width: 20%;
    height: var(--dl-size-size-small);
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    background-color: var(--dl-color-cbm-cbm_red);
}

.cbmcolorfulbar-text2 {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
}

.cbmcolorfulbar-icon4 {
    right: 4px;
    width: 16px;
    bottom: 4px;
    height: 16px;
    position: absolute;
    max-width: 16px;
    min-width: 16px;
    max-height: 16px;
    min-height: 16px;
}

.cbmcolorfulbar-link3 {
    display: contents;
}

.cbmcolorfulbar-container3 {
    flex: 0 0 auto;
    width: 40%;
    height: var(--dl-size-size-small);
    display: flex;
    position: relative;
    align-items: center;
    border-radius: 0px;
    justify-content: center;
    text-decoration: none;
    background-color: var(--dl-color-cbm-cbm_orange);
    border-top-right-radius: var(--dl-radius-radius-radius8);
}

.cbmcolorfulbar-text3 {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
}

.cbmcolorfulbar-icon6 {
    right: 4px;
    width: 16px;
    bottom: 4px;
    height: 16px;
    position: absolute;
    max-width: 16px;
    min-width: 16px;
    max-height: 16px;
    min-height: 16px;
}

.cbmcolorfulbar-bottombar {
    flex: 0 0 auto;
    width: 100%;
    height: var(--dl-size-size-small);
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.cbmcolorfulbar-container4 {
    flex: 0 0 auto;
    width: 20%;
    height: var(--dl-size-size-small);
    display: flex;
    align-items: center;
    border-radius: 0px;
    justify-content: center;
    background-color: var(--dl-color-cbm-cbm_orange);
    border-bottom-left-radius: var(--dl-radius-radius-radius8);
}

.cbmcolorfulbar-text4 {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
}

.cbmcolorfulbar-container5 {
    flex: 0 0 auto;
    width: 20%;
    height: var(--dl-size-size-small);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--dl-color-cbm-cbm_blue);
}

.cbmcolorfulbar-text5 {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
}

.cbmcolorfulbar-container6 {
    flex: 0 0 auto;
    width: 20%;
    height: var(--dl-size-size-small);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--dl-color-cbm-cbm_green);
}

.cbmcolorfulbar-text6 {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
}

.cbmcolorfulbar-container7 {
    flex: 0 0 auto;
    width: 20%;
    height: var(--dl-size-size-small);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--dl-color-cbm-cbm_red);
}

.cbmcolorfulbar-text7 {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
}

.cbmcolorfulbar-container8 {
    flex: 0 0 auto;
    width: 20%;
    height: var(--dl-size-size-small);
    display: flex;
    align-items: center;
    border-radius: 0px;
    justify-content: center;
    background-color: var(--dl-color-cbm-cbm_brown);
    border-bottom-right-radius: var(--dl-radius-radius-radius8);
}

.cbmcolorfulbar-text8 {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
}



.cbmcolorfulbar-container04 {
    flex: 0 0 auto;
    width: 100%;
    height: var(--dl-size-size-small);
    display: flex;
    align-items: center;
    border-color: rgba(217, 217, 217, 0.5);
    border-width: 1px;
    justify-content: space-between;
    border-top-width: 1px;
    border-bottom-width: 1px;
}

.cbmcolorfulbar-icon08 {
    fill: #D9D9D9;
    width: 30px;
    height: 30px;
    max-width: 30px;
    min-width: 30px;
    max-height: 30px;
    min-height: 30px;
    margin-left: var(--dl-space-space-halfunit);
}

.cbmcolorfulbar-text04 {
    color: var(--dl-color-gray-700);
    width: 100%;
    align-self: center;
    font-style: normal;
    text-align: right;
    font-weight: 500;
}

.cbmcolorfulbar-icon10 {
    fill: var(--dl-color-gray-900);
    width: 30px;
    height: 30px;
    max-width: 30px;
    min-width: 30px;
    max-height: 30px;
    min-height: 30px;
    margin-right: var(--dl-space-space-halfunit);
}
.cbmcolorfulbar-icon10 {
    fill: var(--dl-color-gray-900);
    width: 30px;
    height: 30px;
    max-width: 30px;
    min-width: 30px;
    max-height: 30px;
    min-height: 30px;
    margin-left: var(--dl-space-space-halfunit);
}

.cbmcolorfulbar-icon12 {
    fill: var(--dl-color-gray-900);
    width: 30px;
    height: 30px;
    max-width: 30px;
    min-width: 30px;
    max-height: 30px;
    min-height: 30px;
    margin-left: var(--dl-space-space-halfunit);
}

.cbmcolorfulbar-root-class-name {
    height: 100px;
    margin-bottom: var(--dl-space-space-halfunit);
}

.cbmcolorfulbar-root-class-name1 {
    height: 100px;
    margin-bottom: var(--dl-space-space-halfunit);
}

.cbmcolorfulbar-root-class-name2 {
    height: 150px;
    margin-bottom: var(--dl-space-space-halfunit);
}

.cbmcolorfulbar-root-class-name3 {
    height: 100px;
    margin-bottom: var(--dl-space-space-halfunit);
}

.cbmcolorfulbar-root-class-name4 {
    height: 100px;
    margin-bottom: var(--dl-space-space-halfunit);
}

.cbmcolorfulbar-root-class-name5 {
    height: 100px;
    margin-bottom: var(--dl-space-space-halfunit);
}