.ledgerlistitem-ledgerlistitem {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    padding: var(--dl-space-space-halfunit);
    position: relative;
    box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.02);
    align-items: center;
    border-color: rgba(217, 217, 217, 0.83);
    border-style: solid;
    border-width: 1px;
    border-radius: var(--dl-radius-radius-radius8);
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 8px;
    background-color: var(--dl-color-gray-white);
}

.ledgerlistitem-text {
    color: var(--dl-color-custom-postoffice);
    width: 100%;
    font-style: normal;
    font-weight: 700;
    padding-left: var(--dl-space-space-halfunit);
}

.ledgerlistitem-text1 {
    color: var(--dl-color-custom-helloblue);
    width: 100%;
    font-size: 18px;
    font-style: normal;
    text-align: right;
    font-weight: 700;
}

.ledgerlistitem-text2 {
    color: var(--dl-color-gray-700);
    width: 100%;
    font-size: 10px;
    font-style: normal;
    font-weight: 300;
    padding-left: var(--dl-space-space-halfunit);
}