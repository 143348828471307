.activepushitem-activepushitem {
    flex: 0 0 auto;
    width: 100%;
    height: 290px;
    display: flex;
    position: relative;
    align-items: flex-start;
    margin-bottom: var(--dl-space-space-halfunit);
    justify-content: flex-start;
    text-decoration: none;
    background-color: var(--dl-color-gray-white);
}

.activepushitem-container {
    flex: 0 0 auto;
    width: 8px;
    height: var(--dl-size-size-xxlarge);
    display: flex;
    align-items: flex-start;
    border-color: var(--dl-color-cbm-cbm_green);
    border-width: 1px;
    background-color: var(--dl-color-cbm-cbm_green);
}

.activepushitem-container1 {
    flex: 1;
    width: 100px;
    height: var(--dl-size-size-xxlarge);
    display: flex;
    align-items: flex-start;
    border-color: rgba(120, 120, 120, 0.4);
    border-style: solid;
    border-width: 0px;
    flex-direction: column;
    justify-content: flex-start;
    border-top-width: 1px;
    border-bottom-width: 1px;
}

.activepushitem-graphcomeshere {
    flex: 1;
    width: 100%;
    height: var(--dl-size-size-large);
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
}

.activepushitem-container2 {
    flex: 1;
    width: 100%;
    height: var(--dl-size-size-large);
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
}

.activepushitem-container3 {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    align-items: flex-start;
    padding-top: 2px;
    padding-left: var(--dl-space-space-halfunit);
    padding-right: 4px;
    padding-bottom: 2px;
}

.activepushitem-text {
    color: var(--dl-color-gray-700);
    width: 100%;
    font-size: 12px;
    text-align: right;
    padding-top: 0px;
    padding-right: 0px;
    text-transform: uppercase;
}

.activepushitem-text1 {
    color: var(--dl-color-gray-500);
    width: 100%;
    font-size: 18px;
    font-style: normal;
    text-align: left;
    font-weight: 700;
    padding-top: 2px;
    padding-left: var(--dl-space-space-halfunit);
    padding-right: var(--dl-space-space-halfunit);
}

.activepushitem-text2 {
    color: var(--dl-color-cbm-cbm_red);
    width: 100%;
    font-size: 14px;
    font-style: normal;
    text-align: left;
    font-weight: 500;
    padding-top: 2px;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
}

.activepushitem-text3 {
    color: var(--dl-color-gray-700);
    width: 100%;
    font-size: 14px;
    font-style: normal;
    text-align: left;
    font-weight: 500;
    padding-top: 2px;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
}

.activepushitem-text4 {
    color: var(--dl-color-gray-700);
    width: 100%;
    font-size: 14px;
    font-style: normal;
    text-align: left;
    font-weight: 500;
    padding-top: 2px;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
}

.activepushitem-text5 {
    color: var(--dl-color-cbm-cbm_blue);
    width: 100%;
    font-size: 14px;
    font-style: normal;
    text-align: right;
    font-weight: 700;
    padding-top: 0px;
    margin-bottom: var(--dl-space-space-halfunit);
    padding-right: 4px;
    text-transform: uppercase;
}

.activepushitem-text6 {
    color: var(--dl-color-cbm-cbm_orange);
    width: 100%;
    font-size: 14px;
    font-style: normal;
    text-align: right;
    font-weight: 700;
    padding-top: 0px;
    padding-right: 4px;
    text-transform: uppercase;
}

.activepushitem-container4 {
    flex: 0 0 auto;
    width: 8px;
    height: var(--dl-size-size-xxlarge);
    display: flex;
    align-items: flex-start;
    border-color: var(--dl-color-cbm-cbm_green);
    border-width: 1px;
    background-color: var(--dl-color-cbm-cbm_green);
}