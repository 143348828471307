.demographicstatistics-demographicstatistics {
    width: 100%;
    display: flex;
    position: relative;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    background-color: var(--dl-color-gray-white);
}

.demographicstatistics-container {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding-top: var(--dl-space-space-unit);
    border-color: var(--dl-color-gray-900);
    border-width: 2px;
    padding-left: var(--dl-space-space-halfunit);
    padding-right: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-unit);
    justify-content: space-between;
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
}

.demographicstatistics-container01 {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
}

.demographicstatistics-text02 {
    width: 100%;
    margin-top: var(--dl-space-space-halfunit);
    padding-left: var(--dl-space-space-halfunit);
    padding-right: var(--dl-space-space-halfunit);
}

.demographicstatistics-container02 {
    flex: 0 0 auto;
    width: 100%;
    height: var(--dl-size-size-xlarge);
    display: flex;
    margin-top: var(--dl-space-space-unit);
    align-items: flex-start;
    border-color: var(--dl-color-gray-900);
    border-style: solid;
    border-width: 1px;
    border-radius: var(--dl-radius-radius-radius16);
}

.demographicstatistics-container03 {
    width: 100%;
    display: flex;
    margin-top: var(--dl-space-space-unit);
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
}

.demographicstatistics-text03 {
    width: auto;
    padding-left: var(--dl-space-space-halfunit);
    padding-right: var(--dl-space-space-halfunit);
}

.demographicstatistics-text04 {
    color: var(--dl-color-custom-newsalmon);
    width: auto;
    font-style: normal;
    font-weight: 700;
    padding-right: var(--dl-space-space-halfunit);
}

.demographicstatistics-container04 {
    width: 100%;
    display: flex;
    margin-top: var(--dl-space-space-halfunit);
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
}

.demographicstatistics-text05 {
    width: 100%;
    margin-top: var(--dl-space-space-halfunit);
    padding-left: var(--dl-space-space-halfunit);
    padding-right: var(--dl-space-space-halfunit);
}

.demographicstatistics-container05 {
    flex: 0 0 auto;
    width: 100%;
    height: var(--dl-size-size-xlarge);
    display: flex;
    margin-top: var(--dl-space-space-unit);
    align-items: flex-start;
    border-color: var(--dl-color-gray-900);
    border-style: solid;
    border-width: 1px;
    border-radius: var(--dl-radius-radius-radius16);
}

.demographicstatistics-container06 {
    flex: 0 0 auto;
    flex-direction: column;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-top: var(--dl-space-space-unit);
    align-items: center;
    justify-content: flex-start;
}

.demographicstatistics-schoollevelcontainer {
    flex: 1;
    width: 100%;
    height: auto;
    display: flex;
    min-height: var(--dl-size-size-xlarge);
    align-items: flex-start;
}

.demographicstatistics-relationshipcontainer {
    flex: 1;
    width: 100%;
    height: auto;
    display: flex;
    min-height: var(--dl-size-size-xlarge);
    align-items: flex-start;
}

.demographicstatistics-container07 {
    width: 100%;
    display: flex;
    margin-top: var(--dl-space-space-unit);
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
}

.demographicstatistics-text06 {
    width: auto;
    padding-left: var(--dl-space-space-halfunit);
    padding-right: var(--dl-space-space-halfunit);
}

.demographicstatistics-text07 {
    color: var(--dl-color-custom-newsalmon);
    width: auto;
    font-style: normal;
    font-weight: 700;
    padding-right: var(--dl-space-space-halfunit);
}

.demographicstatistics-container08 {
    width: 100%;
    display: flex;
    margin-top: var(--dl-space-space-unit);
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
}

.demographicstatistics-text08 {
    width: auto;
    padding-left: var(--dl-space-space-halfunit);
    padding-right: var(--dl-space-space-halfunit);
}

.demographicstatistics-text09 {
    color: var(--dl-color-custom-newsalmon);
    width: auto;
    font-style: normal;
    font-weight: 700;
    padding-right: var(--dl-space-space-halfunit);
}

.demographicstatistics-container09 {
    width: 100%;
    display: flex;
    margin-top: var(--dl-space-space-unit);
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
}

.demographicstatistics-text10 {
    width: auto;
    padding-left: var(--dl-space-space-halfunit);
    padding-right: var(--dl-space-space-halfunit);
}

.demographicstatistics-text11 {
    color: var(--dl-color-custom-newsalmon);
    width: auto;
    font-style: normal;
    font-weight: 700;
    padding-right: var(--dl-space-space-halfunit);
}

.demographicstatistics-container10 {
    width: 100%;
    display: flex;
    margin-top: var(--dl-space-space-halfunit);
    align-items: center;
    margin-bottom: var(--dl-space-space-twounits);
    flex-direction: column;
    justify-content: flex-start;
}

.demographicstatistics-text12 {
    width: 100%;
    margin-top: var(--dl-space-space-halfunit);
    padding-left: var(--dl-space-space-halfunit);
    padding-right: var(--dl-space-space-halfunit);
}

.demographicstatistics-container11 {
    flex: 0 0 auto;
    width: 100%;
    height: var(--dl-size-size-xlarge);
    display: flex;
    margin-top: var(--dl-space-space-unit);
    align-items: flex-start;
    border-color: var(--dl-color-gray-900);
    border-style: solid;
    border-width: 1px;
    border-radius: var(--dl-radius-radius-radius16);
}

@media(max-width: 479px) {
    .demographicstatistics-container06 {
        flex-direction: column;
    }
}