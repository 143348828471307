.personal-finance-personal-finance {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    position: relative;
    align-items: center;
    border-radius: var(--dl-radius-radius-radius16);
    flex-direction: column;
    justify-content: flex-start;
    background-color: var(--dl-color-gray-white);
}

.personal-finance-total {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    border-color: var(--dl-color-gray-900);
    border-width: 0px;
    padding-left: var(--dl-space-space-unit);
    border-radius: var(--dl-radius-radius-radius4);
    margin-bottom: var(--dl-space-space-halfunit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-halfunit);
    justify-content: space-between;
    border-bottom-width: 2px;
}

.personal-finance-text {
    color: var(--dl-color-gray-700);
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
}

.personal-finance-text1 {
    color: var(--dl-color-custom-postoffice);
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
}