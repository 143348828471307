.useritem-selected-useritem-selected {
    flex: 0 0 auto;
    width: 95%;
    height: auto;
    display: flex;
    position: relative;
    box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.15);
    align-items: center;
    border-color: rgba(217, 217, 217, 0.5);
    border-style: solid;
    border-width: 1px;
    padding-left: var(--dl-space-space-halfunit);
    border-radius: var(--dl-radius-radius-radius16);
    margin-bottom: var(--dl-space-space-halfunit);
    padding-right: var(--dl-space-space-halfunit);
    justify-content: flex-start;
    background-color: var(--dl-color-gray-white);
}

.useritem-selected-image {
    width: 60px;
    height: 60px;
    max-height: 60px;
    min-height: 60px;
    max-width: 60px;
    min-width: 60px;
    object-fit: cover;
    border-radius: var(--dl-radius-radius-round);
}

.useritem-selected-container {
    flex: 1;
    width: 100%;
    height: auto;
    display: flex;
    padding: var(--dl-space-space-halfunit);
    align-items: flex-start;
    flex-direction: column;
}

.useritem-selected-text {
    width: 100%;
    padding-left: 8px;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
}

.useritem-selected-select {
    width: 100%;
    height: 30px;
    padding-left: 8px;
    border-radius: var(--dl-radius-radius-radius16);
}