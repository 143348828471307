.general-settings-container {
    width: 100%;
    display: flex;
    position: relative;
    background-color: var(--dl-color-gray-white);
}

.general-settings-general-settings {
    width: 100%;
    height: auto;
    display: flex;
    padding: var(--dl-space-space-halfunit);
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
}

.general-settings-text {
    color: var(--dl-color-gray-500);
    width: 100%;
    font-size: 22px;
    font-style: normal;
    margin-top: var(--dl-space-space-unit);
    font-weight: 700;
    padding-left: var(--dl-space-space-halfunit);
    margin-bottom: var(--dl-space-space-unit);
}

.general-settings-container01 {
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
}

.general-settings-text01 {
    color: var(--dl-color-gray-500);
    width: 100%;
    font-size: 12px;
    padding-left: var(--dl-space-space-unit);
    margin-bottom: 2px;
    padding-right: var(--dl-space-space-unit);
}

.general-settings-textinput {
    width: 100%;
    border-color: var(--dl-color-gray-900);
    border-radius: var(--dl-radius-radius-radius8);
    margin-bottom: var(--dl-space-space-halfunit);
}

.general-settings-container02 {
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
}

.general-settings-text02 {
    color: var(--dl-color-gray-500);
    width: 100%;
    font-size: 12px;
    padding-left: var(--dl-space-space-unit);
    margin-bottom: 2px;
    padding-right: var(--dl-space-space-unit);
}

.general-settings-textinput1 {
    width: 100%;
    border-color: var(--dl-color-gray-900);
    border-radius: var(--dl-radius-radius-radius8);
    margin-bottom: var(--dl-space-space-halfunit);
}

.general-settings-container03 {
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
}

.general-settings-text03 {
    color: var(--dl-color-gray-500);
    width: 100%;
    font-size: 12px;
    padding-left: var(--dl-space-space-unit);
    margin-bottom: 2px;
    padding-right: var(--dl-space-space-unit);
}

.general-settings-textinput2 {
    width: 100%;
    border-color: var(--dl-color-gray-900);
    border-radius: var(--dl-radius-radius-radius8);
    margin-bottom: var(--dl-space-space-halfunit);
}

.general-settings-container04 {
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
}

.general-settings-text04 {
    color: var(--dl-color-gray-500);
    width: 100%;
    font-size: 12px;
    padding-left: var(--dl-space-space-unit);
    margin-bottom: 2px;
    padding-right: var(--dl-space-space-unit);
}

.general-settings-textinput3 {
    width: 100%;
    border-color: var(--dl-color-gray-900);
    border-radius: var(--dl-radius-radius-radius8);
    margin-bottom: var(--dl-space-space-halfunit);
}

.general-settings-container05 {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    padding-top: var(--dl-space-space-unit);
    margin-bottom: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-unit);
    justify-content: flex-start;
}

.general-settings-text05 {
    color: var(--dl-color-gray-500);
    width: 50%;
    font-size: 12px;
    padding-left: var(--dl-space-space-unit);
}

.general-settings-checkbox {
    width: auto;
}

.general-settings-container06 {
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
}

.general-settings-text06 {
    color: var(--dl-color-gray-500);
    width: 100%;
    font-size: 12px;
    padding-left: var(--dl-space-space-unit);
    margin-bottom: 2px;
    padding-right: var(--dl-space-space-unit);
}

.general-settings-textinput4 {
    width: 100%;
    border-color: var(--dl-color-gray-900);
    border-radius: var(--dl-radius-radius-radius8);
    margin-bottom: var(--dl-space-space-halfunit);
}

.general-settings-container07 {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    padding-top: var(--dl-space-space-unit);
    margin-bottom: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-unit);
    justify-content: flex-start;
}

.general-settings-text07 {
    color: var(--dl-color-gray-500);
    width: 50%;
    font-size: 12px;
    padding-left: var(--dl-space-space-unit);
}

.general-settings-checkbox1 {
    width: auto;
}

.general-settings-container08 {
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
}

.general-settings-text08 {
    color: var(--dl-color-gray-500);
    width: 100%;
    font-size: 12px;
    padding-left: var(--dl-space-space-unit);
    margin-bottom: 2px;
    padding-right: var(--dl-space-space-unit);
}

.general-settings-textinput5 {
    width: 100%;
    border-color: var(--dl-color-gray-900);
    border-radius: var(--dl-radius-radius-radius8);
    margin-bottom: var(--dl-space-space-halfunit);
}

.general-settings-container09 {
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
}

.general-settings-text09 {
    color: var(--dl-color-gray-500);
    width: 100%;
    font-size: 12px;
    padding-left: var(--dl-space-space-unit);
    margin-bottom: 2px;
    padding-right: var(--dl-space-space-unit);
}

.general-settings-textinput6 {
    width: 100%;
    border-color: var(--dl-color-gray-900);
    border-radius: var(--dl-radius-radius-radius8);
    margin-bottom: var(--dl-space-space-halfunit);
}

.general-settings-container10 {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    padding-top: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-unit);
    justify-content: flex-end;
}

.general-settings-button {
    color: var(--dl-color-gray-white);
    font-style: normal;
    font-weight: 800;
    border-color: var(--dl-color-cbm-cbm_green);
    background-color: var(--dl-color-cbm-cbm_green);
}