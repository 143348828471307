.newuserregister-appparametereditor {
    flex: 0 0 auto;
    width: 100%;
    height: 100%;
    display: flex;
    padding: 15px;
    position: relative;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
    background-color: var(--dl-color-gray-white);
}

.newuserregister-form {
    flex: 1;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
}

.newuserregister-registrationform {
    width: 100%;
    height: auto;
    display: flex;
    margin-top: 0px;
    padding-top: 0px;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    flex-direction: column;
    padding-bottom: 0px;
}

.newuserregister-textinput {
    width: 100%;
    border-color: var(--dl-color-gray-700);
    margin-right: 0px;
    border-radius: var(--dl-radius-radius-radius8);
    margin-bottom: var(--dl-space-space-threeunits);
}

.newuserregister-text {
    color: var(--dl-color-custom-redred);
    width: 100%;
    padding-left: var(--dl-space-space-unit);
    margin-bottom: 2px;
}

.newuserregister-textinput1 {
    width: 100%;
    border-color: var(--dl-color-gray-700);
    margin-right: 0px;
    border-radius: var(--dl-radius-radius-radius8);
    margin-bottom: var(--dl-space-space-unit);
}

.newuserregister-textinput2 {
    width: 100%;
    border-color: var(--dl-color-gray-700);
    margin-right: 0px;
    border-radius: var(--dl-radius-radius-radius8);
    margin-bottom: var(--dl-space-space-unit);
}

.newuserregister-textinput3 {
    width: 100%;
    border-color: var(--dl-color-gray-700);
    margin-right: 0px;
    border-radius: var(--dl-radius-radius-radius8);
    margin-bottom: var(--dl-space-space-unit);
}

.newuserregister-container {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.newuserregister-button {
    color: #0099cc;
    padding-top: 2px;
    border-color: #0099cc;
    padding-left: 4px;
    padding-right: 4px;
    padding-bottom: 2px;
}

.newuserregister-icon {
    fill: #0099cc;
    width: var(--dl-size-size-xsmall);
    height: var(--dl-size-size-xsmall);
    max-width: var(--dl-size-size-xsmall);
    min-width: var(--dl-size-size-xsmall);
    max-height: var(--dl-size-size-xsmall);
    min-height: var(--dl-size-size-xsmall);
    margin-left: var(--dl-space-space-unit);
}

.newuserregister-text1 {
    color: #0099cc;
    width: auto;
    margin-left: var(--dl-space-space-unit);
}

.newuserregister-container1 {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    margin-top: 0px;
    align-items: center;
    padding-left: 0px;
    padding-right: 0px;
    justify-content: flex-end;
}

.newuserregister-button1 {
    color: var(--dl-color-gray-white);
    box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.15);
    font-style: normal;
    font-weight: 700;
    border-color: var(--dl-color-success-300);
    border-radius: var(--dl-radius-radius-radius8);
    background-color: var(--dl-color-success-300);
}


.newuserregister-root-class-name {
    height: auto;
}


.imageeditorregister-imageeditor {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    padding: var(--dl-space-space-unit);
    position: relative;
    align-items: center;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
    justify-content: center;
    background-color: var(--dl-color-gray-white);
}

.imageeditorregister-imageeditorcropper {
    width: var(--dl-size-size-xxlarge);
    height: var(--dl-size-size-xxlarge);
    object-fit: cover;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
}

.imageeditorregister-imageeditorcontainers {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    min-width: auto;
    align-items: center;
    padding-left: 0px;
    padding-right: 0px;
    flex-direction: row;
    justify-content: space-between;
}

.imageeditorregister-imageeditorfileselector {
    color: var(--dl-color-gray-700);
    width: 100%;
    min-width: var(--dl-size-size-xxlarge);
    margin-left: 0px;
    border-color: var(--dl-color-gray-900);
    margin-right: 0px;
    border-radius: var(--dl-radius-radius-radius8);
    margin-bottom: 0px;
}

@media(max-width: 991px) {
    .imageeditorregister-imageeditorcontainers {
        min-width: auto;
        padding-left: 0px;
        padding-right: 0px;
    }
}

@media(max-width: 767px) {
    .imageeditorregister-imageeditorcontainers {
        min-width: auto;
        padding-left: 0px;
        padding-right: 0px;
    }
}

@media(max-width: 479px) {
    .imageeditorregister-imageeditor {
        align-items: center;
        padding-left: 0px;
        padding-right: 0px;
        justify-content: center;
    }

    .imageeditorregister-imageeditorcropper {
        width: 100%;
        height: 100%;
    }

    .imageeditorregister-imageeditorcontainers {
        padding-left: 0px;
        padding-right: 0px;
    }

    .imageeditorregister-imageeditorfileselector {
        min-width: var(--dl-size-size-medium);
    }
}

@media(max-width: 479px) {
    .newuserregister-appparametereditor {
        padding: var(--dl-space-space-halfunit);
    }

    .newuserregister-registrationform {
        padding-left: 0px;
        padding-right: 0px;
    }
}