.cbmgroups-container {
    width: 100%;
    display: flex;
    overflow: auto;
    min-height: 100vh;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
}

.cbmgroups-body {
    flex: 1;
    width: 100%;
    height: 100px;
    display: flex;
    max-width: var(--dl-size-size-1200);
    align-self: flex-start;
    align-items: flex-start;
    padding-top: var(--dl-space-space-halfunit);
    padding-left: var(--dl-space-space-halfunit);
    padding-right: var(--dl-space-space-halfunit);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-halfunit);
    justify-content: flex-start;
}

.cbmgroups-maincontainer {
    flex: 1;
    width: 100%;
    height: 100%;
    display: flex;
    max-width: 600px;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
}

.cbmgroups-systemwarning {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    padding: var(--dl-space-space-unit);
    max-width: 600px;
    align-items: center;
    border-color: var(--dl-color-cbm-cbm_red);
    border-style: solid;
    border-width: 2px;
    border-radius: var(--dl-radius-radius-radius16);
    margin-bottom: var(--dl-space-space-halfunit);
    justify-content: center;
    background-color: var(--dl-color-cbm-cbm_red);
}

.cbmgroups-text {
    color: var(--dl-color-gray-white);
    font-size: 20px;
    font-style: normal;
    text-align: center;
    font-weight: 700;
}

.cbmgroups-screencontent {
    width: 100%;
    height: 100%;
    display: flex;
    max-width: 600px;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
}

.cbmgroups-container1 {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    padding: var(--dl-space-space-unit);
    box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.05);
    align-items: center;
    border-color: var(--dl-color-gray-900);
    border-width: 1px;
    border-radius: var(--dl-radius-radius-radius16);
    margin-bottom: var(--dl-space-space-halfunit);
    margin-top: var(--dl-space-space-halfunit);
    justify-content: flex-start;
}

.cbmgroups-icon {
    fill: var(--dl-color-gray-700);
    width: 24px;
    height: 24px;
    max-width: 24px;
    min-width: 24px;
    max-height: 24px;
    min-height: 24px;
    cursor: pointer;
}

.cbmgroups-text1 {
    color: var(--dl-color-gray-700);
    width: 100%;
    padding-left: var(--dl-space-space-halfunit);
    cursor: pointer;
}

.cbmgroups-form {
    width: 100%;
    height: auto;
    display: flex;
    padding: var(--dl-space-space-unit);
    box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.05);
    align-items: center;
    border-color: var(--dl-color-gray-900);
    border-width: 1px;
    border-radius: var(--dl-radius-radius-radius16);
    margin-bottom: var(--dl-space-space-halfunit);
    flex-direction: column;
    justify-content: flex-start;
}

.cbmgroups-textinput {
    width: 100%;
    border-color: var(--dl-color-gray-900);
    border-radius: var(--dl-radius-radius-radius8);
}

.cbmgroups-select {
    width: 100%;
    padding: var(--dl-space-space-halfunit);
    margin-top: var(--dl-space-space-halfunit);
    border-radius: var(--dl-radius-radius-radius8);
}

.cbmgroups-container2 {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    margin-top: var(--dl-space-space-unit);
    align-items: center;
    justify-content: flex-end;
}

.cbmgroups-button {
    color: var(--dl-color-gray-white);
    font-style: normal;
    font-weight: 700;
    border-color: var(--dl-color-cbm-cbm_green);
    background-color: var(--dl-color-cbm-cbm_green);
}

.cbmgroups-text2 {
    color: var(--dl-color-cbm-cbm_blue);
    font-style: normal;
    margin-top: var(--dl-space-space-halfunit);
    font-weight: 700;
    margin-left: var(--dl-space-space-halfunit);
    padding-top: 2px;
    border-color: var(--dl-color-cbm-cbm_blue);
    border-width: 1px;
    padding-left: var(--dl-space-space-halfunit);
    border-radius: var(--dl-radius-radius-radius8);
    margin-bottom: var(--dl-space-space-halfunit);
    padding-right: var(--dl-space-space-halfunit);
    padding-bottom: 2px;
    text-transform: uppercase;
}

.cbmgroups-text3 {
    color: var(--dl-color-cbm-cbm_blue);
    font-style: normal;
    margin-top: var(--dl-space-space-halfunit);
    font-weight: 700;
    margin-left: var(--dl-space-space-halfunit);
    padding-top: 2px;
    border-color: var(--dl-color-cbm-cbm_blue);
    border-width: 1px;
    padding-left: var(--dl-space-space-halfunit);
    border-radius: var(--dl-radius-radius-radius8);
    margin-bottom: var(--dl-space-space-halfunit);
    padding-right: var(--dl-space-space-halfunit);
    padding-bottom: 2px;
    text-transform: uppercase;
}

@media(max-width: 479px) {
    .cbmgroups-body {
        padding: var(--dl-space-space-halfunit);
    }
}