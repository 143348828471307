.expenseaccount-expenseaccount {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    padding: var(--dl-space-space-unit);
    position: relative;
    box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.05);
    align-items: center;
    border-color: rgba(217, 217, 217, 0.5);
    border-style: solid;
    border-width: 1px;
    margin-bottom: var(--dl-space-space-halfunit);
    flex-direction: column;
    justify-content: flex-start;
    background-color: #ebebe0;
}

.expenseaccount-container {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
}

.expenseaccount-text {
    color: var(--dl-color-cbm-cbm_orange);
    width: auto;
    font-style: normal;
    font-weight: 900;
}

.expenseaccount-text1 {
    color: var(--dl-color-cbm-cbm_orange);
    font-style: normal;
    font-weight: 900;
}

.expenseaccount-container1 {
    width: 100%;
    display: flex;
    margin-top: var(--dl-space-space-halfunit);
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
}

.expenseaccount-icon {
    fill: rgba(84, 186, 211, 0.25);
    width: 30px;
    height: 30px;
    max-width: 30px;
    min-width: 30px;
    max-height: 30px;
    min-height: 30px;
}

.expenseaccount-icon2 {
    fill: rgba(194, 167, 100, 0.5);
    width: 30px;
    height: 30px;
    max-width: 30px;
    min-width: 30px;
    max-height: 30px;
    min-height: 30px;
    margin-left: var(--dl-space-space-halfunit);
}

.expenseaccount-container2 {
    flex: 1;
    width: 100%;
    height: auto;
    display: flex;
    align-items: flex-start;
}

.expenseaccount-icon4 {
    fill: var(--dl-color-gray-700);
    width: 30px;
    height: 30px;
    max-width: 30px;
    min-width: 30px;
    max-height: 30px;
    min-height: 30px;
}

.expenseaccount-transfer-form {
    width: 100%;
    height: auto;
    display: flex;
    margin-top: var(--dl-space-space-halfunit);
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
}

.expenseaccount-select {
    width: 100%;
    padding: var(--dl-space-space-halfunit);
    border-radius: var(--dl-radius-radius-radius8);
}

.expenseaccount-container3 {
    width: 100%;
    display: flex;
    margin-top: var(--dl-space-space-halfunit);
    align-items: center;
    justify-content: flex-start;
}

.expenseaccount-textinput {
    width: 100%;
    border-color: var(--dl-color-gray-900);
    border-radius: var(--dl-radius-radius-radius8);
}

.expenseaccount-button {
    color: var(--dl-color-gray-white);
    width: var(--dl-size-size-large);
    min-width: var(--dl-size-size-large);
    font-style: normal;
    font-weight: 700;
    margin-left: var(--dl-space-space-unit);
    border-color: var(--dl-color-cbm-cbm_blue);
    background-color: var(--dl-color-cbm-cbm_blue);
}

.expenseaccount-transfer-form1 {
    width: 100%;
    height: auto;
    display: flex;
    margin-top: var(--dl-space-space-halfunit);
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
}

.expenseaccount-select1 {
    width: 100%;
    padding: var(--dl-space-space-halfunit);
    border-radius: var(--dl-radius-radius-radius8);
}

.expenseaccount-textinput1 {
    width: 100%;
    margin-top: var(--dl-space-space-halfunit);
    border-color: var(--dl-color-gray-900);
}

.expenseaccount-container4 {
    width: 100%;
    display: flex;
    margin-top: var(--dl-space-space-halfunit);
    align-items: center;
    justify-content: flex-start;
}

.expenseaccount-textinput2 {
    width: 100%;
    border-color: var(--dl-color-gray-900);
    border-radius: var(--dl-radius-radius-radius8);
}

.expenseaccount-button1 {
    color: var(--dl-color-gray-white);
    width: var(--dl-size-size-large);
    min-width: var(--dl-size-size-large);
    font-style: normal;
    font-weight: 700;
    margin-left: var(--dl-space-space-unit);
    border-color: var(--dl-color-cbm-cbm_orange);
    background-color: var(--dl-color-cbm-cbm_orange);
}

.expenseaccount-container5 {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    padding: var(--dl-space-space-unit);
    margin-top: var(--dl-space-space-halfunit);
    align-items: center;
    justify-content: center;
    background-color: var(--dl-color-cbm-cbm_green);
}

.expenseaccount-text2 {
    color: var(--dl-color-gray-white);
    font-style: normal;
    font-weight: 700;
}

.expenseaccount-container6 {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    padding: var(--dl-space-space-unit);
    margin-top: var(--dl-space-space-halfunit);
    align-items: center;
    padding-bottom: 16px;
    justify-content: center;
    background-color: var(--dl-color-cbm-cbm_red);
}

.expenseaccount-text3 {
    color: var(--dl-color-gray-white);
    font-style: normal;
    font-weight: 700;
}