.cbmuserallloans-container {
    width: 100%;
    display: flex;
    overflow: auto;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
}

.cbmuserallloans-body {
    flex: 1;
    width: 100%;
    height: 100px;
    display: flex;
    max-width: var(--dl-size-size-1200);
    align-self: flex-start;
    align-items: flex-start;
    padding-top: var(--dl-space-space-halfunit);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-halfunit);
    justify-content: flex-start;
}

.cbmuserallloans-maincontainer {
    flex: 1;
    width: 100%;
    height: auto;
    display: flex;
    max-width: 600px;
    align-self: flex-start;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
}

.cbmuserallloans-kyccontainer {
    flex: 0 0 auto;
    width: 100%;
    height: 100px;
    display: flex;
    padding: var(--dl-space-space-unit);
    max-width: 600px;
    box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.05);
    align-items: flex-start;
    border-color: rgba(217, 217, 217, 0.5);
    border-width: 1px;
    border-radius: var(--dl-radius-radius-radius8);
    margin-bottom: var(--dl-space-space-unit);
}

.cbmuserallloans-paybackloan {
    width: 80%;
    height: auto;
    display: flex;
    padding: var(--dl-space-space-oneandhalfunits);
    align-self: center;
    box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.05);
    align-items: center;
    border-color: rgba(194, 167, 100, 0.5);
    border-width: 1px;
    border-radius: var(--dl-radius-radius-radius16);
    margin-bottom: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
    justify-content: flex-start;
    background-color: rgba(194, 167, 100, 0.25);
}

.cbmuserallloans-text {
    color: var(--dl-color-gray-white);
    width: 100%;
    font-size: 20px;
    font-style: normal;
    text-align: center;
    font-weight: 700;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
}

.cbmuserallloans-textinput {
    width: 95%;
    border-color: rgba(194, 167, 100, 0.5);
    margin-bottom: var(--dl-space-space-oneandhalfunits);
}

.cbmuserallloans-button {
    color: var(--dl-color-cbm-cbm_orange);
    width: 95%;
    box-shadow: rgba(0, 0, 0, 0.1) 5px 5px 10px 0px;
    font-style: normal;
    font-weight: 700;
    border-color: var(--dl-color-cbm-cbm_orange);
    border-radius: var(--dl-radius-radius-radius16);
    margin-bottom: var(--dl-space-space-oneandhalfunits);
}

.cbmuserallloans-confirmloan {
    width: 80%;
    height: auto;
    display: flex;
    padding: var(--dl-space-space-oneandhalfunits);
    align-self: center;
    box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.05);
    align-items: center;
    border-color: rgba(194, 167, 100, 0.5);
    border-width: 1px;
    border-radius: var(--dl-radius-radius-radius16);
    margin-bottom: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
    justify-content: flex-start;
    background-color: rgba(194, 167, 100, 0.25);
}

.cbmuserallloans-text01 {
    color: var(--dl-color-gray-white);
    width: 100%;
    font-size: 20px;
    font-style: normal;
    text-align: center;
    font-weight: 700;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
}

.cbmuserallloans-text02 {
    color: var(--dl-color-cbm-cbm_orange);
    width: 100%;
    font-size: 24px;
    font-style: normal;
    text-align: center;
    font-weight: 700;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
}

.cbmuserallloans-button1 {
    color: var(--dl-color-cbm-cbm_orange);
    width: 95%;
    box-shadow: rgba(0, 0, 0, 0.1) 5px 5px 10px 0px;
    font-style: normal;
    font-weight: 700;
    border-color: var(--dl-color-cbm-cbm_orange);
    border-radius: var(--dl-radius-radius-radius16);
    margin-bottom: var(--dl-space-space-oneandhalfunits);
}

.cbmuserallloans-paybackloan1 {
    width: 80%;
    height: auto;
    display: flex;
    padding: var(--dl-space-space-oneandhalfunits);
    align-self: center;
    box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.05);
    align-items: center;
    border-color: rgba(232, 92, 59, 0.5);
    border-width: 1px;
    border-radius: var(--dl-radius-radius-radius16);
    margin-bottom: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
    justify-content: flex-start;
    background-color: rgba(232, 92, 59, 0.25);
}

.cbmuserallloans-text03 {
    color: var(--dl-color-gray-white);
    width: 100%;
    font-size: 20px;
    font-style: normal;
    text-align: center;
    font-weight: 700;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
}

.cbmuserallloans-text04 {
    color: var(--dl-color-gray-white);
    width: 100%;
    font-size: 16px;
    font-style: normal;
    text-align: center;
    font-weight: 700;
    margin-bottom: var(--dl-space-space-halfunit);
}

.cbmuserallloans-text05 {
    color: var(--dl-color-cbm-cbm_red);
    width: 100%;
    font-size: 24px;
    font-style: normal;
    text-align: center;
    font-weight: 700;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
}

.cbmuserallloans-textinput1 {
    width: 95%;
    border-color: rgba(232, 92, 59, 0.5);
    margin-bottom: var(--dl-space-space-oneandhalfunits);
}

.cbmuserallloans-container1 {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    padding: var(--dl-space-space-halfunit);
    align-items: center;
    border-color: var(--dl-color-cbm-cbm_red);
    border-style: solid;
    border-width: 1px;
    flex-direction: column;
    justify-content: flex-start;
    background-color: var(--dl-color-gray-white);
}

.cbmuserallloans-container2 {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    padding: var(--dl-space-space-halfunit);
    box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.05);
    align-items: center;
    border-color: var(--dl-color-gray-900);
    border-width: 1px;
    border-radius: var(--dl-radius-radius-radius8);
    margin-bottom: var(--dl-space-space-halfunit);
    justify-content: space-between;
}

.cbmuserallloans-icon {
    fill: var(--dl-color-cbm-cbm_red);
    width: 24px;
    height: 24px;
    max-width: 24px;
    min-width: 24px;
    max-height: 24px;
    min-height: 24px;
}

.cbmuserallloans-container3 {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    padding: var(--dl-space-space-halfunit);
    box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.05);
    align-items: center;
    border-color: var(--dl-color-gray-900);
    border-width: 1px;
    border-radius: var(--dl-radius-radius-radius8);
    margin-bottom: var(--dl-space-space-halfunit);
    justify-content: space-between;
}

.cbmuserallloans-icon2 {
    fill: var(--dl-color-cbm-cbm_red);
    width: 24px;
    height: 24px;
    max-width: 24px;
    min-width: 24px;
    max-height: 24px;
    min-height: 24px;
}

.cbmuserallloans-text08 {
    color: var(--dl-color-gray-white);
    width: 100%;
    font-size: 16px;
    font-style: normal;
    margin-top: 2px;
    text-align: right;
    font-weight: 700;
    padding-left: var(--dl-space-space-halfunit);
    margin-bottom: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-halfunit);
}

.cbmuserallloans-button2 {
    color: var(--dl-color-cbm-cbm_red);
    width: 95%;
    box-shadow: rgba(0, 0, 0, 0.1) 5px 5px 10px 0px;
    font-style: normal;
    font-weight: 700;
    border-color: var(--dl-color-cbm-cbm_red);
    border-radius: var(--dl-radius-radius-radius16);
    margin-bottom: var(--dl-space-space-oneandhalfunits);
}

.cbmuserallloans-text09 {
    color: var(--dl-color-cbm-cbm_red);
    width: 100%;
    font-size: 12px;
    font-style: normal;
    text-align: center;
    font-weight: 700;
}

.cbmuserallloans-takepicturebox {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
    justify-content: flex-start;
}

.cbmuserallloans-text10 {
    color: var(--dl-color-cbm-cbm_red);
    width: 100%;
    font-size: 20px;
    font-style: normal;
    margin-top: var(--dl-space-space-oneandhalfunits);
    text-align: center;
    font-weight: 700;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
}

.cbmuserallloans-image {
    width: 100%;
    object-fit: cover;
}

.cbmuserallloans-confirmloan1 {
    width: 80%;
    height: auto;
    display: flex;
    padding: var(--dl-space-space-oneandhalfunits);
    align-self: center;
    box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.05);
    align-items: center;
    border-color: rgba(232, 92, 59, 0.5);
    border-width: 1px;
    border-radius: var(--dl-radius-radius-radius16);
    margin-bottom: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
    justify-content: flex-start;
    background-color: rgba(232, 92, 59, 0.25);
}

.cbmuserallloans-text11 {
    color: var(--dl-color-gray-white);
    width: 100%;
    font-size: 20px;
    font-style: normal;
    text-align: center;
    font-weight: 700;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
}

.cbmuserallloans-text12 {
    color: var(--dl-color-cbm-cbm_red);
    width: 100%;
    font-size: 24px;
    font-style: normal;
    text-align: center;
    font-weight: 700;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
}

.cbmuserallloans-button3 {
    color: var(--dl-color-cbm-cbm_red);
    width: 95%;
    box-shadow: rgba(0, 0, 0, 0.1) 5px 5px 10px 0px;
    font-style: normal;
    font-weight: 700;
    border-color: var(--dl-color-cbm-cbm_red);
    border-radius: var(--dl-radius-radius-radius16);
    margin-bottom: var(--dl-space-space-oneandhalfunits);
}

.cbmuserallloans-successloan {
    width: 80%;
    height: auto;
    display: flex;
    padding: var(--dl-space-space-oneandhalfunits);
    align-self: flex-start;
    box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.05);
    align-items: center;
    border-color: var(--dl-color-custom-krakenlogin);
    border-width: 2px;
    border-radius: var(--dl-radius-radius-radius16);
    margin-bottom: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
    justify-content: flex-start;
    background-color: var(--dl-color-gray-white);
}

.cbmuserallloans-text13 {
    color: var(--dl-color-custom-krakenlogin);
    width: 100%;
    font-size: 24px;
    font-style: normal;
    text-align: center;
    font-weight: 700;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
}

.cbmuserallloans-icon4 {
    fill: var(--dl-color-custom-krakenlogin);
    width: 150px;
    height: 150px;
    max-width: 150px;
    min-width: 150px;
    max-height: 150px;
    min-height: 150px;
    margin-bottom: var(--dl-space-space-threeunits);
}

.cbmuserallloans-button4 {
    color: var(--dl-color-custom-krakenlogin);
    width: 95%;
    box-shadow: rgba(0, 0, 0, 0.1) 5px 5px 10px 0px;
    font-style: normal;
    font-weight: 700;
    border-color: var(--dl-color-custom-krakenlogin);
    border-radius: var(--dl-radius-radius-radius16);
    margin-bottom: var(--dl-space-space-oneandhalfunits);
}

.cbmuserallloans-errorloan {
    width: 80%;
    height: auto;
    display: flex;
    padding: var(--dl-space-space-oneandhalfunits);
    align-self: flex-start;
    box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.05);
    align-items: center;
    border-color: var(--dl-color-custom-redred);
    border-width: 2px;
    border-radius: var(--dl-radius-radius-radius16);
    margin-bottom: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
    justify-content: flex-start;
    background-color: var(--dl-color-gray-white);
}

.cbmuserallloans-text14 {
    color: var(--dl-color-custom-redred);
    width: 100%;
    font-size: 24px;
    font-style: normal;
    text-align: center;
    font-weight: 700;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
}

.cbmuserallloans-icon6 {
    fill: var(--dl-color-custom-redred);
    width: 150px;
    height: 150px;
    max-width: 150px;
    min-width: 150px;
    max-height: 150px;
    min-height: 150px;
    margin-bottom: var(--dl-space-space-threeunits);
}

.cbmuserallloans-button5 {
    color: var(--dl-color-custom-redred);
    width: 95%;
    box-shadow: rgba(0, 0, 0, 0.1) 5px 5px 10px 0px;
    font-style: normal;
    font-weight: 700;
    border-color: var(--dl-color-custom-redred);
    border-radius: var(--dl-radius-radius-radius16);
    margin-bottom: var(--dl-space-space-oneandhalfunits);
}

.cbmuserallloans-all-loans {
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
}

@media(max-width: 479px) {
    .cbmuserallloans-body {
        padding: var(--dl-space-space-halfunit);
    }

    .cbmuserallloans-paybackloan {
        width: 100%;
    }

    .cbmuserallloans-confirmloan {
        width: 100%;
    }

    .cbmuserallloans-paybackloan1 {
        width: 100%;
    }

    .cbmuserallloans-confirmloan1 {
        width: 100%;
    }

    .cbmuserallloans-successloan {
        width: 100%;
    }

    .cbmuserallloans-errorloan {
        width: 100%;
    }
}